import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { FavoritePOST, FavoriteGET } from "../models/Favorite";
import { BehaviorSubject } from "rxjs";
import Response from "../models/ResponseGET";
import { AppConfig } from "src/config/app.config";

@Injectable({
  providedIn: "root"
})
export class FavoriteService {
  private favoritedPagesSource = new BehaviorSubject<FavoriteGET[]>([]);
  private BASE_URL = `${AppConfig.settings.urlApi}`;
  private currentStateFavoritedPages = [] as FavoriteGET[];
  sharedFavoritedPages = this.favoritedPagesSource.asObservable();

  constructor(private http: HttpClient) {
    this.get();
  }

  get() {
    //return this.http
    //  .get<any>(`${AppConfig.settings.urlApi}/Favorito`)
    //  .subscribe((response: Response) => {
    //    this.currentStateFavoritedPages = response.value;
    //    this.updateStateFavoritedPages(response.value as FavoriteGET[]);
    //  });
  }

  delete(id: string) {
    //return this.http.delete<any>(`${AppConfig.settings.urlApi}/Favorito/${id}`);
  }

  post(params: FavoritePOST) {
    //return this.http.post<any>(`${AppConfig.settings.urlApi}/Favorito`, params);
  }

  getByRoutePath(path: string) {
    //return this.currentStateFavoritedPages.find(
    //  (favorite) => favorite.pagina === path,
    //);
  }

  addFavoritedPage(favoritedPage: FavoriteGET) {
    //this.currentStateFavoritedPages.unshift(favoritedPage);
    //this.updateStateFavoritedPages(this.currentStateFavoritedPages);
  }

  removeFavoritedPage(id: string) {
    //this.currentStateFavoritedPages = this.currentStateFavoritedPages.filter(
    //  (item) => item.id !== id,
    //);
    //
    //this.updateStateFavoritedPages(this.currentStateFavoritedPages);
  }

  private updateStateFavoritedPages(favoritedPages: FavoriteGET[]) {
    //this.favoritedPagesSource.next(favoritedPages);
  }
}
