import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "src/config/app.config";

@Injectable({
  providedIn: "root"
})
export class LinkService {
  private BASE_URL = `${AppConfig.settings.urlApi}`;
  constructor(private http: HttpClient) {}

  fetch(): any {
    return this.http.get<any>(`${this.BASE_URL}/Link`);
  }

  getParametrosByAntenaIdLinkId(linkId: number, antenaId: string) {
    return this.http.get<any>(
      `${this.BASE_URL}/Parametro/listar?linkId=${linkId}&antena=${antenaId}`
    );
  }

  newLink(params: any) {
    return this.http.post<any>(`${this.BASE_URL}/Link`, params);
  }

  updateLink(data: any) {
    return this.http.put<any>(`${this.BASE_URL}/Link/${data.id}`, data);
  }

  deleteLink(id) {
    return this.http.delete<any>(`${this.BASE_URL}/Link/${id}`);
  }
}
