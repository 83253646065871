import { NewEditGroupModalComponent } from "./pages/security/group/new-edit-group-modal/new-edit-group-modal.component";
import { NewEditCategoryModalComponent } from "./pages/estacao/configurar-categorias/new-edit-category-modal/new-edit-category-modal.component";
import { NewEditUserModalComponent } from "./pages/security/user/new-edit-user-modal/new-edit-user-modal.component";
import { LoginModalComponent } from "./pages/login-modal/login-modal.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID, APP_INITIALIZER } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  registerLocaleData,
  HashLocationStrategy,
  LocationStrategy
} from "@angular/common";
import localePt from "@angular/common/locales/pt";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { NbDateFnsDateModule } from "@nebular/date-fns";
import { JwtModule } from "@auth0/angular-jwt";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MatDialogModule } from "@angular/material/dialog";
import { ModalModule } from "ngx-bootstrap/modal";

import {
  NbSearchModule,
  NbFormFieldModule,
  NbCheckboxModule,
  NbThemeModule,
  NbDatepickerModule,
  NbLayoutModule,
  NbSidebarModule,
  NbToastrModule,
  NbMenuModule,
  NbCardModule,
  NbSelectModule,
  NbInputModule,
  NbButtonModule,
  NbTabsetModule,
  NbSpinnerModule,
  NbIconComponent,
  NbDialogModule,
  NbIconModule,
  NbTooltipModule,
  NbRadioModule,
  NbPopoverModule,
  NbAutocompleteModule,
  NbListModule,
  NbAlertModule
} from "@nebular/theme";
import { AuthGuard } from "./guards/auth-guard.service";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { Interceptor } from "./services/intercept.service";
import { NgxMaskModule } from "ngx-mask";
import { ptBR } from "date-fns/locale";
import { TopbarModule } from "./components/topbar/topbar.module";
import { SidebarModule } from "./components/sidebar/sidebar.module";
import { FooterModule } from "./components/footer/footer.module";
import { DecisionAlertModalComponent } from "./components/decision-alert/decision-alert-modal.component";
import { PasswordAlertModalComponent } from "./components/password-alert/password-alert-modal.component";
import { ModalFirstAccessComponent } from "./components/modal-first-access/modal-first-access.component";
import { NewEditTermsModalComponent } from "./pages/settings/terms-of-use/new-edit-terms-modal/new-edit-terms-modal.component";
import { NewEditNewsModalComponent } from "./pages/register/news/new-edit-news-modal/new-edit-news-modal.component";
import { NewEditCampaignsModalComponent } from "./pages/register/campaigns/new-edit-campaigns-modal/new-edit-campaigns-modal.component";
import { TermOfUseComponente } from "./components/term-of-use/term-of-use.component";

import { QuillModule } from "ngx-quill";
import { RichtextComponent } from "./components/richtext/richtext.component";
import { ModalProfileComponent } from "./pages/profile/modal-profile.component";
import { FileUploadModule } from "@iplab/ngx-file-upload";
import { DatatableModule } from "./components/datatable/datatable.module";
import { EditFuncionarioModalComponent } from "./pages/funcionario/edit-funcionario-modal/edit-funcionario-modal.component";
import { ImportFuncionarioModalComponent } from "./pages/import-funcionario/import-funcionario-modal/import-funcionario-modal.component";
import { JustificativaEntregaModalComponent } from "./pages/justificativa-entrega/justificativa-entrega-modal/justificativa-entrega-modal.component";
import { MotivoDevolucaoModalComponent } from "./pages/motivo-devolucao/motivo-devolucao-modal/motivo-devolucao-modal.component";
import { DatePickerModule } from "./components/inputs/date-picker/date-picker.module";
import { LoadingPageModule } from "./components/loading-page/loading-page.module";
import { FooterComponent } from "./components/footer/footer.component";
import { EstacaoModule } from "./pages/estacao/consult-estacao.module";
import { FooterLandingPageModule } from "./components/landing-page/footer-landing-page/footer-landing-page.module";
import { CardNewsCampaignModule } from "./components/landing-page/card-news-campaign/card-news-campaign.module";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { initializer } from "src/config/app-init";
import { AppConfig } from "src/config/app.config";
import { EditMaterialModalComponent } from "./pages/register/materials/consult-materials/edit-material-modal/edit-material-modal.component";
import { FuncionarioCentroCustoModalComponent } from "./pages/funcionario-centro-custo/edit-funcionario-centro-custo-modal/funcionario-centro-custo-modal.component";
import { VinculoFuncionarioModalComponent } from "./pages/vinculo-funcionario/vinculo-funcionario-modal/vinculo-funcionario-modal.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { CropperImageModalComponent } from "./components/cropper-image-modal/cropper-image-modal.component";
import { TreeModule } from "@circlon/angular-tree-component";
import { NewEditClienteModalComponent } from "./pages/cliente/new-edit-cliente-modal/new-edit-cliente-modal.component";
import { NewEditBoiaModalComponent } from "./pages/boia/new-edit-boia-modal/new-edit-boia-modal.component";
import { ModalViewPositionComponent } from "./pages/antena/modal-view-position/modal-view-position.component";
import { ModalViewCamerasComponent } from "./pages/antena/modal-view-cameras/modal-view-cameras.component";
import { NewEditEstacaoModalComponent } from "./pages/estacao/new-edit-estacao-modal/new-edit-estacao-modal.component";
import { ConfigureMinMaxComponent } from "./pages/estacao/configure-min-max/configure-min-max.component";
import { MinMaxEstacaoModalComponent } from "./pages/estacao/min-max-estacao-modal/min-max-estacao-modal.component";
import { ModalProfilePassWordComponent } from "./pages/profile/modal-profile-pass-word/modal-profile-pass-word.component";
import { NewEditWebserviceModalComponent } from "./pages/estacao/new-edit-webservice-modal/new-edit-webservice-modal.component";
// import { GroupPermissionComponent } from "./pages/security/group-permission/group-permission.component";
// import { LoginModalComponent } from './pages/login-modal/login-modal.component';

registerLocaleData(localePt);

export function tokenGetter() {
  return localStorage.getItem("access_token");
}
@NgModule({
  declarations: [
    AppComponent,
    NewEditUserModalComponent,
    NewEditGroupModalComponent,
    NewEditCategoryModalComponent,
    NewEditTermsModalComponent,
    DecisionAlertModalComponent,
    PasswordAlertModalComponent,
    ModalFirstAccessComponent,
    RichtextComponent,
    ModalProfileComponent,
    NewEditNewsModalComponent,
    NewEditCampaignsModalComponent,
    TermOfUseComponente,
    EditFuncionarioModalComponent,
    ImportFuncionarioModalComponent,
    JustificativaEntregaModalComponent,
    MotivoDevolucaoModalComponent,
    EditMaterialModalComponent,
    FuncionarioCentroCustoModalComponent,
    VinculoFuncionarioModalComponent,
    CropperImageModalComponent,
    NewEditClienteModalComponent,
    NewEditEstacaoModalComponent,
    ConfigureMinMaxComponent,
    NewEditBoiaModalComponent,
    ModalViewPositionComponent,
    ModalViewCamerasComponent,
    MinMaxEstacaoModalComponent,
    NewEditWebserviceModalComponent,
    ModalProfilePassWordComponent,
    LoginModalComponent,
    // GroupPermissionComponent
  ],
  imports: [
    BrowserModule,
    NbFormFieldModule,
    NbSearchModule,
    FormsModule,
    LoadingBarHttpClientModule,
    AppRoutingModule,
    NbCardModule,
    NbCheckboxModule,
    NbSelectModule,
    NbInputModule,
    NbButtonModule,
    NbTooltipModule,
    NbEvaIconsModule,
    NbIconModule,
    BrowserAnimationsModule,
    NbAutocompleteModule,
    NbThemeModule.forRoot({ name: "vixtheme" }),
    NbDatepickerModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter
      }
    }),
    NgxMaskModule.forRoot(),
    NbDateFnsDateModule.forRoot({
      parseOptions: { locale: ptBR },
      formatOptions: { locale: ptBR }
    }),
    NbToastrModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbLayoutModule,
    TopbarModule,
    SidebarModule,
    NbListModule,
    FooterModule,
    NbTabsetModule,
    NbSpinnerModule,
    NbDialogModule.forRoot(),
    QuillModule.forRoot(),
    FileUploadModule,
    DatatableModule,
    DatePickerModule,
    CardNewsCampaignModule,
    NgxSkeletonLoaderModule,
    NbPopoverModule,
    NbRadioModule,
    ImageCropperModule,
    TreeModule,
    MatDialogModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    NbAlertModule,
    EstacaoModule
  ],
  providers: [
    AuthGuard,
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [AppConfig]
    }
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
  exports: [CropperImageModalComponent]
})
export class AppModule {}
