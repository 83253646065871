import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NbDialogRef, NbToastrService } from "@nebular/theme";
import { Subscription } from "rxjs";
import { EstacaoService } from "src/app/services/estacao.service";
import { WebserviceService } from "src/app/services/webservice.service";

@Component({
  selector: 'app-clone-params-modal',
  templateUrl: './clone-params-modal.component.html',
  styleUrls: ['./clone-params-modal.component.scss']
})

export class CloneParamsModalComponent implements OnInit, OnDestroy, AfterContentChecked {
  @Input() outsideData: any;
  subscriptions = new Subscription();
  isLoading: boolean = true;
  disabledAntena: boolean = true;
  webservicesList: any = [];
  antenasList: any = [];
  data: {
    antenaIdOrigem: string;
    webserviceIdOrigem: number;
    webserviceIdDestino: number;
    antenaIdDestino: string;
  };

  constructor(
    private nbDialogRef: NbDialogRef<CloneParamsModalComponent>,
    private nbToastrService: NbToastrService,
    private changeDetection: ChangeDetectorRef,
    private webService: WebserviceService,
    private estacaoService: EstacaoService,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.fetchListWebservices()
  }

  ngAfterContentChecked() {
    if (this.outsideData && Object.keys(this.outsideData).length !== 0 && this.isLoading) {
      this.data = {
        antenaIdOrigem: "",
        webserviceIdOrigem: 0,
        webserviceIdDestino: this.outsideData.webserviceId,
        antenaIdDestino: this.outsideData.antenaId,
      };
      this.isLoading = false;
    }
  }

  ngAfterViewInit() {
    this.changeDetection.detectChanges();
  }

  fetchListWebservices() {
    this.webService.fetch().subscribe(x => {
      this.webservicesList = x.value;
    });
  }

  onWebServiceChange(event) {
    this.data.webserviceIdOrigem = event;
    this.antenasList = [];

    this.estacaoService.getAntenaByWebService(this.data.webserviceIdOrigem).subscribe(
      data => {
        this.antenasList = data.value.antenas;
        this.disabledAntena = false;
      }
    );
  }

  onAntenaChange(event) {
    this.data.antenaIdOrigem = event;
  }

  save() {
    this.isLoading = true;
    try {
      this.subscriptions.add(
        this.webService.cloneParametros(this.data).subscribe(response => {
          this.nbToastrService.success("Parâmetros clonados com sucesso", "Sucesso", { duration: 2000 });
          this.close();
        })
      );
    } finally {
      this.isLoading = false;
    }
  }

  close() {
    this.nbDialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}