import { Component, OnInit } from "@angular/core";
import { TableBase } from "src/app/models/PagesComponents";
import FilterEstacao from "src/app/models/FilterEstacao";
import { CurrentUserService } from "src/app/services/current-user.service";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import {
  DecisionAlertModalComponent,
  TipoMensagem
} from "src/app/components/decision-alert/decision-alert-modal.component";

import { ColumnObject, DataTableActions } from "src/app/models/DataTable";

import { Subscription } from "rxjs";
import GetEstacao from "src/app/models/Estacao";
import { EstacaoService } from "src/app/services/estacao.service";
import { HelperService } from "src/app/services/helper.service";
import { NewEditEstacaoModalComponent } from "./new-edit-estacao-modal/new-edit-estacao-modal.component";
import { MinMaxEstacaoModalComponent } from "./min-max-estacao-modal/min-max-estacao-modal.component";

@Component({
  selector: "app-consult-estacao",
  templateUrl: "./consult-estacao.component.html",
  styleUrls: ["./consult-estacao.component.scss"]
})
export class EstacaoComponent implements OnInit, TableBase {
  selectedPage: number = 1;
  rowsPerPage: number = 10;
  totalRecords: number;

  filterEstacao: FilterEstacao = {
    webService: "",
    estacao: "",
    classificacao: "",
    cliente: "",
    page: this.selectedPage?.toString(),
    take: this.rowsPerPage?.toString()
  };

  companyId: string;
  isLoaded = false;
  permissions = this.uService.getEnumPermissions();
  subscriptions = new Subscription();
  data = [] as any[];

  columns: ColumnObject = {
    razaoSocial: {
      title: "Cliente",
      sort: false,
      sortDirection: "asc"
    },
    // nomeEstacao: {
    //   title: "Nome",
    //   sort: false,
    //   sortDirection: "asc"
    // },
    nome: {
      title: "Web Service",
      sort: false,
      sortDirection: "asc"
    },
    isn: {
      title: "Estação",
      sort: false,
      sortDirection: "asc"
    },
    classificacao: {
      title: "Classificação",
      sort: false,
      sortDirection: "asc"
    }
  };

  constructor(
    public uService: CurrentUserService,
    private estacaoService: EstacaoService,
    private helperService: HelperService,
    private nbDialogService: NbDialogService,
    private nbToastrService: NbToastrService
  ) {}

  includeActionsColumnOnData(item: any) {
    const buttons = this.uService.getActionsTableAllowed(
      this.permissions.AtualizarEstacao,
      this.permissions.DeletarEstacao,
      this.permissions.DeletarEstacao,
      null,
      null,
      null,
      null,
      this.permissions.ConsultarEstacao,
      this.permissions.ConfigurarEstacao
    );

    const dataWithActions = {
      ...item,
      actions: {
        buttons,
        viewEvent: () => this.view(item),
        editEvent: () => this.edit(item),
        deleteEvent: () => this.removeBase(item),
        activeDisableEvent: () => this.activeDisable(item),
        configureEvent: () => this.configure(item)
      } as DataTableActions
    };

    return dataWithActions;
  }

  view(rowData: any) {
    this.nbDialogService.open(NewEditEstacaoModalComponent, {
      autoFocus: false,
      context: { outsideData: rowData, editable: false }
    });
  }

  edit(rowData: any) {
    console.log(rowData);
    this.nbDialogService.open(NewEditEstacaoModalComponent, {
      autoFocus: false,
      context: { outsideData: rowData, editable: true }
    });
  }

  new() {
    this.nbDialogService.open(NewEditEstacaoModalComponent, {
      autoFocus: false,
      context: { outsideData: false }
    });
  }

  configure(rowData: any) {
    this.nbDialogService.open(MinMaxEstacaoModalComponent, {
      autoFocus: false,
      context: { outsideData: rowData }
    });
  }

  activeDisable(rowData: any) {
    const { id, inativo } = rowData;
    const ativar = inativo ? true : false;

    let tipoMensagem;
    let mensagem;

    if (inativo) {
      tipoMensagem = TipoMensagem.Ativar;
      mensagem = "Ativação";
    } else {
      mensagem = "Inativação";
      tipoMensagem = TipoMensagem.Inativar;
    }

    this.subscriptions.add(
      this.nbDialogService
        .open(DecisionAlertModalComponent, {
          autoFocus: false,
          context: { tipo: tipoMensagem }
        })
        .onClose.subscribe((decision: "cancel" | "remove") => {
          if (decision === "remove") {
            this.subscriptions.add(
              this.estacaoService.ativarDesativar(id, ativar).subscribe(() => {
                this.nbToastrService.success(
                  inativo
                    ? "Registro ativado com sucesso."
                    : "Registro desativado com sucesso.",
                  "Sucesso"
                );
                this.refreshData(this.companyId);
              })
            );
          }
        })
    );

    this.helperService.closeModal(true);
  }

  removeBase(rowData: any) {
    const { id } = rowData;

    let tipoMensagem;

    this.subscriptions.add(
      this.nbDialogService
        .open(DecisionAlertModalComponent, {
          autoFocus: false,
          context: { tipo: tipoMensagem }
        })
        .onClose.subscribe((decision: "cancel" | "remove") => {
          if (decision === "remove") {
            this.subscriptions.add(
              this.estacaoService.removeBaseAntena(id).subscribe(() => {
                this.nbToastrService.success(
                  "Registro removido com sucesso.",
                  "Sucesso"
                );
                this.fetch();
              })
            );
          }
        })
    );

    this.helperService.closeModal(true);
  }

  changePage(selectedPage: number) {
    this.selectedPage = selectedPage || this.selectedPage;
    this.fetch();
  }

  changeRowsPerPage(selectedRowsPerPage: number) {
    this.rowsPerPage = selectedRowsPerPage || this.rowsPerPage;
    this.fetch();
  }

  fetch() {
    this.subscriptions.add(
      this.estacaoService
        .fetch(this.selectedPage, this.rowsPerPage)
        .subscribe(response => {
          this.totalRecords = response.count;
          const clientesFound = response.value;
          this.data = clientesFound.map(item =>
            this.includeActionsColumnOnData(this.handleRawData(item))
          );
          this.isLoaded = false;
        })
    );
  }

  handleRawData(item: any) {
    const processedData = {
      ultimaLatitude: null,
      ultimaLongitude: null,
      loginEstacao: null,
      senhaEstacao: null,
      dataUltimaSincronia: null,
      ...item,
      inativo: !item.ativo,
      nome: item.webService.nome,
      razaoSocial: item.cliente.razaoSocial,
      classificacao:
        item.classificacaoID === 1
          ? "Fixo"
          : item.classificacaoID === 2
          ? "Deriva"
          : "Flutuante"
    };
    return processedData;
  }

  ngOnInit() {
    this.createRefreshDataWatcher();
  }

  createRefreshDataWatcher() {
    this.subscriptions.add(
      this.helperService.isModalToClose.subscribe(() => {
        this.refreshData(this.companyId);
      })
    );
  }

  refreshData(outsideCompanyId?: string) {
    if (this.companyId || outsideCompanyId) {
      this.fetch();
      //this.fetchGroups(this.companyId || outsideCompanyId);
    }
    this.companyId = this.companyId || outsideCompanyId;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
