import { Util } from "./../../helpers/util";
import { UserService } from "./../../services/user.service";
import { Component, OnInit } from "@angular/core";
import { NbToastrService, NbDialogRef } from "@nebular/theme";
import {
  AbstractControl,
  FormControl,
  ValidatorFn,
  Validators
} from "@angular/forms";

@Component({
  selector: "app-modal-first-access",
  templateUrl: "./modal-first-access.component.html",
  styleUrls: ["./modal-first-access.component.scss"]
})
export class ModalFirstAccessComponent implements OnInit {
  senhaPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;

  senhaAntigaControl = new FormControl("", [Validators.required]);
  senhaNovaControl = new FormControl("", [
    Validators.required,
    this.forbiddenNameValidator(this.senhaPattern)
  ]);
  confirmaSenhaNovaControl = new FormControl("", [
    Validators.required,
    this.forbiddenNameValidator(this.senhaPattern)
  ]);

  forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const match = nameRe.test(control.value);
      return match ? null : { forbiddenName: { value: control.value } };
    };
  }

  dataUser = {
    login: null,
    nome: null,
    email: null
  };

  formPassword = {
    userName: null,
    senhaAntiga: null,
    senhaNova: null,
    confirmaSenhaNova: null
  };

  iconControl = "eye-outline";

  constructor(
    private nbDialogRef: NbDialogRef<ModalFirstAccessComponent>,
    private userService: UserService,
    private toastrService: NbToastrService,
    private util: Util
  ) {}

  ngOnInit() {
    const { login, nome, email } = this.util.getDataUserToLocalStorage();

    this.dataUser = {
      login,
      nome,
      email
    };
  }

  viewPass() {
    if (this.iconControl === "eye-off-outline") {
      this.iconControl = "eye-outline";
    } else {
      this.iconControl = "eye-off-outline";
    }
  }

  save() {
    if (
      this.formPassword.senhaAntiga &&
      this.formPassword.senhaNova &&
      this.formPassword.confirmaSenhaNova
    ) {
      this.formPassword.userName = this.dataUser.login;

      this.userService.changePassword(this.formPassword).subscribe(
        resp => {
          this.formPassword.senhaNova = null;
          this.formPassword.senhaAntiga = null;

          if (resp.hasError) {
            this.toastrService.warning(resp.errors[0], "Atenção");
          } else {
            this.toastrService.success("Sua senha foi alterada", "Sucesso");
            localStorage.setItem("firstAccess", "false");
            this.nbDialogRef.close();
          }
        },
        error => {
          console.log(error);
        }
      );
    } else {
      this.toastrService.warning(
        "Por favor, preencha todos os campos",
        "Atenção"
      );
    }
  }
}
