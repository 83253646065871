import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NbDialogRef, NbTabComponent, NbToastrService } from "@nebular/theme";
import { Subscription, forkJoin } from "rxjs";
import ICompany from "src/app/models/Company";
import Funcionario from "src/app/models/Funcionario";
import Group from "src/app/models/Group";
import PostPutUser from "src/app/models/PostPutUser";
import {
  default as Response,
  default as ResponseGET
} from "src/app/models/ResponseGET";
import GetUser from "src/app/models/User";
import { AuthService } from "src/app/services/auth.service";
import { CompanyService } from "src/app/services/company.service";
import { GroupService } from "src/app/services/group.service";
import { HelperService } from "src/app/services/helper.service";
import { UserService } from "src/app/services/user.service";

import moment from "moment";
import GetCliente from "src/app/models/Cliente";
import { ClienteService } from "src/app/services/cliente.service";

enum TipoAcao {
  Editar = 1,
  Visualizar = 2,
  Criacao = 3
}

@Component({
  selector: "app-new-edit-user-modal",
  templateUrl: "./new-edit-user-modal.component.html",
  styleUrls: ["./new-edit-user-modal.component.scss"]
})
export class NewEditUserModalComponent implements OnInit, OnDestroy {
  @Input() outsideData: GetUser;
  @Input() acao: TipoAcao;
  @Input() editable: boolean = true;
  @Input() userLogged: any;

  subscriptions = new Subscription();
  isLoading: boolean;
  newOrEditTabTitle = "";
  selectedTab: "newOrEdit" | "userGroupConfig";
  isEditingAndLoaded: boolean = false;
  disabledChangeProfile: boolean = true;
  companyId: string;
  groups = [] as Group[];
  companies = [] as ICompany[];
  userGroups: string = "";
  data: PostPutUser = {
    id: "",
    nome: "",
    cpf: "",
    login: "",
    email: "",
    telefone: "",
    empresaId: "5883d3fb-f516-4f33-99ae-90035ade5d3c", //para ficar compativel com o banco
    tipoUsuario: 0,
    telefoneResidencial: "",
    urlImagem: "",
    dataCriacao: "",
    dataUltimaAtualizacao: "",
    clienteId: ""
  };

  isJustOneCompany: boolean = this.authService.getIsJustOneCompany();

  CELLPHONE: string = "(00) 0 0000-0000";
  LANDLINE: string = "(00) 0000-0000";
  phoneMask = this.LANDLINE;
  phoneNumber = "";
  previusLength = 0;
  hourMask = "00:00";

  clientes = [] as GetCliente[];

  constructor(
    private groupService: GroupService,
    private companyService: CompanyService,
    private userService: UserService,
    private nbToastrService: NbToastrService,
    private helperService: HelperService,
    private nbDialogRef: NbDialogRef<NewEditUserModalComponent>,
    private authService: AuthService,
    private clienteService: ClienteService
  ) {}

  desabilitaButton(selectedTab, registerUserForm) {
    return selectedTab === "newOrEdit" && !registerUserForm.form.valid;
  }

  ngOnInit() {
    this.fetchCliente();
    this.fetchAndSetInitialData();
    this.isLoading = this.outsideData ? true : false;
    this.selectedTab = "newOrEdit";
    this.newOrEditTabTitle = this.outsideData
      ? "Edição de usuário"
      : "Cadastro de usuário";

    if (this.acao == TipoAcao.Visualizar) this.newOrEditTabTitle = "Ver perfil";

    if (this.acao !== TipoAcao.Criacao) {
      let isAdminMaster = this.outsideData.grupoAspNetUsers.filter(
        (u: any) => u.grupo.nome === "Admin Master"
      );
      let isUserLoggedAdmProfile = this.userLogged.value.grupoAspNetUsers.filter(
        (u: any) => u.grupo.nome === "Administrador"
      );

      if (isAdminMaster.length > 0) {
        this.disabledChangeProfile = true;
        this.editable = false;
      } else {
        if (
          this.userLogged.value.id !== this.outsideData.id &&
          this.editable !== false &&
          isUserLoggedAdmProfile.length > 0
        ) {
          this.disabledChangeProfile = false;
        }
      }
    } else {
      this.disabledChangeProfile = false;
    }

    this.fetchClienteUsuario();
  }

  ngAfterContentChecked() {
    this.setValuesOnFields();
  }

  onPhoneChanged() {
    if (this.phoneNumber.length <= 10 && this.phoneMask === this.CELLPHONE) {
      this.phoneMask = this.LANDLINE;
    } else if (
      this.phoneNumber.length === 10 &&
      this.phoneMask === this.LANDLINE &&
      this.previusLength === 10
    ) {
      this.phoneMask = this.CELLPHONE;
    }

    this.previusLength = this.phoneNumber.length;
  }

  tabChanged(currentTab: NbTabComponent) {
    this.selectedTab =
      currentTab.tabTitle === this.newOrEditTabTitle
        ? "newOrEdit"
        : "userGroupConfig";
  }

  fetchAndSetInitialData() {
    this.fetchCompanies();
    if (this.isJustOneCompany) {
      this.companyId = this.authService.getDefaultCompanyId();
      this.data.empresaId = this.companyId;
      this.fetchGroups(this.companyId);
    }
  }

  fetchCompanies() {
    this.subscriptions.add(
      this.companyService.fetch().subscribe((response: Response) => {
        this.companies = response.value as ICompany[];
      })
    );
  }

  fetchGroups(companieId: string) {
    this.subscriptions.add(
      this.groupService.fetch(companieId).subscribe((response: Response) => {
        this.groups = response.value as Group[];
      })
    );
  }

  setValuesOnFields() {
    if (this.outsideData && this.isLoading) {
      this.data = {
        id: this.outsideData.id,
        nome: this.outsideData.nome,
        cpf:
          this.outsideData.funcionario === undefined
            ? this.outsideData.cpf
            : this.outsideData.funcionario.cpf,
        login: this.outsideData.login,
        email: this.outsideData.email,
        telefone: this.outsideData.telefone,
        empresaId: this.outsideData.empresa.id,
        tipoUsuario: this.outsideData.tipoUsuario,
        telefoneResidencial: this.outsideData.telefoneResidencial,
        urlImagem: "",
        dataCriacao: moment(this.outsideData.dataCriacao).format(
          "DD/MM/YYYY HH:mm:ss"
        ),
        dataUltimaAtualizacao: moment(
          this.outsideData.dataUltimaAtualizacao
        ).format("DD/MM/YYYY HH:mm:ss"),
        clienteId: ""
      };

      var gruposFora = this.outsideData.grupoAspNetUsers.map(
        item => item.grupo.id
      );

      if (gruposFora.length > 0) {
        this.userGroups = gruposFora[0];
        //this.funcionarioService
        this.userService
          .fetchUserById(this.outsideData.id)
          .subscribe((response: ResponseGET) => {
            const usersFound = response.value as Funcionario;
            this.data.nome =
              usersFound.nome === undefined ? "" : usersFound.nome;
            this.data.cpf = usersFound.cpf === undefined ? "" : usersFound.cpf;
          });
      }

      this.isLoading = false;
    }
  }

  fetchClienteUsuario() {
    if (this.outsideData && this.isLoading) {
      this.clienteService
        .fetchClienteUsuarioPorUsuario(this.outsideData.id)
        .subscribe(({ value }) => {
          var clientesresp = value.map(x => x.cliente.id);

          this.data = { ...this.data, clienteId: clientesresp[0] };
        });
    }
  }

  fetchCliente() {
    this.clienteService.fetch(1, 9999).subscribe(({ value }) => {
      var clientes = value as GetCliente[];
      this.clientes = clientes.filter(x => x.ativo);
    });
  }

  save() {
    this.putOrPostUser();
    this.putOrPostUserGroup();
  }

  putOrPostUserGroup() {
    //só se for backoffice
    if (this.outsideData) {
      const originalUserGroups = this.outsideData.grupoAspNetUsers.map(
        item => item.grupo.id
      );
      const includedUserGroups = this.userGroups;
      const excludedUserGroups = originalUserGroups;

      const includeRequests = [
        this.userService.saveUserGroups(this.data.id, includedUserGroups)
      ];
      const excludeRequests = this.outsideData.grupoAspNetUsers
        .filter(item => excludedUserGroups.includes(item.grupo.id))
        .map(item => this.userService.deleteUserGroups(item.id));

      if (includeRequests.length || excludeRequests.length) {
        this.subscriptions.add(
          forkJoin(
            includeRequests.concat(excludeRequests)
          ).subscribe((responsesFork: Response[]) => {})
        );
      } else {
      }
    }
  }

  putOrPostUser() {
    this.isLoading = true;
    try {
      if (this.data.id) {
        const formData = new FormData();
        formData.append("empresaId", this.data.empresaId);
        formData.append("nome", this.data.nome);
        formData.append("login", this.data.login);
        formData.append("id", this.data.id);
        formData.append("telefone", this.data.telefone);
        formData.append("email", this.data.email);
        formData.append("tipoUsuario", this.data.tipoUsuario.toString());
        formData.append("cpf", this.data.cpf);
        formData.append("clienteId[]", this.data.clienteId);

        this.subscriptions.add(
          this.userService.updateUser(this.data, formData).subscribe(
            (response: Response) => {
              this.nbToastrService.success(
                "Registro salvo com sucesso",
                "Sucesso",
                {
                  duration: 2000
                }
              );
              this.close();
            },
            err => {
              //console.log(err)
              //this.close();
            }
          )
        );
      } else {
        this.subscriptions.add(
          this.userService.save(this.data).subscribe(
            (response: Response) => {
              if (!response.hasError) {
                const savedUser = response.value as GetUser;
                this.data = {
                  ...this.data,
                  id: savedUser.id,
                  email: savedUser.email,
                  login: savedUser.login,
                  nome: savedUser.nome,
                  telefone: savedUser.telefone,
                  tipoUsuario: savedUser.tipoUsuario,
                  cpf: savedUser.cpf
                };

                //salva grupos
                if (this.userGroups.length) {
                  this.subscriptions.add(
                    forkJoin(
                      this.userService.saveUserGroups(
                        this.data.id,
                        this.userGroups
                      )
                    ).subscribe((responsesFork: Response[]) => {
                      // this.nbToastrService.success(
                      //   "Registro salvo com sucesso",
                      //   "Sucesso",
                      //   {
                      //     duration: 2000
                      //   }
                      // );
                      // this.close();
                    })
                  );
                }

                this.nbToastrService.success(
                  "Registro salvo com sucesso",
                  "Sucesso",
                  {
                    duration: 2000
                  }
                );

                this.close();
              } else {
                var erro = response.errors.join("\n");
                this.nbToastrService.warning(erro, "Alerta", {
                  duration: 2000
                });
              }
            },
            err => {
              // console.log(err)
              //this.close();
            }
          )
        );
      }
    } finally {
      this.isLoading = false;
    }
  }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
