import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import Response from "../models/ResponseGET";
import { AppConfig } from "src/config/app.config";

@Injectable({
  providedIn: "root"
})
export class EstacaoService {
  private BASE_URL = `${AppConfig.settings.urlApi}`;
  constructor(private http: HttpClient) {}

  fetch(page: number, rowsPerPage: number) {
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/ClienteAntena?ascending=false&take=${rowsPerPage}&page=${page}`
    );
  }

  ativarDesativar(id, ativar) {
    return this.http.delete<any>(
      `${AppConfig.settings.urlApi}/ClienteAntena/ativarDesativar/${id}`,
      ativar
    );
  }

  removeBaseAntena(id: string) {
    return this.http.delete<any>(`${AppConfig.settings.urlApi}/ClienteAntena/RemoverBase/${id}`);
  }

  save(params: any) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/ClienteAntena/CadastrarClienteAntena`,
      params
    );
  }

  update(params: any, id: string) {
    return this.http.put<any>(
      `${AppConfig.settings.urlApi}/ClienteAntena/AtualizarClienteAntena/${id}`,
      params
    );
  }

  fetchMinMaxEstacao(id: string) {
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/ClienteAntenaMinMax/GetById/${id}`
    );
  }

  saveMinMax(params: any) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/ClienteAntenaMinMax/CadastrarMinMax`,
      params
    );
  }
  
  getAntenaByWebService(id: number) {
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/ClienteAntena/GetAntenaByWebService?id=${id}`
    );
  }

  getMinMaxParamsByClientId(id: number) {    
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/ClienteAntenaMinMax/GetMinMaxId/${id}`
    );
  }

  getHistoricoAntena(
    dataInical,
    dataFinal,
    horainicial,
    horafinal,
    webServiceId,
    isn
  ) {
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/ClienteAntena/GetHistoricoAntena?dataInical=${dataInical}&dataFinal=${dataFinal}&horaInicial=${horainicial}&horaFinal=${horafinal}&webServiceId=${webServiceId}&isn=${isn}`
    );
  }
}
