import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from "@angular/core";
import { NbDialogRef, NbToastrService } from "@nebular/theme";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-decision-alert",
  templateUrl: "./decision-alert-modal.component.html",
  styleUrls: ["./decision-alert-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DecisionAlertModalComponent implements OnInit {
  @Input() tipo: TipoMensagem;
  @Input() materialDescricao: string;
  textoTitulo: string;
  textoMensagem: string;
  textoBotao: string;
  textoBotao2: string;
  imagemMensagem: string;
  textoMaterial: string = "";

  constructor(
    protected dialogRef: NbDialogRef<DecisionAlertModalComponent>,
    private helperService: HelperService,
    private nbToastrService: NbToastrService
  ) {}

  ngOnInit() {
    switch (this.tipo) {
      case TipoMensagem.Deletar:
        this.textoTitulo = "Remoção de registro";
        this.textoMensagem =
          "Deseja mesmo prosseguir com a remoção do registro?";
        this.textoBotao = "Remover";
        break;
      case TipoMensagem.Ativar:
        this.textoTitulo = "Ativação de registro";
        this.textoMensagem =
          "Deseja mesmo prosseguir com a ativação do registro?";
        this.textoBotao = "Ativar";
        break;
      case TipoMensagem.Inativar:
        this.textoTitulo = "Inativação de registro";
        this.imagemMensagem = "../../../assets/images/alert.png";
        this.textoMensagem =
          "Deseja mesmo prosseguir com a inativação do registro?";
        this.textoBotao = "Inativar";
        break;
      case TipoMensagem.Redirecionamento:
        this.textoTitulo = "Vapt Conecta";
        this.textoMensagem =
          "Você irá abrir o sistema externo do Vapt Conecta, deseja continuar?";
        this.textoBotao = "Abrir";
        break;
      case TipoMensagem.Material:
        this.textoTitulo = "Vapt Epi";
        this.textoMensagem = `Os materiais abaixo já estão vinculados a outra foto. 
          Ao confirmar, o sistema irá substituir para a foto atual. 
          Deseja continuar?`;
        this.textoBotao = "Confirmar";
        this.textoMaterial = this.materialDescricao;
        break;

      case TipoMensagem.EscolherCoordenadas:
        this.textoTitulo =
          "Deseja ver a posição atual, ou a última posição sincronizada?";
        this.textoMensagem = `Recomendado ver a última posição, pois a posição atual demora alguns minutos!`;
        this.textoBotao = "Posição Atual";
        this.textoBotao2 = "Última Posição";
        this.textoMaterial = this.materialDescricao;
        break;

      default:
        this.textoTitulo = "Remoção de registro";
        this.textoMensagem =
          "Deseja mesmo prosseguir com a remoção do registro?";
        this.textoBotao = "Remover";
        break;
    }
  }

  cancel() {
    if (
      this.tipo !== TipoMensagem.Redirecionamento &&
      this.tipo !== TipoMensagem.EscolherCoordenadas
    ) {
      this.nbToastrService.warning(`${this.textoTitulo} cancelada`, "Alerta");
    }

    this.helperService.closeModal(true);
    this.dialogRef.close("cancel");
  }

  submit() {
    this.helperService.closeModal(true);
    if (this.tipo === TipoMensagem.Redirecionamento) {
      this.dialogRef.close("abrir");
    } else if (this.tipo === TipoMensagem.EscolherCoordenadas) {
      this.dialogRef.close("atual");
    } else {
      this.dialogRef.close("remove");
    }
  }

  submit2() {
    this.helperService.closeModal(true);
    if (this.tipo === TipoMensagem.Redirecionamento) {
      this.dialogRef.close("abrir");
    } else if (this.tipo === TipoMensagem.EscolherCoordenadas) {
      this.dialogRef.close("ultima");
    } else {
      this.dialogRef.close("remove");
    }
  }
}

export enum TipoMensagem {
  Deletar = "Deletar",
  Ativar = "Ativar",
  Inativar = "Inativar",
  Redirecionamento = "Redirecionar",
  Material = "Material",
  EscolherCoordenadas = "EscolherCoordenadas"
}
