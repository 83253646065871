import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "src/config/app.config";

@Injectable({
  providedIn: "root"
})
export class WebserviceService {
  private BASE_URL = `${AppConfig.settings.urlApi}`;
  constructor(private http: HttpClient) {}

  fetch(): any {
    return this.http.get<any>(`${this.BASE_URL}/WebService/listar`);
  }
  getById(id) {
    return this.http.get<any>(`${this.BASE_URL}/WebService/` + `${id}`);
  }

  getParametrosByAntenaIdWebserviceId(webserviceId: number, antenaId: string) {
    return this.http.get<any>(
      `${this.BASE_URL}/Parametro/listar?webservice=${webserviceId}&antena=${antenaId}`
    );
  }

  getCategorias() {
    return this.http.get<any>(`${this.BASE_URL}/WebService/listarCategorias`);
  }

  getParametrosCadastrados() {
    return this.http.get<any>(`${this.BASE_URL}/Parametro/listar`);
  }

  getListaParametros() {
    return this.http.get<any>(`${this.BASE_URL}/WebService/listarParametros`);
  }

  novoParametro(data: any) {
    let obj = {
      parametro: data.parametro,
      antena: data.antena,
      webserviceId: data.webserviceId,
      unidadeMedida: data.unidadeMedida,
      parametroNome: data.nomeParametro,
      categoria: data.categoria,
      numeroCasasDecimais: data.numeroCasasDecimais,
      ordem: data.ordem
    };

    return this.http.post<any>(`${this.BASE_URL}/Parametro/CadastrarParametro`, obj);
  }

  // updateParametro(data: any) {
  //   return this.http.put<any>(
  //     `${AppConfig.settings.urlApi}/Parametro/${data.id}`,
  //     data
  //   );
  // }

  updateParametro(data: any) {
    return this.http.put<any>(
      `${AppConfig.settings.urlApi}/Parametro/atualizar`,
      data
    );
  }

  cloneParametros(data: any) {
    return this.http.get<any>(`
      ${AppConfig.settings.urlApi}/parametro/${data.webserviceIdDestino}/${data.antenaIdOrigem}/${data.antenaIdDestino}
    `);
  }

  updateWebService(data: any) {
    return this.http.put<any>(
      `${AppConfig.settings.urlApi}/WebService/${data.id}`,
      data
    );
  }

  deleteParametro(id) {
    return this.http.delete<any>(
      `${AppConfig.settings.urlApi}/Parametro/${id}`
    );
  }
}
