import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import PostPutUser from "../models/PostPutUser";
import { AppConfig } from "src/config/app.config";
import Response from "../models/ResponseGET";
import FilterUsuario from "../models/FilterUsuario";

@Injectable({
  providedIn: "root"
})
export class UserService {
  private BASE_URL = `${AppConfig.settings.urlApi}`;
  constructor(private http: HttpClient) {}

  fetch(page: number, rowsPerPage: number, companieId: string) {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/Usuario?EmpresaId=${companieId}&ascending=false&take=${rowsPerPage}&page=${page}`
    );
  }

  fetchAllUsersTipoCliente() {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/Usuario?TipoUsuario=1`
    );
  }

  search(
    filterUsuario: FilterUsuario,
    page: number,
    rowsPerPage: number,
    companieId: string
  ) {
    return this.http.get<any>(`${AppConfig.settings.urlApi}/Usuario`, {
      params: {
        login: filterUsuario.matricula,
        cpf: filterUsuario.cpf,
        nome: filterUsuario.nome,
        grupoid: filterUsuario.codigoGrupo,
        empresaid: companieId,
        take: rowsPerPage?.toString(),
        page: page?.toString()
      }
    });
  }

  searchUsers(
    filterText: string,
    page: number,
    rowsPerPage: number,
    companieId: string
  ) {
    return this.http.get<any>(`${AppConfig.settings.urlApi}/Usuario`, {
      params: {
        nome: filterText,
        empresaid: companieId,
        take: rowsPerPage?.toString(),
        page: page?.toString()
      }
    });
  }

  save(params: PostPutUser) {
    return this.http.post<any>(`${AppConfig.settings.urlApi}/Usuario`, {
      ...params,
      clienteId: [params.clienteId]
    });
  }

  updateUser(params: PostPutUser, dados: any) {
    return this.http.put<any>(
      `${AppConfig.settings.urlApi}/Usuario/${params.id}`,
      dados
    );
  }

  saveUserGroups(userId: string, grupoId: string) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/Usuario/${userId}/grupo`,
      {
        grupoId
      }
    );
  }

  deleteUserGroups(grupoId: string) {
    return this.http.delete<any>(
      `${AppConfig.settings.urlApi}/Usuario/grupo/${grupoId}`
    );
  }

  fetchUserById(userId: string) {
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/Usuario/${userId}`
    );
  }

  fetchPermissions(userId: string) {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/Usuario/${userId}/permissoes`
    );
  }

  fetchUsuarioCliente(userId: string) {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/ClienteUsuario?usuarioId=${userId}`
    );
  }

  allPermissions() {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/Dominio/EnumPermissao`
    );
  }

  changeStatusUser(id: string) {
    return this.http.delete<any>(`${AppConfig.settings.urlApi}/Usuario/${id}`);
  }

  removeUserBase(id: string) {
    return this.http.delete<any>(`${AppConfig.settings.urlApi}/Usuario/RemoverBase/${id}`);
  }

  unlock(userId: string) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/Usuario/${userId}/desbloquear`,
      userId
    );
  }

  changePassword(params) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/Login/senha/alterar`,
      params
    );
  }
}
