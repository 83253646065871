import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { News } from "../models/News";
import { IPostPutCampaigns } from "../models/PostPutCampaigns";
import Response from "../models/ResponseGET";
import { AppConfig } from "src/config/app.config";

@Injectable({
  providedIn: "root"
})
export class CampaignService {
  private BASE_URL = `${AppConfig.settings.urlApi}`;
  constructor(private http: HttpClient) {}

  fetch(page: number, rowsPerPage: number, companieId: string, sort: string) {
    return companieId
      ? this.http.get<Response>(
          `${AppConfig.settings.urlApi}/Campanha?empresaId=${companieId}&sortingProp=${sort}&ascending=false&take=${rowsPerPage}&page=${page}&landing=true`
        )
      : this.http.get<Response>(
          `${AppConfig.settings.urlApi}/Campanha?ascending=false&take=${rowsPerPage}&page=${page}`
        );
  }

  fetchByID(id: string) {
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/Campanha/${id}`
    );
  }

  fetchByIdDescricao(id: string) {
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/Campanha/${id}/Descricao`
    );
  }

  fetchFiltered(
    firstDate?: string,
    endDate?: string,
    title?: string,
    published?: boolean,
    buscarTudo?: boolean,
    take?: number,
    page?: number
  ) {
    const urlFiltered = [
      firstDate ? "dataInicio=" + firstDate : null,
      endDate ? "dataFim=" + endDate : null,
      title ? "titulo=" + title : null,
      published ? "situacao=Publicada" : null,
      buscarTudo ? "buscarTudo=" + buscarTudo : false
    ]
      .filter(item => !!item)
      .join("&");
    return urlFiltered.length > 0
      ? this.http.get<Response>(
          `${AppConfig.settings.urlApi}/Campanha?take=${take}&page=${page}&sortingProp=dataPublicacao&ascending=false&${urlFiltered}`
        )
      : this.http.get<Response>(
          `${AppConfig.settings.urlApi}/Campanha?take=${take}&page=${page}&sortingProp=dataPublicacao&ascending=false`
        );
  }

  fetchLastCampaign(amount: number, published?: boolean, landing?: boolean) {
    const urlFiltered = published ? "situacao=Publicada&" : "";
    const landingFiltered = landing ? "landing=true&" : "landing=false&";

    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/Campanha?take=${amount}&${urlFiltered}offset=0&${landingFiltered}sortingProp=dataPublicacao&ascending=false`
    );
  }

  fetchByDate(
    page: number,
    rowsPerPage: number,
    companieId: string,
    firstDate: string,
    endDate: string,
    titulo: string,
    published?: boolean
  ) {
    const urlFiltered = published ? "situacao=Publicada&" : "";
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/Campanha?empresaId=${companieId}&${urlFiltered}dataInicio=${firstDate}&dataFim=${endDate}&titulo=${titulo}&take=${rowsPerPage}&page=${page}`
    );
  }

  delete(id: string) {
    return this.http.delete<any>(`${AppConfig.settings.urlApi}/Campanha/${id}`);
  }

  save(dados: any) {
    return this.http.post<Response>(
      `${AppConfig.settings.urlApi}/Campanha`,
      dados
    );
  }

  update(dados: any, params: IPostPutCampaigns) {
    return this.http.put<Response>(
      `${AppConfig.settings.urlApi}/Campanha/${params.id}`,
      dados
    );
  }

  addFile(params: any, campanhaId: string) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/Campanha/${campanhaId}/pecas`,
      params
    );
  }

  deletePeca(id: string) {
    return this.http.delete<Response>(
      `${AppConfig.settings.urlApi}/Campanha/${id}/pecas`
    );
  }
}
