import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  AfterViewInit
} from "@angular/core";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import IPostPutNews from "src/app/models/PostPutNews";
import { RichtextComponent } from "src/app/components/richtext/richtext.component";
import { NewsService } from "src/app/services/news.service";
import { HelperService } from "src/app/services/helper.service";
import { Subscription } from "rxjs";
import IResponseGET from "src/app/models/ResponseGET";
import { News } from "src/app/models/News";
import { CompanyService } from "src/app/services/company.service";
import { AuthService } from "src/app/services/auth.service";
import ICompany from "src/app/models/Company";
import { AlertService } from "src/app/services/alert.service";
import { CurrentUserService } from "src/app/services/current-user.service";
import { Util } from "src/app/helpers/util";
import dayjs from "dayjs";
import imageCompression from "browser-image-compression";
import {
  DecisionAlertModalComponent,
  TipoMensagem
} from "src/app/components/decision-alert/decision-alert-modal.component";
import { NgForm } from "@angular/forms";
import { CropperImageModalComponent } from "src/app/components/cropper-image-modal/cropper-image-modal.component";

@Component({
  selector: "app-new-edit-news-modal",
  templateUrl: "./new-edit-news-modal.component.html",
  styleUrls: ["./new-edit-news-modal.component.scss"]
})
export class NewEditNewsModalComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(RichtextComponent) richEditor: RichtextComponent;
  @Input() outsideData: News;

  compressOptions = {
    maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight: 1920, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    useWebWorker: true // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
  };

  subscriptions = new Subscription();
  companyId: string;
  data: IPostPutNews = {
    fotoExcluida: false,
    fotoDetalhe: null,
    urlImagemDetalhe: "",
    urlImagem: "",
    file: null,
    id: "",
    titulo: "",
    foto: "",
    descricao: "",
    situacao: 0,
    empresaId: "",
    dataPublicacao: null,
    nomeUsuario: ""
  };
  isLoading: boolean;
  isPublishing: boolean = false;
  isJustOneCompany: boolean = this.authService.getIsJustOneCompany();
  companies = [] as ICompany[];
  textoPublicar: "Publicar Notícia" | "Remover Publicação" = "Publicar Notícia";
  isPhotoLoaded = true;

  selectedImgCover: any;
  selectedImgDetail: any;

  constructor(
    private alert: AlertService,
    private newsService: NewsService,
    private helperService: HelperService,
    private nbDialogRef: NbDialogRef<NewEditNewsModalComponent>,
    private companyService: CompanyService,
    private authService: AuthService,
    private changeDetection: ChangeDetectorRef,
    public uService: CurrentUserService,
    private nbDialogService: NbDialogService,
    private util: Util
  ) {}

  ngOnInit() {
    this.fetchAndSetInitialData();
    this.isLoading = this.outsideData ? true : false;
  }

  ngAfterContentChecked() {
    this.setValuesOnFields();
  }

  ngAfterViewInit() {
    this.subscriptions.add(
      this.newsService
        .fetchByIdDescricao(this.outsideData.id)
        .subscribe((response: IResponseGET) => {
          const news = response.value as News;
          this.data.descricao = news.descricao;
          this.richEditor.content = this.data.descricao;
          this.changeDetection.detectChanges();
        })
    );
  }

  fetchCompanies() {
    this.subscriptions.add(
      this.companyService.fetch().subscribe((response: IResponseGET) => {
        this.companies = response.value as ICompany[];
      })
    );
  }

  fetchAndSetInitialData() {
    this.fetchCompanies();
    if (this.isJustOneCompany) {
      this.companyId = this.authService.getDefaultCompanyId();
      this.data.empresaId = this.companyId;
    }
  }

  setValuesOnFields() {
    if (this.outsideData && this.isLoading) {
      this.isPhotoLoaded = false;
      this.data = {
        file: null,
        fotoExcluida: false,
        urlImagem: this.outsideData.urlImagem,
        fotoDetalhe: this.outsideData.fotoDetalhe,
        urlImagemDetalhe: this.outsideData.urlImagemDetalhe,
        id: this.outsideData.id,
        descricao: this.outsideData.descricao,
        empresaId: this.companyId,
        foto: this.outsideData.foto,
        situacao: this.outsideData.situacao,
        titulo: this.outsideData.titulo,
        nomeUsuario: this.outsideData.nomeUsuario,
        dataPublicacao:
          this.outsideData.dataPublicacao === undefined
            ? null
            : new Date(
                dayjs(this.outsideData.dataPublicacao).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              )
      };

      this.verifyStatus();
      this.isPhotoLoaded = true;
      this.isLoading = false;
    }
  }

  verifyStatus() {
    this.textoPublicar =
      this.data.situacao === 0 ? "Publicar Notícia" : "Remover Publicação";
  }

  async fileChosen(event: Event, kindOfFile: "cover-landing" | "read-image") {
    this.isPhotoLoaded = kindOfFile !== "cover-landing";
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length) {
      const file = target.files.item(0);

      const croppedImage =
        kindOfFile === "cover-landing"
          ? await this.nbDialogService
              .open(CropperImageModalComponent, {
                autoFocus: false,
                closeOnEsc: false,
                closeOnBackdropClick: false,
                context: {
                  imageChangedEvent: event,
                  showAlertAfterCropping: true,
                  maintainAspectRatio: true,
                  cropperMinWidth: 677.33,
                  cropperMinHeight: 381,
                  textLabel:
                    "Esta imagem será exibida como capa e também nos cards de notícia. Para que a exibição fique adequada, a proporção do recorte é fixada em 1,77 e a resolução mínima em aprox. 677px x 381px."
                }
              })
              .onClose.toPromise()
          : kindOfFile === "read-image"
          ? await this.nbDialogService
              .open(CropperImageModalComponent, {
                autoFocus: false,
                closeOnEsc: false,
                closeOnBackdropClick: false,
                context: {
                  imageChangedEvent: event,
                  showAlertAfterCropping: true,
                  maintainAspectRatio: true,
                  aspectRatio: 1366 / 360,
                  cropperMinWidth: 1366,
                  cropperMinHeight: 360,
                  textLabel:
                    "Esta imagem será exibida em cima da notícia, na tela de leitura. Para que a exibição fique adequada, a proporção do recorte é fixada em 3,79 e a resolução mínima em aprox. 1366px x 360px."
                }
              })
              .onClose.toPromise()
          : null;

      if (croppedImage) {
        const compressedFile = await this.compressImage(croppedImage);
        const reader = new FileReader();
        reader.readAsDataURL(compressedFile);
        reader.onload = () => {
          if (kindOfFile === "cover-landing") {
            this.data.file = compressedFile;
            this.data.urlImagem = reader.result.toString();
          } else if (kindOfFile === "read-image") {
            this.data.fotoDetalhe = compressedFile;
            this.data.urlImagemDetalhe = reader.result.toString();
          }
          this.isPhotoLoaded = true;
        };
      }
      this.selectedImgCover = null;
      this.isPhotoLoaded = true;
    } else {
      this.isPhotoLoaded = false;
      this.alert.warning("O recorte da imagem é obrigatório", "Ooops");
    }
  }

  async compressImage(file: any) {
    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      });
      return compressedFile as File;
    } catch (ex) {
      this.alert.error("Houve um problema ao realizar a compressão da imagem");
      console.log(ex);
      return null;
    }
  }

  async removeFile() {
    try {
      const decision: "cancel" | "remove" = await this.nbDialogService
        .open(DecisionAlertModalComponent, {
          autoFocus: false,
          context: { tipo: TipoMensagem.Deletar }
        })
        .onClose.toPromise();
      if (decision === "remove") {
        this.isPhotoLoaded = false;
        this.data.fotoDetalhe = null;
        this.data.urlImagemDetalhe = "";
        this.data.file = null;
        this.data.fotoExcluida = true;
        this.alert.success("Remoção efetuada com sucesso");
      }
    } catch (ex) {
      this.alert.error(
        "Não foi possível remover a foto da notícia",
        "Ocorreu um problema"
      );
      console.error(ex);
    } finally {
      this.isPhotoLoaded = true;
    }
  }

  isValidForm(form: NgForm) {
    if (!this.data.titulo || this.richEditor.content === "") {
      this.alert.warning(
        "É necessário preencher todos os campos obrigatórios, inclusive o corpo da notícia"
      );
      return false;
    } else {
      return true;
    }
  }

  async putNews(publishing: boolean, formData: FormData) {
    try {
      this.isPublishing = publishing;
      const response = await this.newsService
        .update(this.data, formData)
        .toPromise();
      this.alert.success(
        publishing && this.data.situacao === 1
          ? "Publicação efetuada com sucesso"
          : publishing && this.data.situacao === 0
          ? "Publicação removida com sucesso"
          : "Registro salvo com sucesso",
        "Sucesso",
        {
          duration: 2000
        }
      );

      if (publishing && this.data.situacao === 1) {
        this.data.dataPublicacao = new Date(
          dayjs().format("YYYY-MM-DD HH:mm:ss")
        );
      }

      this.isPublishing = false;
      if (!publishing) {
        this.close();
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  async postNews(formData: FormData) {
    try {
      const response = await this.newsService.save(formData).toPromise();
      const savedNews = response.value as News;
      this.data.id = savedNews.id;
      this.alert.success("Registro salvo com sucesso.");
      this.isPublishing = false;
    } catch (ex) {
      console.error(ex);
    }
  }

  putPostPublish() {
    this.data.situacao = this.textoPublicar === "Publicar Notícia" ? 1 : 0;
    this.textoPublicar =
      this.textoPublicar === "Publicar Notícia"
        ? "Remover Publicação"
        : "Publicar Notícia";
    this.save(null, true);
  }

  save(form: NgForm, publishing?: boolean) {
    if (this.isValidForm(form)) {
      this.data.descricao = this.richEditor.content;
      this.isLoading = true;
      try {
        const formData = new FormData();
        formData.append("file", this.data.file);
        formData.append("fotoDetalhe", this.data.fotoDetalhe);
        formData.append("descricao", this.data.descricao);
        this.data.dataPublicacao?.toJSON() &&
          formData.append("dataPublicacao", this.data.dataPublicacao.toJSON());
        formData.append("titulo", this.data.titulo);
        formData.append("situacao", this.data.situacao.toString());
        formData.append("empresaId", this.data.empresaId);
        formData.append("fotoExcluida", this.data.fotoExcluida + "");

        if (this.data.id) {
          this.putNews(publishing, formData);
        } else {
          this.postNews(formData);
        }
      } finally {
        this.isLoading = false;
      }
    } else {
      form.resetForm(form.value);
    }
  }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
