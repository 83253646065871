import { Component, OnInit } from "@angular/core";
import { Input, AfterContentChecked, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { NbDialogRef, NbTabComponent, NbToastrService } from "@nebular/theme";
import { HelperService } from "src/app/services/helper.service";
import { EstacaoService } from "src/app/services/estacao.service";
import { WebserviceService } from "src/app/services/webservice.service";
@Component({
  selector: "app-new-edit-webservice-modal",
  templateUrl: "./new-edit-webservice-modal.component.html",
  styleUrls: ["./new-edit-webservice-modal.component.scss"]
})
export class NewEditWebserviceModalComponent
  implements OnInit, AfterContentChecked, OnDestroy {
  @Input() outsideData: any;
  @Input() editable: boolean = true;

  isLoading: boolean;
  subscriptions = new Subscription();

  data: any = {
    id: "",
    nome: "",
    url: " ",
    login: "",
    senha: " ",
    horaConsulta: "00" + ":" + "00"
  };

  constructor(
    private nbDialogRef: NbDialogRef<NewEditWebserviceModalComponent>,
    private helperService: HelperService,
    private webservice: WebserviceService,
    private nbToastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.isLoading =
      this.outsideData && Object.keys(this.outsideData).length !== 0
        ? true
        : false;
  }
  save() {
    this.subscriptions.add(
      this.webservice.updateWebService(this.data).subscribe(response => {
        this.nbToastrService.success("Registro salvo com sucesso", "Sucesso", {
          duration: 2000
        });
        this.close();
      })
    );
  }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  ngAfterContentChecked() {
    this.setValuesOnFields();
  }
  setValuesOnFields() {
    if (
      this.outsideData &&
      Object.keys(this.outsideData).length !== 0 &&
      this.isLoading
    ) {
      const hora = this.outsideData.horaConsulta.split(":");
      this.data = {
        id: this.outsideData.id,
        nome: this.outsideData.nome,
        url: this.outsideData.url,
        login: this.outsideData.login,
        senha: this.outsideData.senha,
        horaConsulta: hora[0] + ":" + hora[1] || "00" + ":" + "00"
      };
      this.isLoading = false;
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
