import { Component, OnInit } from "@angular/core";
import { TableBase } from "src/app/models/PagesComponents";
import FilterEstacao from "src/app/models/FilterEstacao";
import { CurrentUserService } from "src/app/services/current-user.service";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import {
  DecisionAlertModalComponent,
  TipoMensagem
} from "src/app/components/decision-alert/decision-alert-modal.component";

import { ColumnObject, DataTableActions } from "src/app/models/DataTable";

import { Subscription } from "rxjs";

import { HelperService } from "src/app/services/helper.service";
import { WebserviceService } from "src/app/services/webservice.service";
import { NewEditWebserviceModalComponent } from "../new-edit-webservice-modal/new-edit-webservice-modal.component";

@Component({
  selector: "app-webservice",
  templateUrl: "./webservice.component.html",
  styleUrls: ["./webservice.component.scss"]
})
export class WebserviceComponent implements OnInit, TableBase {
  selectedPage: number = 1;
  rowsPerPage: number = 10;
  totalRecords: number;

  companyId: string;
  isLoaded = false;
  permissions = this.uService.getEnumPermissions();
  subscriptions = new Subscription();
  data = [] as any[];

  columns: ColumnObject = {
    nome: {
      title: "Web Service",
      sort: true,
      sortDirection: "asc"
    },
    url: {
      title: "Rota",
      sort: true,
      sortDirection: "asc"
    },
    horaConsulta: {
      title: "Intervalo de buscas (h)",
      sort: true,
      sortDirection: "asc"
    }
  };

  constructor(
    public uService: CurrentUserService,
    private webservice: WebserviceService,
    private helperService: HelperService,
    private nbDialogService: NbDialogService,
    private nbToastrService: NbToastrService
  ) {}

  includeActionsColumnOnData(item: any) {
    const buttons = this.uService.getActionsTableAllowed(
      this.permissions.AtualizarEstacao,
      null,
      null,
      null,
      null,
      null,
      null,
      this.permissions.ConsultarEstacao
    );

    const dataWithActions = {
      ...item,
      actions: {
        buttons,
        viewEvent: () => this.view(item),
        editEvent: () => this.edit(item)
      } as DataTableActions
    };

    return dataWithActions;
  }

  view(rowData: any) {
    this.nbDialogService.open(NewEditWebserviceModalComponent, {
      autoFocus: false,
      context: { outsideData: rowData, editable: false }
    });
  }

  edit(rowData: any) {
    this.nbDialogService.open(NewEditWebserviceModalComponent, {
      autoFocus: false,
      context: { outsideData: rowData, editable: true }
    });
  }

  changePage(selectedPage: number) {
    this.selectedPage = selectedPage || this.selectedPage;
    this.fetch();
  }

  changeRowsPerPage(selectedRowsPerPage: number) {
    this.rowsPerPage = selectedRowsPerPage || this.rowsPerPage;
    this.fetch();
  }

  fetch(): any {
    this.webservice.fetch().subscribe(results => {
      this.data = results.value.map(item =>
        this.includeActionsColumnOnData(item)
      );
      this.isLoaded = true;
    });
  }

  ngOnInit() {
    this.createRefreshDataWatcher();
  }

  createRefreshDataWatcher() {
    this.subscriptions.add(
      this.helperService.isModalToClose.subscribe(() => {
        this.refreshData(this.companyId);
      })
    );
  }

  refreshData(outsideCompanyId?: string) {
    if (this.companyId || outsideCompanyId) {
      this.fetch();
      //this.fetchGroups(this.companyId || outsideCompanyId);
    }
    this.companyId = this.companyId || outsideCompanyId;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
