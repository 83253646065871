import { Input, AfterContentChecked, OnDestroy } from "@angular/core";

import { NbDialogRef, NbToastrService } from "@nebular/theme";
import { Component, OnInit } from "@angular/core";
import { HelperService } from "src/app/services/helper.service";

import { Subscription } from "rxjs";
import { EstacaoService } from "src/app/services/estacao.service";
import { UserService } from "src/app/services/user.service";
import IPostPutEstacao from "src/app/models/PostPutEstacao";
import GetCliente from "src/app/models/Cliente";
import { ClienteService } from "src/app/services/cliente.service";
import { WebserviceService } from "src/app/services/webservice.service";
import moment from "moment";
import { LinkService } from "src/app/services/link.service";

enum Acao {
  Novo = 1,
  Editar = 2,
  Visualizar = 3
}

@Component({
  selector: "app-new-edit-estacao-modal",
  templateUrl: "./new-edit-estacao-modal.component.html",
  styleUrls: ["./new-edit-estacao-modal.component.scss"]
})
export class NewEditEstacaoModalComponent
  implements OnInit, AfterContentChecked, OnDestroy {
  @Input() outsideData: any;
  @Input() editable: boolean = true;

  webServices = [] as any[];
  links = [] as any[];
  clientes = [] as GetCliente[];
  classificacao: any[] = [];

  isLoading: boolean;
  subscriptions = new Subscription();
  data: IPostPutEstacao = {
    id: "",
    linkId: "",
    webServiceId: "",
    webService: "",
    estacaoId: "",
    estacao: "",
    nomeEstacao: "",
    classificacaoId: "",
    classificacao: "",
    clienteId: "",
    cliente: "",
    loginEstacao: "",
    senhaEstacao: "",
    latitude: "",
    longitude: "",
    dataUltimaSincronia: null,
    deletado: false,
    dataCriacao: null
  };

  showPassword = false;

  constructor(
    private nbDialogRef: NbDialogRef<NewEditEstacaoModalComponent>,
    private helperService: HelperService,
    private estacaoService: EstacaoService,
    private nbToastrService: NbToastrService,
    private linkService: LinkService,
    private webServiceService: WebserviceService,
    private clienteService: ClienteService
  ) {}

  ngOnInit() {
    this.isLoading =
      this.outsideData && Object.keys(this.outsideData).length !== 0
        ? true
        : false;
    this.fetchWebServices();
    this.fetchLinks();
    this.fetchCliente();

    this.classificacao.push({ id: 1, nome: "Fixo" });
    this.classificacao.push({ id: 2, nome: "Deriva" });
    this.classificacao.push({ id: 3, nome: "Flutuante" });
  }

  toggleShowPass() {
    this.showPassword = !this.showPassword;
  }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  fetchWebServices() {
    this.webServiceService.fetch().subscribe(({ value }) => {
      this.webServices = value;
    });
  }

  fetchLinks() {
    this.linkService.fetch().subscribe(({ value }) => {
      this.links = value;
    });
  }

  fetchCliente() {
    this.clienteService.fetch(1, 9999).subscribe(({ value }) => {
      this.clientes = value;
    });
  }

  save() {
    try {
      this.isLoading = true;

      if (this.data.linkId === ""){
        this.data.linkId = null
      }
      console.log(this.data)

      const observable = this.data.id ? this.estacaoService.update(this.data, this.data.id) : this.estacaoService.save(this.data);

      this.subscriptions.add(observable.subscribe(
          res => {
            this.nbToastrService.success(
              this.data.id ? "Editado com sucesso!" : "Estação cadastrada com sucesso!", "Sucesso", { duration: 2000 }
            );

            this.close();
          },
          err => {
            this.isLoading = false;
          }
        )
      );
    } finally {
      this.isLoading = false;
    }
  }

  ngAfterContentChecked() {
    this.setValuesOnFields();
  }

  showSuccess() {
    this.nbToastrService.success(
      "Informações alteradas com sucesso",
      "Sucesso",
      {
        duration: 3000,
        preventDuplicates: true
      }
    );
  }

  hasTooltip() {
    const tooltip = document.querySelectorAll(
      "div.cdk-overlay-container > div.cdk-overlay-connected-position-bounding-box"
    );
    tooltip[0]?.remove();
  }

  setValuesOnFields() {
    if (this.outsideData && Object.keys(this.outsideData).length !== 0 && this.isLoading) {
      this.data = {
        id: this.outsideData.id,
        webServiceId: this.outsideData.webServiceId,
        linkId: this.outsideData.linkId ?? "",
        webService: "",
        estacaoId: "",
        estacao: this.outsideData.isn,
        nomeEstacao: this.outsideData.nomeEstacao,
        classificacaoId: this.outsideData.classificacaoID,
        classificacao: "",
        clienteId: this.outsideData.clienteId,
        cliente: "",
        loginEstacao: this.outsideData.loginEstacao,
        senhaEstacao: this.outsideData.senhaEstacao,
        latitude: this.outsideData.latitude,
        longitude: this.outsideData.longitude,
        dataUltimaSincronia: this.outsideData.dataUltimaSincronia ? moment(this.outsideData.dataUltimaSincronia).format("DD/MM/YYYY HH:mm:ss") : null,
        deletado: false,
        dataCriacao: moment(this.outsideData.dataCriacao).format("DD/MM/YYYY HH:mm:ss")
      };
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
