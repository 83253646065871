import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import Response from "../models/ResponseGET";
import { AppConfig } from "src/config/app.config";
import { PostPutClienteUsuario } from "../models/Cliente";

@Injectable({
  providedIn: "root"
})
export class ClienteService {
  private BASE_URL = `${AppConfig.settings.urlApi}`;
  constructor(private http: HttpClient) {}

  fetchCompany(id: string) {
    return this.http.get<any>(`${AppConfig.settings.urlApi}/Cliente/${id}`);
  }

  activeInactiveCliente(id: string) {
    return this.http.delete<any>(`${AppConfig.settings.urlApi}/Cliente/${id}`);
  }

  removeClienteBase(id: string) {
    return this.http.delete<any>(`${AppConfig.settings.urlApi}/Cliente/RemoverBase/${id}`);
  }

  fetch(page: number, rowsPerPage: number) {
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/Cliente?ascending=false&take=${rowsPerPage}&page=${page}`
    );
  }

  fetchClienteUsuario(clienteId: string) {
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/Cliente/` + clienteId
    );
  }

  fetchClienteUsuarioPorUsuario(usuarioId: string) {
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/ClienteUsuario?usuarioId=` + usuarioId
    );
  }

  fetchClienteUsuarioTodos() {
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/ClienteUsuario`
    );
  }

  createClienteUsuario(params: PostPutClienteUsuario) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/ClienteUsuario`,
      params
    );
  }

  update(params: any, id: string) {
    return this.http.put<any>(
      `${AppConfig.settings.urlApi}/Cliente/${id}`,
      params
    );
  }

  deleteClienteUsuario(id: string) {
    return this.http.delete<any>(
      `${AppConfig.settings.urlApi}/ClienteUsuario/${id}`
    );
  }

  save(params: any) {
    return this.http.post<any>(`${AppConfig.settings.urlApi}/Cliente`, params);
  }
}
