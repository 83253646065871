import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from "@angular/core";
import { ViewCell } from "ng2-smart-table";
import { DataTableActions } from "src/app/models/DataTable";
@Component({
  selector: "app-actions-datatable",
  templateUrl: "./actions-datatable.component.html",
  styleUrls: ["./actions-datatable.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsDataTableComponent implements ViewCell, OnInit {
  @Input() value: string | number;
  @Input() rowData: any;

  actionsConfig: DataTableActions;
  showActions = false;
  showEdit = false;
  showDelete = false;
  showActiveDisable = false;
  showDownload = false;
  showReset = false;
  showUnlock = false;
  showMapa = false;
  showView = false;
  showConfigure = false;

  activeDisableButtonTooltip: "Ativar" | "Desativar";
  desbloquear: boolean = false;
  unlockTootip: "Registro desbloqueado" | "Desbloquear";
  downloadTootip: "Download" | "Ver Imagens";
  constructor() {}

  ngOnInit() {
    this.initializeAndConfigureActions();
  }

  initializeAndConfigureActions() {
    if (this.rowData && this.rowData?.actions) {
      this.actionsConfig = this.rowData?.actions;
      this.showActions = true;
      this.showEdit = this.actionsConfig.buttons.includes("edit");
      this.showDelete = this.actionsConfig.buttons.includes("delete");
      this.showActiveDisable = this.actionsConfig.buttons.includes(
        "active-disable"
      );
      this.showDownload = this.actionsConfig.buttons.includes("download");
      this.downloadTootip = "Ver Imagens";

      this.showReset = this.actionsConfig.buttons.includes("reset");
      this.showUnlock = this.actionsConfig.buttons.includes("unlock");
      this.showMapa = this.actionsConfig.buttons.includes("showMapa");
      this.activeDisableButtonTooltip = this.rowData?.inativo
        ? "Ativar"
        : "Desativar";
      this.desbloquear = this.rowData?.desbloquear;
      this.unlockTootip = this.rowData?.desbloquear
        ? "Desbloquear"
        : "Registro desbloqueado";
      this.showView = this.actionsConfig.buttons.includes("view");
      this.showConfigure = this.actionsConfig.buttons.includes("configure");
    }
  }
}
