import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  ChangeDetectionStrategy
} from "@angular/core";
import { LocalDataSource } from "ng2-smart-table";
import { ActionsDataTableComponent } from "../actions-datatable/actions-datatable.component";
import { Util } from "src/app/helpers/util";
import {
  DataTableSettings,
  ColumnProperties,
  MaskedValues,
  DatatableFilterSettings
} from "src/app/models/DataTable";
import { uniq, range, sortBy } from "lodash";

@Component({
  selector: "app-datatable",
  templateUrl: "./datatable.component.html",
  styleUrls: ["./datatable.component.scss"]
})
export class DatatableComponent implements OnInit, OnChanges {
  @Input() columns: any;
  @Input() data: any[];
  @Input() isLoaded = false;
  @Input() showSearchFields = true;
  @Input() minHeight = 300;
  @Input() totalRecords;
  @Input() selectedPage: number = 1;
  @Output() pageChanged = new EventEmitter();
  @Output() rowsPerPageChanged = new EventEmitter();

  settings = new DataTableSettings();
  rowsPerPage: number = 10;
  totalPages: number;
  source: LocalDataSource;
  dataCount = 0; // Automatic pagination, without queryParams on request's.
  showDefaultPagination: boolean = true;
  readyPages: number[] = [];

  constructor(private changeDetection: ChangeDetectorRef, public util: Util) {}

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    // Executa configuração de colunas somente se houver colunas
    if (changes.columns?.currentValue != changes.columns?.previousValue) {
      this.settings.columns = this.columns;
    }
    // Executa configuração dos dados somente se houver dados
    if (changes.data?.currentValue != changes.data?.previousValue) {
      this.handleRawData();
      this.handleActionsColumn();
      this.initializeDataSource();
    }

    if (
      changes.totalRecords &&
      changes.totalRecords.previousValue !== changes.totalRecords.currentValue
    ) {
      this.setPagination();
      this.settings.pager.display = this.showDefaultPagination;
    }
  }

  handleActionsColumn() {
    if (this.data.some(item => item?.actions)) {
      this.settings.columns.actions = {
        title: "Ações",
        filter: false,
        sort: false,
        class: "text-center",
        type: "custom",
        width: "200px",
        renderComponent: ActionsDataTableComponent
      };

      this.reRenderDatatable();
    }
  }

  handleRawData() {
    const columns = Object.keys(this.settings.columns);

    columns.forEach((columnName: string) => {
      const columnWithProperties = this.settings.columns[
        columnName
      ] as ColumnProperties;
      this.formatValuesOnData(columnName, columnWithProperties.valueType);
    });
  }

  formatValuesOnData(column: string, type: MaskedValues) {
    const dataAux = this.data;

    dataAux.forEach((item, index) => {
      dataAux[index][column] = this.util.isEmpty(dataAux[index][column])
        ? "-"
        : type === MaskedValues.cep
        ? this.util.applyMaskCEP(dataAux[index][column])
        : type === MaskedValues.cpfCnpj
        ? this.util.applyMaskCPForCNPJ(dataAux[index][column])
        : type === MaskedValues.date
        ? this.util.applyMaskDate(dataAux[index][column])
        : type === MaskedValues.dateTime
        ? this.util.applyMaskDateTime(dataAux[index][column])
        : type === MaskedValues.phone
        ? this.util.applyMaskPhone(dataAux[index][column])
        : dataAux[index][column];
    });

    this.data = dataAux;
  }

  initializeDataSource() {
    this.source = new LocalDataSource(this.data);
    this.dataCount = this.data.length;
  }

  setHideSubHeader(checked: boolean) {
    this.settings.hideSubHeader = !checked;
    this.reRenderDatatable();
  }

  setRowsPerPage(value: number) {
    this.settings.pager.perPage = value;
    this.emitRowsPerPageChange(value);
    this.setPagination();
    this.reRenderDatatable();
  }

  setPagination() {
    if (this.totalRecords && this.rowsPerPage) {
      this.totalPages = Math.ceil(this.totalRecords / this.rowsPerPage);
      this.showDefaultPagination = false;
      this.getNumberPages();
    } else if (this.totalRecords === 0 || this.rowsPerPage === 0) {
      this.showDefaultPagination = true;
    } else {
      this.showDefaultPagination = true;
      console.error(
        "totalRecords ou rowsPerPage são nulos em datatable.component.ts. A paginação default foi exibida."
      );
    }
  }

  getNumberPages() {
    this.readyPages =
      this.selectedPage + 4 > this.totalPages
        ? range(this.totalPages - 4, this.totalPages + 1)
        : range(this.selectedPage, this.selectedPage + 4);

    if (this.selectedPage !== 1 && this.selectedPage !== this.totalPages) {
      this.readyPages.unshift(this.selectedPage - 1);
    }

    this.readyPages = this.readyPages.filter(item => item > 0);
    this.readyPages = sortBy(uniq(this.readyPages), value => value);
    return this.readyPages;
  }

  emitPageChange(selectedPage: number) {
    this.selectedPage = selectedPage;
    this.getNumberPages();
    this.pageChanged.emit(selectedPage);
  }

  emitRowsPerPageChange(selectedRowsPerPage: number) {
    this.selectedPage = 1;
    this.rowsPerPage = selectedRowsPerPage;
    this.getNumberPages();
    this.rowsPerPageChanged.emit(selectedRowsPerPage);
  }

  reRenderDatatable() {
    this.isLoaded = false;
    this.changeDetection.detectChanges();
    this.isLoaded = true;
  }

  onSearch(query: string = "") {
    try {
      if (query) {
        const columns = Object.keys(this.settings.columns);

        const filteredColumns = columns.map(item => {
          return {
            field: item,
            search: query,
            filter: this.settings.columns[item].filterFunction
          } as DatatableFilterSettings;
        });

        this.source.setFilter(filteredColumns, false);
      } else {
        this.source.reset();
      }
    } finally {
      this.dataCount = this.source.count();
    }
  }
}
