import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { NbDialogRef, NbToastrService } from "@nebular/theme";
import { Util } from "src/app/helpers/util";
import MeusDados from "src/app/models/MeusDados";
import { AlertService } from "src/app/services/alert.service";
import { UserService } from "src/app/services/user.service";

import { AbstractControl, ValidatorFn } from "@angular/forms";

@Component({
  selector: "app-modal-profile-pass-word",
  templateUrl: "./modal-profile-pass-word.component.html",
  styleUrls: ["./modal-profile-pass-word.component.scss"]
})
export class ModalProfilePassWordComponent implements OnInit {
  senhaPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
  clickPushed = false;
  data: MeusDados = {
    id: "",
    nome: "",
    telefone: "",
    email: "",
    login: "",
    cpf: ""
  };

  formPassword = {
    userName: null,
    senhaAntiga: null,
    senhaNova: null,
    senhaNovaConfirm: null
  };

  formPasswordControl = {
    userName: "",
    senhaAntiga: new FormControl("", [Validators.required]),
    senhaNova: new FormControl("", [
      Validators.required,
      this.forbiddenNameValidator(this.senhaPattern)
    ]),
    senhaNovaConfirm: new FormControl("", [
      Validators.required,
      this.forbiddenNameValidator(this.senhaPattern)
    ])
  };

  forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const match = nameRe.test(control.value);
      return match ? null : { forbiddenName: { value: control.value } };
    };
  }

  showCurrentPassword = false;
  showNewPassword = false;
  showNewPasswordConfirm = false;

  constructor(
    private util: Util,
    private toastrService: NbToastrService,
    private userService: UserService
  ) {}

  ngOnInit() {
    const userdata = this.util.getDataUserToLocalStorage();
    const { login, nome, id, email, telefone, cpf } = userdata;
    this.data = {
      id: id,
      nome: nome,
      telefone: telefone,
      email: email,
      login: login,
      cpf: userdata.cpf
    };
  }

  toggleShowPassword(
    inputType: "currentPassword" | "newPassword" | "newPasswordConfirm"
  ) {
    if (inputType === "currentPassword") {
      this.showCurrentPassword = !this.showCurrentPassword;
    } else if (inputType === "newPasswordConfirm") {
      this.showNewPasswordConfirm = !this.showNewPasswordConfirm;
    } else {
      this.showNewPassword = !this.showNewPassword;
    }
  }

  save() {
    this.clickPushed = true;

    if (
      this.formPasswordControl.senhaNova.errors ||
      this.formPasswordControl.senhaAntiga.errors ||
      this.formPasswordControl.senhaNovaConfirm.errors
    )
      return;

    if (
      !this.formPasswordControl.senhaNovaConfirm.errors &&
      !this.formPasswordControl.senhaNova.errors &&
      this.formPassword.senhaNovaConfirm !== this.formPassword.senhaNova
    )
      return;

    if (
      this.formPasswordControl.senhaAntiga &&
      this.formPasswordControl.senhaNova
    ) {
      this.formPasswordControl.userName = this.data.login;

      this.userService
        .changePassword({
          ...this.formPasswordControl,
          senhaAntiga: this.formPasswordControl.senhaAntiga.value,
          senhaNova: this.formPasswordControl.senhaNova.value
        })
        .subscribe(
          resp => {
            this.formPasswordControl.senhaNova = null;
            this.formPasswordControl.senhaAntiga = null;

            if (resp.hasError) {
              this.toastrService.warning(resp.errors[0], "Atenção");
            } else {
              this.toastrService.success(
                "Alteração salva com sucesso!",
                "Sucesso"
              );
            }
          },
          error => {
            console.log(error);
          }
        );
    } else {
      this.toastrService.warning(
        "Por favor, preencha todos os campos",
        "Atenção"
      );
    }

    return false;
  }
}
