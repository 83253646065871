import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse
} from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, throwError, Subscription, Subject } from "rxjs";
import { catchError, tap, switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { AlertService } from "./alert.service";
import Response from "../models/ResponseGET";

@Injectable()
export class Interceptor implements HttpInterceptor, OnDestroy {
  isRefreshingToken = false;
  subscriptions = new Subscription();
  token: string;

  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(private authService: AuthService, private alert: AlertService) {
    this.getTokenState();
  }

  getTokenState() {
    this.subscriptions.add(
      this.authService.getTokenState.subscribe(token => {
        this.token = token;
      })
    );
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const request = this.returnRequestWithAuthHeader(req);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleResponseError(error, request, next);
      })
    );
  }

  handleResponseError(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    const { status } = error;
    if (status === 401) {
      return this.handleResponse401(request, next);
    } else if (status === 403) {
      return this.handleResponse403(error);
    }
    this.handleErrorsWithGenericResponse(error);
    return throwError(error);
  }

  returnRequestWithAuthHeader(request: HttpRequest<any>) {
    const copyOfRequest = request.clone({
      headers: request.headers.set("Authorization", `Bearer ${this.token}`)
    });

    return copyOfRequest;
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.authService.refreshToken().pipe(
        tap(response => {
          this.authService.token.next(response?.value?.token?.accessToken);
          sessionStorage.setItem(
            "access-token",
            response?.value?.token?.accessToken
          );
          localStorage.setItem(
            "refresh-token",
            response?.value?.token?.refreshToken
          );
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        }),
        catchError(err => {
          this.refreshTokenInProgress = false;
          this.authService.logout();
          return throwError(
            "Não foi possível realizar o refresh do token do usuário"
          );
        })
      );
    }
  }

  handleResponse(response: any) {
    if (response && response instanceof HttpResponse) {
      const status = response.status;
      const body = response.body as Response;
      if (status === 200 && body?.errors?.length) {
        this.handleResponse200WithError(response.body);
      }
    }
  }

  handleResponse200WithError(body: Response) {
    this.alert.error(body.errors[0]);
  }

  handleResponse401(request: HttpRequest<any>, next: HttpHandler) {
    return this.refreshToken().pipe(
      switchMap(() => {
        request = this.returnRequestWithAuthHeader(request);
        return next.handle(request);
      }),
      catchError((anotherError: HttpErrorResponse) => {
        return this.handleResponseError(anotherError, request, next);
      })
    );
  }

  handleUserWithInvalidToken() {
    this.authService.logout();
    return throwError("Token e RefreshToken Expirados");
  }

  handleResponse403(error: HttpErrorResponse) {
    this.handleErrorsWithGenericResponse(error);
    return throwError("Sem permissão");
  }

  handleErrorsWithGenericResponse(error: HttpErrorResponse | string) {
    if (error instanceof HttpErrorResponse) {
      const arrayOfErrors = error.error?.errors;
      if (arrayOfErrors) {
        if (Array.isArray(arrayOfErrors) && arrayOfErrors.length) {
          if (
            error.status === 400 &&
            arrayOfErrors.includes(
              "Sua sessão expirou. Você será redirecionado para o login."
            )
          ) {
            this.handleUserWithInvalidToken();
          }
          arrayOfErrors.forEach(problemMessage =>
            this.alert.error(problemMessage)
          );
        } else if (Object.getOwnPropertyNames(arrayOfErrors).length > 0) {
          Object.getOwnPropertyNames(arrayOfErrors).forEach(propOfError =>
            this.alert.error(arrayOfErrors[propOfError])
          );
        }
      } else {
        const { status } = error;
        if (status === 403) {
          this.alert.error(
            "Você não tem permissão para executar essa ação!",
            "Atenção!!!"
          );
        } else {
          this.alert.error(
            typeof error.error === typeof ""
              ? error.error
              : "Verifique sua conexão e tente novamente.",
            "Ocorreu um erro sem tratamento"
          );
        }
      }
    } else {
      console.error(error);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
