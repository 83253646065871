import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "src/config/app.config";

interface SavePermissionBody {
  permissaoId: string;
  grupoId: string;
}

@Injectable({
  providedIn: "root"
})
export class CategoryService {
  private BASE_URL = `${AppConfig.settings.urlApi}`;
  constructor(private http: HttpClient) {}

  getCategories() {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/ParametroCategoria`
    );
  }

  deleteCategory(id: string) {
    return this.http.delete<any>(`${AppConfig.settings.urlApi}/ParametroCategoria/${id}`);
  }

  saveCategory(params) {
    return this.http.post<any>(`${AppConfig.settings.urlApi}/ParametroCategoria`, params);
  }

  updateCategory(id, params) {
    return this.http.put<any>(`${AppConfig.settings.urlApi}/ParametroCategoria/${id}`, params);
  }

  fetch(companyId: string) {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/Grupo?EmpresaId=${companyId}`
    );
  }
}
