import { ConstantPool } from "@angular/compiler";
import { Component, Input, OnInit } from "@angular/core";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { Subscription } from "rxjs";
import { DecisionAlertModalComponent } from "src/app/components/decision-alert/decision-alert-modal.component";
import { HelperService } from "src/app/services/helper.service";
import { WebserviceService } from "src/app/services/webservice.service";
import { EstacaoService } from "src/app/services/estacao.service";
import { NewParametroModalComponent } from "../new-parametro-modal/new-parametro-modal.component";
import { CloneParamsModalComponent } from "../clone-params-modal/clone-params-modal.component";
import { ViewStringBrutaComponent } from "../view-string-bruta/view-string-bruta.component";
import { LinkService } from "src/app/services/link.service";
@Component({
  selector: "app-configurar-param",
  templateUrl: "./configurar-param.component.html",
  styleUrls: ["./configurar-param.component.scss"]
})
export class ConfigurarParamComponent implements OnInit {
  @Input() editable: boolean = true;
  disabledAntena: boolean = true;
  mostrarParametros = false;
  parametrosList: any = [];
  webservicesList: any = [];
  antenasList: any = [];
  subscriptions = new Subscription();
  webserviceLinkId: any;
  webserviceId: any;
  antenaId: string;
  antenaOpt: any;
  separador: string;
  stringBruta: string;

  constructor(
    private WebService: WebserviceService,
    private EstacaoService: EstacaoService,
    private nbDialogService: NbDialogService,
    private helperService: HelperService,
    private nbToastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.fetchListWebservices();
  }

  save() {
    let arr = [];
    this.parametrosList.forEach((param: any) => {
      let obj = {
        id: param.id,
        parametro: param.parametro,
        antena: this.antenaId,
        unidadeMedida: param.unidadeMedida,
        nomeParametro: param.parametroNome,
        categoria: param.categoria,
        numeroCasasDecimais: param.numeroCasasDecimais,
        ordem: param.ordem
      };
      arr.push(obj);
    });

    this.subscriptions.add(
      this.WebService.updateParametro(arr).subscribe(response => {
        this.nbToastrService.success("Registro salvo com sucesso", "Sucesso", {
          duration: 2000
        });
        this.fetchListWebservices();
      })
    );
  }

  fieldChange(e: any, idItem: string) {
    this.parametrosList.map((param: any) => {
      if (param.id === idItem) {
        param[e.target.name] = e.target.value;
      }
    });
  }

  fetchListWebservices() {
    this.WebService.fetch().subscribe(x => {
      this.webservicesList = x.value;
    });
  }

  onWebServiceChange(event) {
    this.webserviceId = event;
    this.antenasList = [];
    this.antenaOpt = "";

    this.EstacaoService.getAntenaByWebService(this.webserviceId).subscribe(
      data => {
        this.antenasList = data.value.antenas;
        this.disabledAntena = false;
      }
    );
  }

  onAntenaChange(event) {
    this.mostrarParametros = false;
    this.antenaId = event;

    this.WebService.getParametrosByAntenaIdWebserviceId(
      this.webserviceId,
      this.antenaId
    ).subscribe(data => {
      this.separador = data.value.separador;
      this.stringBruta = data.value.stringBruta;
      if (data.value.parametrosNameResponse.length > 1) {
        this.parametrosList = data.value.parametrosNameResponse.sort((a, b) => {
          parseFloat(a.ordem) - parseFloat(b.ordem);
          this.mostrarParametros = true;
        });
      } else {
        this.parametrosList = data.value.parametrosNameResponse;
        this.mostrarParametros = true;
      }
    });
  }

  new() {
    this.nbDialogService
      .open(NewParametroModalComponent, {
        autoFocus: false,
        context: {
          outsideData: {
            webserviceId: this.webserviceId,
            antenaId: this.antenaId,
            ordem: this.parametrosList.length + 1
          }
        }
      })
      .onClose.subscribe(() => this.onAntenaChange(this.antenaId));
  }

  clone() {
    this.nbDialogService
      .open(CloneParamsModalComponent, {
        autoFocus: false,
        context: {
          outsideData: {
            webserviceId: this.webserviceId,
            antenaId: this.antenaId
          }
        }
      })
      .onClose.subscribe(() => this.onAntenaChange(this.antenaId));
  }

  viewStringBruta() {
    this.nbDialogService
      .open(ViewStringBrutaComponent, {
        autoFocus: false,
        context: {
          outsideData: { stringBruta: this.stringBruta }
        }
      })
      .onClose.subscribe(() => this.onAntenaChange(this.antenaId));
  }

  deleteEvent(item: any) {
    const { id } = item;
    this.subscriptions.add(
      this.nbDialogService
        .open(DecisionAlertModalComponent, { autoFocus: false })
        .onClose.subscribe((decision: "cancel" | "remove") => {
          if (decision === "remove") {
            this.subscriptions.add(
              this.WebService.deleteParametro(id).subscribe(() => {
                this.nbToastrService.success(
                  "Remoção realizada com sucesso.",
                  "Sucesso"
                );
                this.onAntenaChange(this.antenaId);
              })
            );
          }
        })
    );
    this.helperService.closeModal(true);
  }

  editEvent(item) {
    item.webserviceId = this.webserviceId;
    item.antenaId = this.antenaId;
    item.title = "Editar Parâmetro";
    this.nbDialogService
      .open(NewParametroModalComponent, {
        autoFocus: false,
        context: { outsideData: item }
      })
      .onClose.subscribe(() => this.onAntenaChange(this.antenaId));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
