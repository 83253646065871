import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import FilterMotivoDevolucao from "../models/FilterMotivoDevolucao";
import { AppConfig } from "src/config/app.config";

@Injectable({
  providedIn: "root"
})
export class MotivoDevolucaoService {
  id: any;

  private BASE_URL = `${environment.BASE_URL}`;
  constructor(private http: HttpClient) {}

  fetch(page: number, rowsPerPage: number) {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/MotivoDevolucao?take=${rowsPerPage}&page=${page}&sortingProp=descricao`
    );
  }

  save(params) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/MotivoDevolucao`,
      params
    );
  }

  update(id, params) {
    return this.http.put<any>(
      `${AppConfig.settings.urlApi}/MotivoDevolucao/${id}`,
      params
    );
  }

  search(
    filterMotivoDevolucao: FilterMotivoDevolucao,
    page: number,
    rowsPerPage: number
  ) {
    return this.http.get(`${AppConfig.settings.urlApi}/MotivoDevolucao`, {
      params: {
        descricao: `${filterMotivoDevolucao.descricao}`,
        ativo: `${filterMotivoDevolucao.ativo}`,
        take: rowsPerPage?.toString(),
        page: page?.toString()
      }
    });
  }

  ativarDesativar(id, params) {
    return this.http.put<any>(
      `${AppConfig.settings.urlApi}/MotivoDevolucao/ativarDesativar/${id}`,
      params
    );
  }

  delete(id, params) {
    return this.http.put<any>(
      `${AppConfig.settings.urlApi}/MotivoDevolucao/deletar/${id}`,
      params
    );
  }
}
