import { Component, OnInit } from "@angular/core";
import { TableBase } from "src/app/models/PagesComponents";
import FilterEstacao from "src/app/models/FilterEstacao";
import { CurrentUserService } from "src/app/services/current-user.service";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import {
  DecisionAlertModalComponent,
  TipoMensagem
} from "src/app/components/decision-alert/decision-alert-modal.component";

import { ColumnObject, DataTableActions } from "src/app/models/DataTable";

import { Subscription } from "rxjs";

import { HelperService } from "src/app/services/helper.service";
import { WebserviceService } from "src/app/services/webservice.service";
import { NewEditLinkModalComponent } from "../new-edit-link-modal/new-edit-link-modal.component";
import { LinkService } from "src/app/services/link.service";

@Component({
  selector: "app-link",
  templateUrl: "./link.component.html",
  styleUrls: ["./link.component.scss"]
})
export class LinkComponent implements OnInit, TableBase {
  selectedPage: number = 1;
  rowsPerPage: number = 10;
  totalRecords: number;

  companyId: string;
  isLoaded = false;
  permissions = this.uService.getEnumPermissions();
  subscriptions = new Subscription();
  data = [] as any[];

  columns: ColumnObject = {
    webService: {
      title: "WebService",
      sort: true,
      sortDirection: "asc"
    },
    url: {
      title: "Link",
      sort: true,
      sortDirection: "asc"
    }
  };

  constructor(
    public uService: CurrentUserService,
    private nbToastrService: NbToastrService,
    private linkService: LinkService,
    private helperService: HelperService,
    private nbDialogService: NbDialogService,
  ) {}

  includeActionsColumnOnData(item: any) {
    const buttons = this.uService.getActionsTableAllowed(
      this.permissions.AtualizarEstacao,
      null,
      this.permissions.DeletarEstacao,
      null,
      null,
      null,
      null,
      this.permissions.ConsultarEstacao
    );

    const dataWithActions = {
      ...item,
      actions: {
        buttons,
        viewEvent: () => this.view(item),
        editEvent: () => this.edit(item),
        deleteEvent: () => this.delete(item),
      } as DataTableActions
    };

    return dataWithActions;
  }

  new() {
    this.nbDialogService.open(NewEditLinkModalComponent, {
      autoFocus: false,
      context: { outsideData: false }
    });
  }

  view(rowData: any) {
    this.nbDialogService.open(NewEditLinkModalComponent, {
      autoFocus: false,
      context: { outsideData: rowData, editable: false }
    });
  }

  edit(rowData: any) {
    this.nbDialogService.open(NewEditLinkModalComponent, {
      autoFocus: false,
      context: { outsideData: rowData, editable: true }
    });
  }

  delete(rowData: any) {
    const { id } = rowData;
    this.subscriptions.add(
      this.nbDialogService.open(DecisionAlertModalComponent, { autoFocus: false }).onClose.subscribe((decision: "cancel" | "remove") => {
        if (decision === "remove") {
          this.subscriptions.add(
            this.linkService.deleteLink(id).subscribe(() => {
              this.nbToastrService.success("Link removido com sucesso.", "Sucesso");
              this.fetch();
            })
          );
        }
      })
    );

    this.helperService.closeModal(true);
  }

  changePage(selectedPage: number) {
    this.selectedPage = selectedPage || this.selectedPage;
    this.fetch();
  }

  changeRowsPerPage(selectedRowsPerPage: number) {
    this.rowsPerPage = selectedRowsPerPage || this.rowsPerPage;
    this.fetch();
  }

  fetch(): any {
    this.linkService.fetch().subscribe(results => {
      this.data = results.value.map(item =>
        this.includeActionsColumnOnData(item)
      );
      this.isLoaded = true;
    });
  }

  ngOnInit() {
    this.createRefreshDataWatcher();
  }

  createRefreshDataWatcher() {
    this.subscriptions.add(
      this.helperService.isModalToClose.subscribe(() => {
        this.refreshData(this.companyId);
      })
    );
  }

  refreshData(outsideCompanyId?: string) {
    if (this.companyId || outsideCompanyId) {
      this.fetch();
    }
    this.companyId = this.companyId || outsideCompanyId;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
