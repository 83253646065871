import { UserService } from "src/app/services/user.service";
import { Util } from "./../../helpers/util";
import { Component, OnInit, DebugElement } from "@angular/core";
import {
  NbDialogRef,
  NbToastrService,
  NbTabComponent,
  NbDialogService
} from "@nebular/theme";
import { AlertService } from "src/app/services/alert.service";
import MeusDados from "src/app/models/MeusDados";
import { ModalProfilePassWordComponent } from "./modal-profile-pass-word/modal-profile-pass-word.component";
import GetUser from "src/app/models/User";

@Component({
  selector: "app-modal-profile",
  templateUrl: "./modal-profile.component.html",
  styleUrls: ["./modal-profile.component.scss"]
})
export class ModalProfileComponent implements OnInit {
  isLoading: boolean;
  showEndereco: boolean = true;
  selectedTab: string = "Dados pessoais";

  formPassword = {
    userName: null,
    senhaAntiga: null,
    senhaNova: null
  };

  data: any = {
    id: "",
    nome: "",
    telefone: "",
    email: "",
    login: "",
    cpf: "",
    grupo: ""
  };

  dadosUsuario: any = null;

  showCurrentPassword = false;
  showNewPassword = false;

  enableEmail = false;
  enableTelefone = false;

  clickPushed = false;

  constructor(
    private nbDialogRef: NbDialogRef<ModalProfileComponent>,
    private util: Util,
    private toastrService: NbToastrService,
    private userService: UserService,
    private alert: AlertService,
    private dialogService: NbDialogService
  ) {}

  ngOnInit() {
    const userdata = this.util.getDataUserToLocalStorage();
    const { login, nome, id, email, telefone, cpf } = userdata;
    this.data = {
      id: id,
      nome: nome,
      telefone: telefone,
      email: email,
      login: login,
      cpf: cpf
    };

    this.userService.fetchUserById(this.data.id).subscribe(response => {
      const resp = response.value;
      this.data.cpf = response.hasSuccess ? resp.cpf : this.data.cpf;
      this.data.login = response.hasSuccess ? resp.login : this.data.login;
      this.data.grupo = response.hasSuccess
        ? resp.grupoAspNetUsers.map(x => x.grupo.nome).join(",")
        : "";
    });
  }

  changePassword() {
    this.dialogService.open(ModalProfilePassWordComponent);
  }

  tabChanged(currentTab: NbTabComponent) {
    this.selectedTab = currentTab.tabTitle;
  }

  close() {
    this.nbDialogRef.close();
  }

  toggleShowPassword(inputType: "currentPassword" | "newPassword") {
    if (inputType === "currentPassword") {
      this.showCurrentPassword = !this.showCurrentPassword;
    } else {
      this.showNewPassword = !this.showNewPassword;
    }
  }

  save(form) {
    this.clickPushed = true;
    if (!form.valid) return;

    switch (this.selectedTab) {
      case "Dados pessoais": {
        this.putOrPostUser();
        break;
      }
      case "Alterar Senha": {
        if (this.formPassword.senhaAntiga && this.formPassword.senhaNova) {
          this.formPassword.userName = this.data.login;

          this.userService.changePassword(this.formPassword).subscribe(
            resp => {
              this.formPassword.senhaNova = null;
              this.formPassword.senhaAntiga = null;

              if (resp.hasError) {
                this.toastrService.warning(resp.errors[0], "Atenção");
              } else {
                this.toastrService.success("Sua senha foi alterada", "Sucesso");
              }
            },
            error => {
              console.log(error);
            }
          );
        } else {
          this.toastrService.warning(
            "Por favor, preencha todos os campos",
            "Atenção"
          );
        }
        break;
      }
    }

    return false;
  }

  validarCampos() {
    if (
      this.data.telefone &&
      this.data.telefone.length < 11 &&
      this.data.telefone.length > 0
    ) {
      this.alert.error("O formato do telefone celular está incorreto.");
      return;
    }

    let regex = new RegExp(
      /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/i
    );

    if (this.data.email && !regex.test(this.data.email)) {
      this.alert.error("O formato do e-mail está incorreto.");
      return;
    }

    return true;
  }

  putOrPostUser() {
    this.isLoading = true;
    try {
      if (this.validarCampos()) {
        const dados = localStorage.getItem("person");
        let person = dados ? JSON.parse(atob(dados)) : null;

        let userdata = person.value; //this.util.getDataUserToLocalStorage();

        const formData = new FormData();
        formData.append("nome", this.data.nome);
        formData.append("telefone", this.data.telefone);
        formData.append("login", this.data.login);
        formData.append("empresaId", userdata.empresa.id);
        formData.append("tipoUsuario", userdata.tipoUsuario);
        formData.append("email", this.data.email);
        formData.append("cpf", this.data.cpf);

        userdata = {
          id: this.data.id,
          nome: this.data.nome,
          telefone: this.data.telefone,
          login: this.data.login,
          email: this.data.email,
          cpf: this.data.cpf
        };

        this.userService
          .updateUser(userdata, formData)
          .subscribe((response: Response) => {
            this.toastrService.success(
              "Perfil atualizado com sucesso",
              "Sucesso",
              {
                duration: 2000
              }
            );

            localStorage.setItem("person", btoa(JSON.stringify(response)));

            // window.location.reload();
            this.close();
          });
      }

      return false;
    } finally {
      this.isLoading = false;
    }
  }
}
