import { Component, OnInit } from "@angular/core";
import { NbDialogRef, NbToastrService } from "@nebular/theme";
import { EstacaoService } from "src/app/services/estacao.service";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-configure-min-max",
  templateUrl: "./configure-min-max.component.html",
  styleUrls: ["./configure-min-max.component.scss"]
})
export class ConfigureMinMaxComponent implements OnInit {
  constructor(
    private nbDialogRef: NbDialogRef<ConfigureMinMaxComponent>,
    private helperService: HelperService,
    private clienteService: EstacaoService,
    private nbToastrService: NbToastrService
  ) {}

  ngOnInit() {}

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }
}
