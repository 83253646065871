import { HttpClient } from "@angular/common/http";
import { forkJoin } from "rxjs";
import { LoginUser } from "../models/LoginUser";
import { Injectable } from "@angular/core";
import { UserService } from "./user.service";
import { AuthService } from "./auth.service";
import { Permissions } from "../models/Permissions";
import { take } from "rxjs/operators";
@Injectable({
  providedIn: "root"
})
export class CurrentUserService {
  id: any;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private authService: AuthService
  ) {
    const { id } = this.authService.decode();
    this.id = id;
  }

  public async getUser(): Promise<LoginUser> {
    const { id } = this.authService.decode();

    //Chamar
    const res = await forkJoin(
      this.userService.fetchUserById(id),
      this.userService.fetchPermissions(id),
      this.userService.fetchUsuarioCliente(id)
    )
      .pipe(take(1))
      .toPromise();

    const user = res[0];
    const userPermissions = res[1].value;
    const userClientes = res[2].value;
    const tipoUsuario = user.value.tipoUsuario; /// 0.admin  |  1.cliente

    let jsonClients = "";

    if (tipoUsuario === 1 && userClientes.length > 0) {
      jsonClients = btoa(JSON.stringify(userClientes[0].cliente));
    }

    //Salvar os dados no localstorage
    localStorage.setItem("user-clients", jsonClients);
    localStorage.setItem("person", btoa(JSON.stringify(user)));
    localStorage.setItem( "user-permissions", btoa(JSON.stringify(userPermissions)));

    return user.value;
  }

  public getEnumPermissions() {
    return Permissions;
  }

  public getActionsTableAllowed(
    editPermission?: Permissions,
    activeDisablePermission?: Permissions,
    removePermission?: Permissions,
    downloadPermission?: Permissions,
    resetPermission?: Permissions,
    unlockPermission?: Permissions,
    showMapaPermission?: Permissions,
    viewPermission?: Permissions,
    configurePermission?: Permissions
  ): Array<
    | "edit"
    | "delete"
    | "download"
    | "active-disable"
    | "reset"
    | "unlock"
    | "showMapa"
    | "view"
    | "configure"
  > {
    const actions = [] as Array<
      | "edit"
      | "delete"
      | "download"
      | "active-disable"
      | "reset"
      | "unlock"
      | "showMapa"
      | "view"
      | "configure"
    >;
    const canEdit = editPermission && this.validatePermissions(editPermission);
    const canActiveDisable =
      activeDisablePermission &&
      this.validatePermissions(activeDisablePermission);
    const canDelete =
      removePermission && this.validatePermissions(removePermission);
    const canDownload =
      downloadPermission && this.validatePermissions(downloadPermission);
    const canReset =
      resetPermission && this.validatePermissions(resetPermission);
    const canUnlock =
      unlockPermission && this.validatePermissions(unlockPermission);
    const canShowMapa =
      showMapaPermission && this.validatePermissions(showMapaPermission);
    const canView = viewPermission && this.validatePermissions(viewPermission);
    const canConfigure =
      configurePermission && this.validatePermissions(configurePermission);

    if (canEdit) {
      actions.push("edit");
    }
    if (canActiveDisable) {
      actions.push("active-disable");
    }
    if (canDelete) {
      actions.push("delete");
    }
    if (canDownload) {
      actions.push("download");
    }
    if (canReset) {
      actions.push("reset");
    }
    if (canUnlock) {
      actions.push("unlock");
    }
    if (canShowMapa) {
      actions.push("showMapa");
    }
    if (canView) {
      actions.push("view");
    }
    if (canConfigure) {
      actions.push("configure");
    }

    return actions;
  }

  public getPermissionsFromStorage(): any {
    const userPermissions = localStorage.getItem("user-permissions");
    if (userPermissions) {
      const permissions = JSON.parse(
        atob(localStorage.getItem("user-permissions"))
      );
      return permissions;
    }
  }

  public validatePermissions(Userpermission: Permissions): boolean {
    const userPermissions = localStorage.getItem("user-permissions");
    if (userPermissions) {
      const permissions = JSON.parse(
        atob(localStorage.getItem("user-permissions"))
      );
      if (permissions.find(e => e.nome === Userpermission)) {
        return true;
      } else {
        return false;
      }
    }
  }

  public getUserType(): string {
    let person = localStorage.getItem("person");
    const ret = person ? JSON.parse(atob(person)) : null;
    return ret ? ret.value.tipoUsuario : null;
  }
}
