import { Component, OnInit } from "@angular/core";
import { Input, AfterContentChecked, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { NbDialogRef,  NbToastrService } from "@nebular/theme";
import { HelperService } from "src/app/services/helper.service";
import { WebserviceService } from "src/app/services/webservice.service";
import { LinkService } from "src/app/services/link.service";
@Component({
  selector: "app-new-edit-link-modal",
  templateUrl: "./new-edit-link-modal.component.html",
  styleUrls: ["./new-edit-link-modal.component.scss"]
})
export class NewEditLinkModalComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() outsideData: any;
  @Input() editable: boolean = true;

  isLoading: boolean;
  webservicesList: any = [];
  subscriptions = new Subscription();

  data: any = {
    id: "",
    webServiceId: "",
    url: "",
  };

  constructor(
    private nbDialogRef: NbDialogRef<NewEditLinkModalComponent>,
    private helperService: HelperService,
    private webservice: WebserviceService,
    private linkService: LinkService,
    private nbToastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.fetchListWebservices();
    this.isLoading = this.outsideData && Object.keys(this.outsideData).length !== 0 ? true : false;
  }

  fetchListWebservices() {
    this.webservice.fetch().subscribe(x => {
      this.webservicesList = x.value;
    });
  }

  save() {
    if (this.data.id) {
      this.subscriptions.add(
        this.linkService.updateLink(this.data).subscribe(response => {
          this.nbToastrService.success("Link atualizado com sucesso", "Sucesso", {
            duration: 2000
          });
          this.close();
        })
      );
    } else {
      let obj = {
        webServiceId: this.data.webServiceId,
        url: this.data.url,
      }
      this.subscriptions.add(
        this.linkService.newLink(obj).subscribe(response => {
          this.nbToastrService.success("Link salvo com sucesso", "Sucesso", {
            duration: 2000
          });
          this.close();
        })
      );
    }
  }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  ngAfterContentChecked() {
    this.setValuesOnFields();
  }

  setValuesOnFields() {
    if (this.outsideData && Object.keys(this.outsideData).length !== 0 && this.isLoading) {
      this.data = {
        id: this.outsideData.id,
        webServiceId: this.outsideData.webServiceId,
        url: this.outsideData.url,
      };
      this.isLoading = false;
    }
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
