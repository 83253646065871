import {
  Component,
  OnInit,
  Input,
  NgZone,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { CompanyService } from "src/app/services/company.service";
import { TermService } from "src/app/services/term.service";
import { NbToastrService, NbDialogRef, NbTabComponent } from "@nebular/theme";
import { HelperService } from "src/app/services/helper.service";
import IResponseGET from "src/app/models/ResponseGET";
import IPostPutTerm from "src/app/models/PostPutTerm";
import ICompany from "src/app/models/Company";
import GetTerm from "src/app/models/GetTerm";
import { AuthService } from "src/app/services/auth.service";
import { forkJoin, Subscription, of, Subject, BehaviorSubject } from "rxjs";
import { AlertService } from "src/app/services/alert.service";
import { RichtextComponent } from "../../../../components/richtext/richtext.component";

import Quill from "quill";
import * as dayjs from "dayjs";
import { ClienteService } from "src/app/services/cliente.service";
import GetCliente from "src/app/models/Cliente";
import { QuillModules } from "ngx-quill";

const parchment = Quill.import("parchment");
const block = parchment.query("block");
block.tagName = "DIV";
// or class NewBlock extends Block {} NewBlock.tagName = 'DIV'
Quill.register(block /* or NewBlock */, true);

@Component({
  selector: "app-new-edit-terms-modal",
  templateUrl: "./new-edit-terms-modal.component.html",
  styleUrls: ["./new-edit-terms-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewEditTermsModalComponent implements OnInit, OnDestroy {
  @Input() outsideData: GetTerm;
  @ViewChild(RichtextComponent) richEditor: RichtextComponent;

  data: IPostPutTerm = {
    id: "",
    nome: "",
    mensagem: ""
  };

  companies = [] as GetCliente[];
  companyId: string;
  subscriptions = new Subscription();
  isJustOneCompany: boolean = this.authService.getIsJustOneCompany();
  isLoading: boolean;

  editor_modules: QuillModules = {
    imageResize: true
  };

  constructor(
    private companyService: CompanyService,
    private termService: TermService,
    private nbToastrService: NbToastrService,
    private helperService: HelperService,
    private nbDialogRef: NbDialogRef<NewEditTermsModalComponent>,
    private clientService: ClienteService,
    private authService: AuthService, // private alert: AlertService,
    private changeDetection: ChangeDetectorRef
  ) {}

  testeSubject: Subject<any>;
  testeVal: any;

  ngOnInit() {
    this.isLoading = this.outsideData ? true : false;

    this.testeSubject = new BehaviorSubject("Iniciando Variavel");

    this.testeSubject.subscribe(x => {
      console.log(x);
      this.testeVal = x;
    });

    return this.testeSubject.asObservable();
  }

  ngAfterContentChecked() {
    this.setValuesOnFields();
  }

  ngAfterViewInit() {
    //this.richEditor.content = this.data.mensagem;
    //this.data.mensagem
    this.changeDetection.detectChanges();
  }

  setValuesOnFields() {
    if (this.outsideData && this.isLoading) {
      this.data = {
        id: this.outsideData.id,
        mensagem: this.outsideData.mensagem,
        nome: this.outsideData.nome
      };
      this.isLoading = false;
    }
  }

  save() {
    this.isLoading = true;
    try {
      if (this.data.id) {
        this.subscriptions.add(
          this.termService
            .updateTerm(this.data)
            .subscribe((response: IResponseGET) => {
              this.nbToastrService.success(
                "Registro salvo com sucesso",
                "Sucesso",
                {
                  duration: 2000
                }
              );
              this.close();
            })
        );
      } else {
        this.subscriptions.add(
          this.termService
            .save(this.data)
            .subscribe((response: IResponseGET) => {
              const savedTerm = response.value as GetTerm;
              this.nbToastrService.success(
                "Registro salvo com sucesso",
                "Sucesso",
                {
                  duration: 2000
                }
              );
              this.close();
            })
        );
      }
    } finally {
      this.isLoading = false;
    }
  }

  validate(value: string): boolean {
    let regex = new RegExp(
      "([0-9]{4}[-|/](0[1-9]|1[0-2])[-|/]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[-|/](0[1-9]|1[0-2])[-|/][0-9]{4})"
    );
    return regex.test(value);
  }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
