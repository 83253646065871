import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from "@angular/core";
import { NbDialogRef, NbToastrService } from "@nebular/theme";
import { Subscription } from "rxjs";
import { tipoParametro } from "src/app/enum/TipoParametro";
import { CategoryService } from "src/app/services/category.service";
import { HelperService } from "src/app/services/helper.service";
import { WebserviceService } from "src/app/services/webservice.service";

@Component({
  selector: "app-new-parametro-modal",
  templateUrl: "./new-parametro-modal.component.html",
  styleUrls: ["./new-parametro-modal.component.scss"]
})
export class NewParametroModalComponent
  implements OnInit, AfterContentChecked, OnDestroy {
  @Input() outsideData: any;
  errors: any = [];
  paramsList: any = [];
  categoriasList: any = [];
  tipoParametroList: any = Object.values(tipoParametro).filter(item => {
    return isNaN(Number(item));
  });
  isLoading: boolean = true;
  disableParam: boolean = false;
  modalTitle: string = "Adicionar Parâmetro";
  subscriptions = new Subscription();
  data: {
    id: number;
    parametro: string;
    antena: string;
    webserviceId: number;
    unidadeMedida: string;
    nomeParametro: string;
    categoria: string;
    numeroCasasDecimais: string;
    ordem: number;
    tipoParametro: tipoParametro;
  };

  //validators
  hasErrorParametro: boolean = false;
  hasErrorNomeExibicao: boolean = false;
  hasErrorUnidadeMedida: boolean = false;
  hasErrorCasasDecimais: boolean = false;
  hasErrorOrder: boolean = false;

  constructor(
    private helperService: HelperService,
    private webService: WebserviceService,
    private categoryService: CategoryService,
    private nbDialogRef: NbDialogRef<NewParametroModalComponent>,
    private nbToastrService: NbToastrService,
    private changeDetection: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isLoading = this.outsideData ? true : false;
    this.getCategories();
  }

  getCategories() {
    this.subscriptions.add(
      this.categoryService.getCategories().subscribe(response => {
        this.categoriasList = response.value;
      })
    );
  }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  save() {
    !this.data.parametro
      ? (this.hasErrorParametro = true)
      : (this.hasErrorParametro = false);
    !this.data.nomeParametro
      ? (this.hasErrorNomeExibicao = true)
      : (this.hasErrorNomeExibicao = false);
    !this.data.unidadeMedida
      ? (this.hasErrorUnidadeMedida = true)
      : (this.hasErrorUnidadeMedida = false);
    !this.data.numeroCasasDecimais
      ? (this.hasErrorCasasDecimais = true)
      : (this.hasErrorCasasDecimais = false);
    !this.data.ordem
      ? (this.hasErrorOrder = true)
      : (this.hasErrorOrder = false);

    if (
      this.hasErrorParametro ||
      this.hasErrorNomeExibicao ||
      this.hasErrorUnidadeMedida ||
      this.hasErrorCasasDecimais ||
      this.hasErrorOrder
    ) {
      return;
    }

    this.isLoading = true;
    try {
      if (this.disableParam) {
        this.subscriptions.add(
          this.webService.updateParametro([this.data]).subscribe(response => {
            this.nbToastrService.success(
              "Registro salvo com sucesso",
              "Sucesso",
              { duration: 2000 }
            );
            this.close();
          })
        );
      } else {
        this.subscriptions.add(
          this.webService.novoParametro(this.data).subscribe(response => {
            this.nbToastrService.success(
              "Registro salvo com sucesso",
              "Sucesso",
              { duration: 2000 }
            );
            this.close();
          })
        );
      }
    } finally {
      this.isLoading = false;
    }
  }

  ngAfterContentChecked() {
    this.setValuesOnFields();
  }

  ngAfterViewInit() {
    this.changeDetection.detectChanges();
  }

  setValuesOnFields() {
    if (this.outsideData && this.isLoading) {
      this.disableParam = this.outsideData.id ? true : false;
      this.modalTitle = this.outsideData.title ?? "Adicionar Parâmetro";

      this.data = {
        id: this.outsideData.id ?? null,
        parametro: this.outsideData.parametro ?? "",
        antena: this.outsideData.antenaId ?? "",
        webserviceId: this.outsideData.webserviceId,
        unidadeMedida: this.outsideData.unidadeMedida ?? "",
        nomeParametro: this.outsideData.parametroNome ?? "",
        categoria: this.outsideData.categoria ?? "",
        numeroCasasDecimais: this.outsideData.numeroCasasDecimais ?? "",
        ordem: this.outsideData.ordem ?? null,
        tipoParametro: this.outsideData.tipoParametroNome ?? null
      };

      this.isLoading = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
