import { NgModule, OnDestroy } from "@angular/core";
import {
  RouterModule,
  Route,
  Router,
  NavigationStart,
  NavigationEnd
} from "@angular/router";
import { AuthGuard } from "./guards/auth-guard.service";
import { RouteSearchable } from "./models/Route";
import { Subscription } from "rxjs";
import { AuthService } from "./services/auth.service";
import { NbDialogService } from "@nebular/theme";
import { TermOfUseComponente } from "./components/term-of-use/term-of-use.component";
import { ModalFirstAccessComponent } from "./components/modal-first-access/modal-first-access.component";

export const RouteList: RouteSearchable[] = [
  { name: "", path: "", redirectTo: "login", pathMatch: "full" },
  {
    name: "login",
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then(m => m.LoginModule)
  },
  {
    path: "home",
    title: "Home Page",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/landing-page/home/home.module").then(m => m.HomeModule)
  },

  {
    path: "clientes",
    title: "Clientes",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/cliente/consult-cliente.module").then(m => m.ClienteModule)
  },

  // {
  //   path: "antenas",
  //   title: "Antenas",
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import("./pages/antena/consult-antenas.module").then(
  //       m => m.ConsultAntenasModule
  //     )
  // },

  //{
  //  path: 'boias',
  //  title: 'Bóias',
  //  loadChildren: () =>
  //    import('./pages/boia/consult-boia.module').then((m) => m.ConsultBoiaModule),
  //},

  {
    name: "consult-user",
    title: "Usuário",
    path: "security/user",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/security/user/consult-user.module").then(m => m.ConsultUserModule)
  },
  {
    name: "consult-group",
    title: "Grupo",
    path: "security/group",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/security/group/consult-group.module").then(m => m.ConsultGroupModule)
  },
  {
    name: "group-permission",
    title: "Permissões de Grupo",
    path: "security/group-permission",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/security/group-permission/group-permission.module").then(m => m.GroupPermissionModule)
  },
  {
    path: "dashboard",
    title: "Home",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/dashboard/dashboard.module").then(m => m.DashboardModule)
  },
  {
    path: "estacao",
    title: "Estação",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/estacao/consult-estacao.module").then(m => m.EstacaoModule)
  },
  {
    path: "security/termo-uso",
    title: "Termos de Uso",
    loadChildren: () =>
      import("./pages/settings/terms-of-use/consult-terms.module").then(m => m.ConsultTermsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(RouteList, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule implements OnDestroy {
  subscriptions = new Subscription();
  constructor(
    private router: Router,
    private authService: AuthService,
    private nbDialogService: NbDialogService
  ) {
    this.startRouterHooks();
  }

  startRouterHooks() {
    this.subscriptions.add(
      this.router.events.subscribe(event => {
        switch (true) {
          case event instanceof NavigationStart:
            this.navigationStart();
            break;
          case event instanceof NavigationEnd:
            break;
        }
      })
    );
  }

  navigationStart() {
    this.handleDialogs();
  }

  handleDialogs() {
    const termOfUse = localStorage.getItem("termo");
    //if (termOfUse === 'false') {
    //  this.showTermOfUse();
    //} else {
    this.handleFirstAccess();
    //}
  }

  handleFirstAccess() {
    const firstAccess = localStorage.getItem("firstAccess");
    if (firstAccess === "true") {
      this.showChangePassword();
    }
  }

  //showTermOfUse() {
  //  this.subscriptions.add(
  //    this.authService.getTermoUso().subscribe((response) => {
  //      const value = response.value;
  //      if (value[0]) {
  //        const text = value[0].mensagem;
  //        const id = value[0].id;
  //        const dialog = this.nbDialogService
  //          .open(TermOfUseComponente, {
  //            autoFocus: false,
  //            closeOnBackdropClick: false,
  //            closeOnEsc: false,
  //            context: { outsideData: text, termoId: id },
  //          })
  //          .onClose.subscribe(
  //            (result: 'accept' | 'refused') =>
  //              result === 'accept' && this.handleFirstAccess(),
  //          );
  //      }
  //    }),
  //  );
  //}

  showChangePassword() {
    this.nbDialogService.open(ModalFirstAccessComponent, {
      closeOnBackdropClick: false,
      closeOnEsc: false
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
