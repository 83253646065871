import { Input, AfterContentChecked, OnDestroy } from "@angular/core";

import Response from "../../../models/ResponseGET";
import { NbDialogRef, NbTabComponent, NbToastrService } from "@nebular/theme";
import { Component, OnInit } from "@angular/core";
import GetCliente from "src/app/models/Cliente";
import { FormsModule } from "@angular/forms";
import { HelperService } from "src/app/services/helper.service";

import { Subscription } from "rxjs";
import IPostPutCliente from "src/app/models/PostPutCliente";
import { ClienteService } from "src/app/services/cliente.service";
import GetUser, { UsersAdded, UsersToAdd } from "src/app/models/User";
import { UserService } from "src/app/services/user.service";
import { PermissionAdded, PermissionToAdd } from "src/app/models/Permissions";
import ResponsePOST from "src/app/models/ResponsePOST";

import moment from "moment";

enum Acao {
  Editar = 1,
  Visualizar = 2
}

@Component({
  selector: "app-new-edit-cliente-modal",
  templateUrl: "./new-edit-cliente-modal.component.html",
  styleUrls: ["./new-edit-cliente-modal.component.scss"]
})
export class NewEditClienteModalComponent
  implements OnInit, AfterContentChecked, OnDestroy {
  @Input() outsideData: GetCliente;
  @Input() acao: Acao;
  @Input() editable: boolean = true;

  selectedTab: string = "Usuários"; // 'Cadastro de cliente';
  isLoading: boolean;
  subscriptions = new Subscription();
  data: IPostPutCliente = {
    id: "",
    razaoSocial: "",
    cnpj: "",
    email: "",
    telefone: "",
    foto: null,
    dataCriacao: "",
    dataUltimaAtualizacao: ""
  };

  dataUsers: UsersToAdd[] = [];
  dataUsersAdded: UsersAdded[] = [];
  public UsersToAddTemp: string[] = [];
  public UsersAddedTemp: string[] = [];
  checkRemove = false;
  checkAdd = false;
  showPassword = false;

  CELLPHONE: string = "(00) 0 0000-0000";
  LANDLINE: string = "(00) 0000-0000";
  phoneMask = this.LANDLINE;
  phoneNumber = "";
  previusLength = 0;

  newOrEditTabTitle = "";

  onPhoneChanged() {
    if (this.phoneNumber.length <= 10 && this.phoneMask === this.CELLPHONE) {
      this.phoneMask = this.LANDLINE;
    } else if (
      this.phoneNumber.length === 10 &&
      this.phoneMask === this.LANDLINE &&
      this.previusLength === 10
    ) {
      this.phoneMask = this.CELLPHONE;
    }

    this.previusLength = this.phoneNumber.length;
  }

  constructor(
    private nbDialogRef: NbDialogRef<NewEditClienteModalComponent>,
    private helperService: HelperService,
    private clienteService: ClienteService,
    private nbToastrService: NbToastrService,
    private users: UserService
  ) {}

  ngOnInit() {
    this.fetchUsers();

    if (this.outsideData && this.outsideData.id !== "")
      this.fetchClienteUsuarios(this.outsideData.id);

    this.isLoading = this.outsideData ? true : false;

    this.newOrEditTabTitle =
      this.acao == Acao.Visualizar ? "Ver Empresa" : "Cadastro de empresa";
  }

  toggleShowPass() {
    this.showPassword = !this.showPassword;
  }

  changeFile(event) {
    this.data.foto = event.target.files[0];
  }

  fetchUsers() {
    return new Promise<void>(resolve => {
      this.users.fetchAllUsersTipoCliente().subscribe((response: Response) => {
        this.dataUsers = response.value.sort();
        resolve();
      });
    });
  }

  changeValueInArrays(value: boolean, array, arrTemp) {
    if (value) {
      array.forEach(element => {
        element.check = true;
        this.setValueToggle(element.id, value, arrTemp);
      });
    } else {
      array.forEach(element => {
        element.check = false;
      });
      arrTemp = [];
    }
  }

  setValueToggle(value, event: boolean, arr) {
    if (event) {
      if (arr.indexOf(value) === -1) {
        arr.push(value);
      }
    } else {
      if (arr.indexOf(value) > -1) {
        const index = arr.indexOf(value);
        arr.splice(index, 1);
      }
    }
  }

  UsersAddedAll(event) {
    this.checkRemove = event;
    this.changeValueInArrays(event, this.dataUsersAdded, this.UsersAddedTemp);

    if (!event) {
      this.UsersAddedTemp = [];
    }
  }

  UsersToAddAll(event) {
    this.checkAdd = event;
    this.changeValueInArrays(event, this.dataUsers, this.UsersToAddTemp);

    if (!event) {
      this.UsersToAddTemp = [];
    }
  }

  removeUser(user: UsersToAdd) {
    this.dataUsers = this.dataUsers.filter(item => item.id !== user.id);
  }

  async fetchClienteUsuarios(clienteId: string) {
    if (clienteId !== "0" && clienteId !== "") {
      this.isLoading = true;
      await this.fetchUsers();

      this.clienteService.fetchClienteUsuario(clienteId).subscribe(
        (response: Response) => {
          this.dataUsersAdded = [];
          this.dataUsersAdded = response.value;

          this.dataUsers.forEach(user => {
            this.dataUsersAdded.forEach(userToAdd => {
              if (user.id === userToAdd.usuario.id) {
                this.removeUser(user);
              }
            });
          });
        },
        err => {
          this.dataUsersAdded = [];
          this.dataUsersAdded = [];
        }
      );
      this.isLoading = false;
    }
  }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  save(registerClienteForm) {
    //const tab = this.selectedTab;

    // switch (tab) {
    //   //case 'Usuários':
    //   //  alert('usuarios');
    //   //  break;
    //   case "Cadastro de empresas":

    this.putOrPostGroup();

    //     break;
    // }
  }

  ngAfterContentChecked() {
    this.setValuesOnFields();
  }

  tabChanged(currentTab: NbTabComponent) {
    this.selectedTab = currentTab.tabTitle;
  }

  setUser(value, event) {
    this.setValueToggle(value, event, this.UsersToAddTemp);
  }

  setUsersInCostumer() {
    if (Array.isArray(this.UsersToAddTemp) && this.UsersToAddTemp.length) {
      this.dataUsers.forEach(user => {
        this.UsersToAddTemp.forEach(UserToAdd => {
          if (user.id === UserToAdd) {
            this.addUsersAdded(user);
          }
        });
      });
    } else {
      this.nbToastrService.warning("Escolha ao menos um usuário", "Atenção");
      return;
    }

    return;
  }

  removeUserAdded(clienteUser: UsersAdded) {
    this.clienteService.deleteClienteUsuario(clienteUser.id).subscribe(() => {
      this.dataUsersAdded = this.dataUsersAdded.filter(
        item => item.id !== clienteUser.id
      );
      this.showSuccess();
      this.UsersAddedTemp = [];
      this.checkRemove = false;
      this.hasTooltip();
    });

    this.backUserAdded(clienteUser);
  }

  setUserAdded(value, event) {
    this.setValueToggle(value, event, this.UsersAddedTemp);
  }

  backUserAdded(user: UsersAdded) {
    delete user?.check;
    this.dataUsers.push(user.usuario);
  }

  removeUsersInCostumer() {
    if (this.UsersAddedTemp.length > 0) {
      this.dataUsersAdded.forEach(user => {
        this.UsersAddedTemp.forEach(UserToRemove => {
          if (user.id === UserToRemove) {
            this.removeUserAdded(user);
          }
        });
      });
    } else {
      this.nbToastrService.warning("Escolha ao menos uma permissão", "Atenção");
      return;
    }
  }

  addUsersAdded(user: UsersToAdd) {
    this.removeUser(user);
    this.postUserAdded(user);
  }

  showSuccess() {
    this.nbToastrService.success(
      "Informações alteradas com sucesso",
      "Sucesso",
      {
        duration: 3000,
        preventDuplicates: true
      }
    );
  }

  hasTooltip() {
    const tooltip = document.querySelectorAll(
      "div.cdk-overlay-container > div.cdk-overlay-connected-position-bounding-box"
    );
    tooltip[0]?.remove();
  }

  postUserAdded(user: UsersToAdd) {
    this.clienteService
      .createClienteUsuario({
        clienteId: this.outsideData.id,
        usuarioId: user.id
      })
      .subscribe(
        (response: ResponsePOST) => {
          this.dataUsersAdded.push({
            id: response.value.id,
            usuario: user
          });
          this.showSuccess();
          this.UsersToAddTemp = [];
          this.checkAdd = false;
          this.hasTooltip();
        },
        resp => {
          if (resp !== "Sem permissão") {
            this.nbToastrService.danger("Falha ao inserir registro", "Falha", {
              duration: 3000
            });
          }
        }
      );
  }

  setValuesOnFields() {
    if (this.outsideData && this.isLoading) {
      this.data = {
        id: this.outsideData.id,
        razaoSocial: this.outsideData.razaoSocial,
        cnpj: this.outsideData.cnpj,
        email: this.outsideData.email,
        telefone: this.outsideData.telefone,
        dataCriacao: moment(this.outsideData.dataCriacao).format(
          "DD/MM/YYYY HH:mm:ss"
        ),
        dataUltimaAtualizacao: this.outsideData.dataUltimaAtualizacao
          ? moment(this.outsideData.dataUltimaAtualizacao).format(
              "DD/MM/YYYY HH:mm:ss"
            )
          : null
      };
      this.isLoading = false;
    }
  }

  putOrPostGroup() {
    try {
      this.isLoading = true;

      const formData = new FormData();
      this.data.id ? formData.append("id", this.data.id) : "";
      formData.append("razaoSocial", this.data.razaoSocial);
      formData.append("cnpj", this.data.cnpj);
      formData.append("email", this.data.email);
      formData.append("telefone", this.data.telefone);
      formData.append("img", this.data.foto);
      
      const observable = this.data.id
        ? this.clienteService.update(formData, this.data.id)
        : this.clienteService.save(formData);

      this.subscriptions.add(
        observable.subscribe(
          res => {
            this.nbToastrService.success(
              this.data.id ? "Editado com sucesso!" : "Cadastrado com sucesso!",
              "Sucesso",
              {
                duration: 2000
              }
            );

            this.close();
          },
          err => {
            this.isLoading = false;
            //this.close();
          }
        )
      );
    } finally {
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
