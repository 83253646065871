import { FilterTableModule } from "../../components/filter-table/filter-table.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EstacaoComponent } from "./consult-estacao.component";
import { ptBR } from "date-fns/locale";
import {
  NbCardModule,
  NbButtonModule,
  NbCheckboxModule,
  NbSelectModule,
  NbInputModule,
  NbIconModule,
  NbDatepickerModule
} from "@nebular/theme";
import { DatatableModule } from "src/app/components/datatable/datatable.module";
import { EstacaoRoutingModule } from "./consult-estacao-routing.module";
import { FavoriteModule } from "src/app/components/favorite/favorite.module";
import { ConfigurarParamComponent } from "./configurar-param/configurar-param.component";
import { ConfigurarCategoriasComponent } from "./configurar-categorias/configurar-categorias.component";
import { NgxMaskModule } from "ngx-mask";
import { HistoricoEstacaoComponent } from "./historico-estacao/historico-estacao.component";
import { FormsModule } from "@angular/forms";
import { NbDateFnsDateModule } from "@nebular/date-fns";
import { WebserviceComponent } from "./webservice/webservice.component";
import { LinkComponent } from "./link/link.component";
import { ViewStringBrutaComponent } from './view-string-bruta/view-string-bruta.component';
import { NewParametroModalComponent } from './new-parametro-modal/new-parametro-modal.component';
import { NewEditLinkModalComponent } from './new-edit-link-modal/new-edit-link-modal.component';
import { ErrorFieldComponent } from "src/app/components/error-field/error-field.component";
import { CloneParamsModalComponent } from './clone-params-modal/clone-params-modal.component';

@NgModule({
  declarations: [
    EstacaoComponent,
    ConfigurarParamComponent,
    ConfigurarCategoriasComponent,
    HistoricoEstacaoComponent,
    WebserviceComponent,
    LinkComponent,
    ViewStringBrutaComponent,
    NewParametroModalComponent,
    NewEditLinkModalComponent,
    ErrorFieldComponent,
    CloneParamsModalComponent,
  ],
  imports: [
    EstacaoRoutingModule,
    CommonModule,
    FilterTableModule,
    NbCardModule,
    NbCheckboxModule,
    NbInputModule,
    NbButtonModule,
    NbSelectModule,
    DatatableModule,
    FavoriteModule,
    FormsModule,
    NbIconModule,
    NbDatepickerModule.forRoot(),
    NbDateFnsDateModule.forRoot({
      parseOptions: { locale: ptBR },
      formatOptions: { locale: ptBR }
    }),
    NgxMaskModule.forRoot()
  ]
})
export class EstacaoModule {}
