import { Component, Input, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { Subscription } from "rxjs";
import GetAntenaPosicao from "src/app/models/AntenaPosicao";
import { HelperService } from "src/app/services/helper.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-modal-view-position",
  templateUrl: "./modal-view-position.component.html",
  styleUrls: ["./modal-view-position.component.scss"]
})
export class ModalViewPositionComponent implements OnInit {
  @Input() data: GetAntenaPosicao;
  @Input() isn: string;

  isLoading = false;
  subscriptions = new Subscription();

  constructor(
    private nbDialogRef: NbDialogRef<ModalViewPositionComponent>,
    private helperService: HelperService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {}

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }
}
