export enum MaskedValues {
  date,
  dateTime,
  cpfCnpj,
  phone,
  cep,
  boolean
}

export interface ColumnObject {
  [key: string]: ColumnProperties;
}

export interface ColumnProperties {
  title?: string;
  class?: "text-left" | "text-center" | "text-right";
  valueType?: MaskedValues;
  width?: string;
  sort?: boolean;
  sortDirection?: "asc" | "desc";
  filter?: boolean;
  type?: "text" | "html" | "custom";
  renderComponent?: any;
  onComponentInitFunction?: (renderComponent: any) => void;
  /**
   * Função útil para modificar o valor a ser exibido na table de acordom uma logica, ex: (value) => value === 0 ? 'zero' : 'um'
   */
  valuePrepareFunction?: (cell: any, row: any) => void;
  /**
   * Função utilizada para executar filtro por pesquisa em colunas que utilizam valuePrepareFunction().
   */
  filterFunction?: (cell: any, search: string) => void;
}

export interface DataTableActions {
  buttons: Array<
    | "edit"
    | "delete"
    | "download"
    | "active-disable"
    | "reset"
    | "unlock"
    | "showMapa"
    | "view"
    | "configure"
  >;
  editEvent?: () => void;
  activeDisableEvent?: (status: boolean) => void;
  deleteEvent?: () => void;
  downloadEvent?: () => void;
  resetEvent?: () => void;
  unlockEvent?: () => void;
  showMapaEvent?: () => void;
  viewEvent?: () => void;
  configureEvent?: () => void;
}

export class DataTableSettings {
  hideSubHeader: boolean;
  noDataMessage: string;
  mode: string;
  class: string;
  actions: {
    add: boolean;
    edit: boolean;
    delete: boolean;
    download: boolean;
    reset: boolean;
    unlock: boolean;
    showMapa: boolean;
    view: boolean;
    configure: boolean;
    position: string;
    columnTitle: string;
    custom?: any[];
  };
  pager: {
    perPage: number;
    display: boolean;
  };
  columns: ColumnObject;

  constructor() {
    this.hideSubHeader = true;
    this.noDataMessage = "Nenhum dado encontrado.";
    this.mode = "external";
    this.actions = {
      add: false,
      edit: false,
      delete: false,
      download: false,
      reset: false,
      unlock: false,
      showMapa: false,
      view: false,
      configure: false,
      position: "right",
      columnTitle: "Ações Desabilitadas"
    };
    this.pager = {
      perPage: 10,
      display: true
    };
    this.columns = {};
  }
}

export interface DatatableFilterSettings {
  field: string;
  search: string;
  filter?: (cell: any, search: string) => void;
}
