import {
  Component,
  OnInit,
  Input,
  AfterContentChecked,
  OnDestroy,
  ViewChild,
  ElementRef
} from "@angular/core";
import { NbDialogRef, NbDialogService, NbToastrService } from "@nebular/theme";
import { GroupService } from "src/app/services/group.service";
import { CompanyService } from "src/app/services/company.service";
import { HelperService } from "src/app/services/helper.service";
import ICompany from "src/app/models/Company";
import IPostPutGroup from "src/app/models/PostPutGroup";
import Module from "src/app/models/Module";
import { ModuleService } from "src/app/services/module.service";
import Response from "src/app/models/ResponseGET";
import { AuthService } from "src/app/services/auth.service";
import Group from "src/app/models/Group";
import { forkJoin, Subscription } from "rxjs";
import { AlertService } from "src/app/services/alert.service";
import { GroupPermissionComponent } from "src/app/pages/security/group-permission/group-permission.component";

@Component({
  selector: "app-new-edit-group-modal",
  templateUrl: "./new-edit-group-modal.component.html",
  styleUrls: ["./new-edit-group-modal.component.scss"]
})
export class NewEditGroupModalComponent
  implements OnInit, OnDestroy, AfterContentChecked {
  @Input() outsideData: Group;
  @Input() editable: boolean = true;

  @ViewChild("submitButtonGroup") submitButtonGroup: ElementRef = null;

  subscriptions = new Subscription();
  isLoading: boolean;
  companyId: string;
  moduleId: string;
  data: IPostPutGroup = {
    id: "",
    nome: "",
    moduloId: "",
    empresaId: "0",
    padrao: false
  };
  companies = [] as ICompany[];
  modules = [] as Module[];
  isJustOneCompany: boolean = this.authService.getIsJustOneCompany();

  buttonClicked: Boolean = false;

  constructor(
    private nbToastrService: NbToastrService,
    private nbDialogRef: NbDialogRef<NewEditGroupModalComponent>,
    private groupService: GroupService,
    private companyService: CompanyService,
    private helperService: HelperService,
    private moduleService: ModuleService,
    private authService: AuthService,
    private alert: AlertService
  ) {}

  ngOnInit() {
    this.fetchAndSetInitialData();
    this.isLoading = this.outsideData ? true : false;
  }

  ngAfterContentChecked() {
    this.setValuesOnFields();
  }

  fetchAndSetInitialData() {
    this.subscriptions.add(
      forkJoin([
        this.companyService.fetch(),
        this.moduleService.fetch()
      ]).subscribe((response: Response[]) => {
        this.companies = response[0].value as ICompany[];
        this.modules = response[1].value as Module[];
        this.moduleId = this.modules[0].id;
        this.data.moduloId = this.moduleId;
      })
    );

    if (this.isJustOneCompany) {
      this.companyId = this.authService.getDefaultCompanyId();
      this.data.empresaId = this.companyId;
    }
  }

  setValuesOnFields() {
    if (this.outsideData && this.isLoading) {
      this.data = {
        id: this.outsideData.id,
        nome: this.outsideData.nome,
        moduloId: this.outsideData.modulo.id,
        empresaId: this.companyId,
        padrao: this.outsideData.padrao
      };
      this.isLoading = false;
    }
  }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  save(registerGroupForm) {
    this.buttonClicked = true;
    if (registerGroupForm.valid) this.putOrPostGroup();
  }

  setPadrao() {
    this.data.padrao = !this.data.padrao;
  }

  putOrPostGroup() {
    try {
      this.isLoading = true;

      const observable = this.data.id
        ? this.groupService.update(this.data.id, this.data)
        : this.groupService.save(this.data);

      this.subscriptions.add(
        observable.subscribe(
          res => {
            this.nbToastrService.success(
              this.data.id
                ? "Perfil atualizado com sucesso"
                : "Perfil cadastrado com sucesso",
              "Sucesso",
              {
                duration: 2000
              }
            );

            this.close();
          },
          err => {
            this.isLoading = false;
            //this.alert.warning(err);
            //this.close();
          }
        )
      );
    } finally {
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
