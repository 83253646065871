import {
  Component,
  AfterViewInit,
  OnInit,
  OnDestroy,
  AfterViewChecked
} from "@angular/core";
import { AuthService } from "./services/auth.service";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
  isLoaded: boolean;
  title = "plataformarh-backoffice";
  isAuthenticated = false;
  onLandingPage: boolean;
  subscriptions = new Subscription();

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.getRouteInfo();
    this.setLoggedIn();
  }

  getRouteInfo() {
    this.subscriptions.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.onLandingPage = event.url.includes("home");
        }
      })
    );
  }

  setLoggedIn() {
    this.subscriptions.add(
      this.authService.isLoggedIn.subscribe(loggedIn => {
        //if (loggedIn) {
        this.isAuthenticated = loggedIn;
        //  }
        this.isLoaded = true;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
