import { Component, OnInit, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

import * as QuillNamespace from "quill";
let Quill: any = QuillNamespace;

import ImageResize from "quill-image-resize-module";
import { QuillModules } from "ngx-quill";
Quill.register("modules/imageResize", ImageResize);

@Component({
  selector: "app-richtext",
  templateUrl: "./richtext.component.html",
  styleUrls: ["./richtext.component.scss"]
})
export class RichtextComponent implements OnInit {
  @Input() disabled = false;
  @Input() showFontSelect = false;
  @Input() showSize = true;
  @Input() showColorSelect = true;
  @Input() showQuote = true;
  @Input() showIdent = true;
  @Input() showAlign = true;
  @Input("modelo") mdl = "";

  content = "";
  editor_modules: QuillModules = {
    imageResize: true
  };

  constructor() {}

  ngOnInit() {
    this.content = this.mdl;
  }
}
