import { Input, Component, OnInit, AfterContentChecked, OnDestroy } from '@angular/core';
import { NbDialogRef } from "@nebular/theme";
import { Subscription } from 'rxjs';
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: 'app-view-string-bruta',
  templateUrl: './view-string-bruta.component.html',
  styleUrls: ['./view-string-bruta.component.scss']
})
export class ViewStringBrutaComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() outsideData: any;
  isLoading: boolean;
  stringBruta: string;
  subscriptions = new Subscription();

  constructor(
    private nbDialogRef: NbDialogRef<ViewStringBrutaComponent>,
    private helperService: HelperService,
  ) { }

  ngOnInit() {
    this.isLoading = true;
  }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  ngAfterContentChecked() {
    this.setValuesOnFields();
  }

  setValuesOnFields() {
    if (this.outsideData && this.isLoading) {
      this.stringBruta = this.outsideData.stringBruta;
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
