import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { News } from "../models/News";
import IPostPutNews from "../models/PostPutNews";
import Response from "../models/ResponseGET";
import { AppConfig } from "src/config/app.config";

@Injectable({
  providedIn: "root"
})
export class NewsService {
  private BASE_URL = `${AppConfig.settings.urlApi}`;
  constructor(private http: HttpClient) {}

  fetch(page: number, rowsPerPage: number, companieId?: string) {
    return companieId
      ? this.http.get<Response>(
          `${AppConfig.settings.urlApi}/Noticia?empresaId=${companieId}&sortingProp=dataPublicacao&ascending=false&take=${rowsPerPage}&page=${page}&landing=true`
        )
      : this.http.get<Response>(
          `${AppConfig.settings.urlApi}/Noticia?sortingProp=dataPublicacao&ascending=false&take=${rowsPerPage}&page=${page}`
        );
  }

  fetchByIdDescricao(id: string) {
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/Noticia/${id}/Descricao`
    );
  }

  fetchByID(id: string) {
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/Noticia/${id}`
    );
  }

  fetchFiltered(
    firstDate?: string,
    endDate?: string,
    title?: string,
    published?: boolean,
    buscarTudo?: boolean,
    take?: number,
    page?: number
  ) {
    const urlFiltered = [
      firstDate ? "dataInicio=" + firstDate : null,
      endDate ? "dataFim=" + endDate : null,
      title ? "titulo=" + title : null,
      published ? "situacao=Publicada" : null,
      buscarTudo ? "buscarTudo=" + buscarTudo : false
    ]
      .filter(item => !!item)
      .join("&");
    return urlFiltered.length > 0
      ? this.http.get<Response>(
          `${AppConfig.settings.urlApi}/Noticia?take=${take}&page=${page}&situacao=Publicada&sortingProp=dataPublicacao&ascending=false&${urlFiltered}`
        )
      : this.http.get<Response>(
          `${AppConfig.settings.urlApi}/Noticia?take=${take}&page=${page}&situacao=Publicada&sortingProp=dataPublicacao&ascending=false`
        );
  }

  fetchLastNews(amount: number, published?: boolean, landing?: boolean) {
    const urlFiltered = published ? "situacao=Publicada&" : "";
    const landingFiltered = landing ? "landing=true&" : "landing=false&";

    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/Noticia?take=${amount}&${urlFiltered}offset=0&${landingFiltered}sortingProp=dataPublicacao&ascending=false`
    );
  }

  fetchFilteredNewsAndCampaigns(
    firstDate?: string,
    endDate?: string,
    title?: string,
    published?: boolean,
    buscarTudo?: boolean,
    take?: number,
    page?: number
  ) {
    const urlFiltered = [
      firstDate ? "dataInicio=" + firstDate : null,
      endDate ? "dataFim=" + endDate : null,
      title ? "titulo=" + title : null,
      published ? "situacao=Publicada" : null,
      buscarTudo ? "buscarTudo=" + buscarTudo : false
    ]
      .filter(item => !!item)
      .join("&");

    return urlFiltered.length > 0
      ? this.http.get<Response>(
          `${AppConfig.settings.urlApi}/Noticia/noticia-campanha?take=${take}&page=${page}&situacao=Publicada&sortingProp=dataPublicacao&ascending=false&${urlFiltered}`
        )
      : this.http.get<Response>(
          `${AppConfig.settings.urlApi}/Noticia/noticia-campanha?take=${take}&page=${page}&situacao=Publicada&sortingProp=dataPublicacao&ascending=false`
        );
  }

  fetchByDate(
    page: number,
    rowsPerPage: number,
    companieId: string,
    firstDate: string,
    endDate: string,
    titulo: string,
    published?: boolean
  ) {
    let urlFiltered = published ? "situacao=Publicada&" : "";

    if (titulo) {
      urlFiltered += `titulo=${titulo}&`;
    }
    if (firstDate) {
      urlFiltered += `dataInicio=${firstDate}&`;
    }
    if (endDate) {
      urlFiltered += `dataFim=${endDate}&`;
    }
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/Noticia?empresaId=${companieId}&${urlFiltered}take=${rowsPerPage}&page=${page}&landing=true`
    );
  }

  delete(id: string) {
    return this.http.delete<any>(`${AppConfig.settings.urlApi}/Noticia/${id}`);
  }

  save(params: any) {
    return this.http.post<Response>(
      `${AppConfig.settings.urlApi}/Noticia`,
      params
    );
  }

  update(params: IPostPutNews, data: any) {
    return this.http.put<Response>(
      `${AppConfig.settings.urlApi}/Noticia/${params.id}`,
      data
    );
  }
}
