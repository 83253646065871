import {
  Component,
  OnInit,
  Input,
  AfterContentChecked,
  OnDestroy,
  ViewChild,
  ElementRef
} from "@angular/core";
import { NbDialogRef,  NbToastrService } from "@nebular/theme";
import { CategoryService } from "src/app/services/category.service";
import { HelperService } from "src/app/services/helper.service";
import Category from "src/app/models/Category";
import { forkJoin, Subscription } from "rxjs";
import { AlertService } from "src/app/services/alert.service";

@Component({
  selector: "app-new-edit-category-modal",
  templateUrl: "./new-edit-category-modal.component.html",
  styleUrls: ["./new-edit-category-modal.component.scss"]
})
export class NewEditCategoryModalComponent
  implements OnInit, OnDestroy, AfterContentChecked {
  @Input() outsideData: Category;
  @Input() editable: boolean = true;

  @ViewChild("submitButtonGroup") submitButtonGroup: ElementRef = null;

  subscriptions = new Subscription();
  isLoading: boolean;
  data: any = {
    dataCriacao: "",
    categoria: "",
  };

  constructor(
    private nbToastrService: NbToastrService,
    private nbDialogRef: NbDialogRef<NewEditCategoryModalComponent>,
    private categoryService: CategoryService,
    private helperService: HelperService,
    private alert: AlertService
  ) {}

  ngOnInit() {
    this.isLoading = this.outsideData ? true : false;
  }

  ngAfterContentChecked() {
    this.setValuesOnFields();
  }

  setValuesOnFields() {
    if (this.outsideData && this.isLoading) {
      this.data = {
        categoria: this.outsideData.categoria,
        dataCriacao: this.outsideData.dataCriacao,
      };
      this.isLoading = false;
    }
  }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  save(registerGroupForm) {
    if (registerGroupForm.valid) this.putOrPostGroup();
  }

  putOrPostGroup() {
    try {
      this.isLoading = true;
      this.data.dataCriacao = this.outsideData ? this.outsideData.dataCriacao : new Date();
      const observable = this.outsideData ? this.categoryService.updateCategory(this.outsideData.id, this.data) : this.categoryService.saveCategory(this.data);

      this.subscriptions.add(
        observable.subscribe(
          res => {
            this.nbToastrService.success(this.outsideData ? "Categoria atualizada com sucesso!" : "Categoria cadastrada com sucesso!","Sucesso", { duration: 2000 });
            this.close();
          },
          err => {
            this.isLoading = false;
            this.alert.warning(err);
          }
        )
      );
    } finally {
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
