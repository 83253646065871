import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  AfterViewInit
} from "@angular/core";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { IPostPutCampaigns } from "src/app/models/PostPutCampaigns";
import IResponseGET from "src/app/models/ResponseGET";
import { RichtextComponent } from "src/app/components/richtext/richtext.component";
import { CampaignService } from "src/app/services/campaign.service";
import { HelperService } from "src/app/services/helper.service";
import { Subscription } from "rxjs";
import { Campaign } from "src/app/models/Campaigns";
import { CompanyService } from "src/app/services/company.service";
import { AuthService } from "src/app/services/auth.service";
import ICompany from "src/app/models/Company";
import * as dayjs from "dayjs";
import { Peca } from "src/app/models/Peca";
import { CurrentUserService } from "src/app/services/current-user.service";
import { ColumnObject, DataTableActions } from "src/app/models/DataTable";
import {
  DecisionAlertModalComponent,
  TipoMensagem
} from "src/app/components/decision-alert/decision-alert-modal.component";
import { Util } from "../../../../helpers/util";
import { AlertService } from "src/app/services/alert.service";
import { NgForm } from "@angular/forms";
import imageCompression from "browser-image-compression";
import { CropperImageModalComponent } from "src/app/components/cropper-image-modal/cropper-image-modal.component";

@Component({
  selector: "app-new-edit-campaigns-modal",
  templateUrl: "./new-edit-campaigns-modal.component.html",
  styleUrls: ["./new-edit-campaigns-modal.component.scss"]
})
export class NewEditCampaignsModalComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(RichtextComponent) richEditor: RichtextComponent;
  @Input() outsideData: Campaign;

  compressOptions = {
    maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight: 1920, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    useWebWorker: true // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
  };

  subscriptions = new Subscription();
  companyId: string;
  data: IPostPutCampaigns = {
    file: null,
    fotoDetalhe: null,
    urlImagemDetalhe: "",
    id: "",
    titulo: "",
    foto: "",
    descricao: "",
    situacao: 0,
    empresaId: "",
    dataInicio: null,
    dataPublicacao: null,
    dataCriacao: null,
    dataFim: null,
    pecas: [],
    urlImagem: "",
    fotoExcluida: false,
    nomeUsuario: "",
    dataAlteracao: null
  };
  peca: Peca = {
    id: "",
    tipo: "1",
    descricao: "",
    file: null
  };
  isLoading: boolean;
  isPublishing: boolean = false;
  isJustOneCompany: boolean = this.authService.getIsJustOneCompany();
  companies = [] as ICompany[];
  textoPublicar: "Publicar Campanha" | "Remover Publicação" =
    "Publicar Campanha";
  columns: ColumnObject = {
    tipo: {
      title: "Tipo"
    },
    descricao: {
      title: "Descrição"
    }
  };
  dataPeca = [] as Peca[];
  permissions = this.uService.getEnumPermissions();
  isPhotoLoaded = true;
  isSavingPiece: boolean = false;

  selectedImgCover: any;
  selectedImgDetail: any;

  constructor(
    private alert: AlertService,
    private campaignService: CampaignService,
    private helperService: HelperService,
    private nbDialogRef: NbDialogRef<NewEditCampaignsModalComponent>,
    private companyService: CompanyService,
    private authService: AuthService,
    private changeDetection: ChangeDetectorRef,
    public uService: CurrentUserService,
    private nbDialogService: NbDialogService,
    private util: Util
  ) {}

  ngOnInit() {
    this.fetchAndSetInitialData();
    this.isLoading = this.outsideData ? true : false;
  }

  ngAfterContentChecked() {
    this.setValuesOnFields();
  }

  ngAfterViewInit() {
    this.campaignService
      .fetchByIdDescricao(this.outsideData.id)
      .subscribe((response: IResponseGET) => {
        const news = response.value as Campaign;
        this.data.descricao = news.descricao;
        this.richEditor.content = this.data.descricao;
        this.changeDetection.detectChanges();
      });
  }

  fetchCompanies() {
    this.subscriptions.add(
      this.companyService.fetch().subscribe(response => {
        const {
          value,
          hasSuccess
        }: { value: string; hasSuccess: boolean } = response;
        this.companies = response.value as ICompany[];
      })
    );
  }

  fetchAndSetInitialData() {
    this.fetchCompanies();
    if (this.isJustOneCompany) {
      const { sid } = this.authService.decode();
      this.companyId = sid;
      this.data.empresaId = this.companyId;
      if (this.outsideData) {
        this.dataPeca = this.outsideData.pecas.map(item =>
          this.includeActionsColumnOnData(item)
        );
      }
    }
  }

  includeActionsColumnOnData?(item: Peca) {
    const buttons = this.uService.getActionsTableAllowed(
      null,
      null,
      this.permissions.DeletarCampanha,
      this.permissions.CadastrarCampanha
    );

    const dataWithActions = {
      ...item,
      actions: {
        buttons,
        deleteEvent: () => this.delete(item),
        downloadEvent: () => this.download(item)
      } as DataTableActions
    };

    return dataWithActions;
  }

  async delete(item: Peca) {
    try {
      const decision = await this.nbDialogService
        .open(DecisionAlertModalComponent, {
          autoFocus: false,
          context: { tipo: TipoMensagem.Deletar }
        })
        .onClose.toPromise<"cancel" | "remove">();

      if (decision === "remove") {
        const response = await this.campaignService
          .deletePeca(item.id)
          .toPromise();
        this.alert.success("Peça removida com sucesso");
        const removeIndex = this.outsideData.pecas
          .map(el => el.id)
          .indexOf(item.id);
        // remove object
        this.outsideData.pecas.splice(removeIndex, 1);
        this.fetchAndSetInitialData();
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  async download(item: Peca) {
    try {
      const response = await this.helperService
        .download("Campanha/anexos-peca", item.id)
        .toPromise();
      this.util.convertBlobToBase64(response, name);
    } catch (ex) {
      console.error(ex);
    }
  }

  setValuesOnFields() {
    if (this.outsideData && this.isLoading) {
      this.isPhotoLoaded = false;
      this.data = {
        fotoExcluida: false,
        file: null,
        id: this.outsideData.id,
        descricao: this.outsideData.descricao,
        empresaId: this.companyId,
        foto: this.outsideData.foto,
        fotoDetalhe: this.outsideData.fotoDetalhe,
        urlImagemDetalhe: this.outsideData.urlImagemDetalhe,
        situacao: this.outsideData.situacao,
        titulo: this.outsideData.titulo,
        dataFim:
          this.outsideData.dataFim === undefined
            ? null
            : new Date(
                dayjs(this.outsideData.dataFim).format("YYYY-MM-DD HH:mm:ss")
              ),
        dataInicio: new Date(
          dayjs(this.outsideData.dataInicio).format("YYYY-MM-DD HH:mm:ss")
        ),
        dataCriacao:
          this.outsideData.dataCriacao === undefined
            ? null
            : new Date(
                dayjs(this.outsideData.dataCriacao).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              ),
        dataPublicacao:
          this.outsideData.dataPublicacao === undefined
            ? null
            : new Date(
                dayjs(this.outsideData.dataPublicacao).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              ),
        pecas: this.outsideData.pecas,
        urlImagem: this.outsideData.urlImagem,
        nomeUsuario: this.outsideData.nomeUsuario,
        dataAlteracao:
          this.outsideData.dataAlteracao === undefined
            ? null
            : new Date(
                dayjs(this.outsideData.dataAlteracao).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              )
      };
      this.verifyStatus();
      this.isPhotoLoaded = true;
      this.isLoading = false;
    }
  }

  verifyStatus() {
    this.textoPublicar =
      this.data.situacao === 0 ? "Publicar Campanha" : "Remover Publicação";
  }

  async fileChosen(
    event: Event,
    kindOfFile: "cover-landing" | "read-image" | "piece-file"
  ) {
    this.isPhotoLoaded = kindOfFile !== "cover-landing";
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length) {
      const file = target.files.item(0);

      const croppedImage =
        kindOfFile === "cover-landing"
          ? await this.nbDialogService
              .open(CropperImageModalComponent, {
                autoFocus: false,
                closeOnEsc: false,
                closeOnBackdropClick: false,
                context: {
                  imageChangedEvent: event,
                  showAlertAfterCropping: true,
                  maintainAspectRatio: true,
                  cropperMinWidth: 677.33,
                  cropperMinHeight: 381,
                  textLabel:
                    "Esta imagem será exibida como capa e também nos cards de campanha. Para que a exibição fique adequada, a proporção do recorte é fixada em 1,77 e a resolução mínima em aprox. 677px x 381px."
                }
              })
              .onClose.toPromise()
          : kindOfFile === "read-image"
          ? await this.nbDialogService
              .open(CropperImageModalComponent, {
                autoFocus: false,
                closeOnEsc: false,
                closeOnBackdropClick: false,
                context: {
                  imageChangedEvent: event,
                  showAlertAfterCropping: true,
                  maintainAspectRatio: true,
                  aspectRatio: 1366 / 360,
                  cropperMinWidth: 1366,
                  cropperMinHeight: 360,
                  textLabel:
                    "Esta imagem será exibida em cima da campanha, na tela de leitura. Para que a exibição fique adequada, a proporção do recorte é fixada em 3,79 e a resolução mínima em aprox. 1366px x 360px."
                }
              })
              .onClose.toPromise()
          : null;

      if (croppedImage) {
        if (kindOfFile === "cover-landing" || kindOfFile === "read-image") {
          const compressedFile = await this.compressImage(croppedImage);
          const reader = new FileReader();
          reader.readAsDataURL(compressedFile);
          reader.onload = () => {
            if (kindOfFile === "cover-landing") {
              this.data.file = compressedFile;
              this.data.urlImagem = reader.result.toString();
            } else if (kindOfFile === "read-image") {
              this.data.fotoDetalhe = compressedFile;
              this.data.urlImagemDetalhe = reader.result.toString();
            }
            this.isPhotoLoaded = true;
          };
        }
      } else if (kindOfFile === "piece-file") {
        this.peca.file =
          this.peca.tipo === "1" ? await this.compressImage(file) : null;
      } else {
        this.alert.warning("O recorte da imagem é obrigatório", "Ooops");
      }
    }
    this.selectedImgCover = null;
    this.isPhotoLoaded = true;
  }

  async compressImage(file: any) {
    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      });
      return compressedFile as File;
    } catch (ex) {
      this.alert.error("Houve um problema ao realizar a compressão da imagem");
      console.log(ex);
      return null;
    }
  }

  async removeFile() {
    try {
      const decision: "cancel" | "remove" = await this.nbDialogService
        .open(DecisionAlertModalComponent, {
          autoFocus: false,
          context: { tipo: TipoMensagem.Deletar }
        })
        .onClose.toPromise();
      if (decision === "remove") {
        this.isPhotoLoaded = false;
        this.data.foto = null;
        this.data.urlImagem = "";
        this.data.file = null;
        this.data.fotoExcluida = true;
        this.alert.success("Remoção efetuada com sucesso");
      }
    } catch (ex) {
      this.alert.error(
        "Não foi possível remover a foto da campanha",
        "Ocorreu um problema"
      );
      console.error(ex);
    } finally {
      this.isPhotoLoaded = true;
    }
  }

  isValidForm(form: NgForm) {
    if (
      !this.data.titulo ||
      !this.data.dataInicio ||
      this.richEditor.content === ""
    ) {
      this.alert.warning(
        "É necessário preencher todos os campos obrigatórios, inclusive o corpo da campanha"
      );
      return false;
    } else if (dayjs(this.data.dataInicio).isAfter(dayjs(this.data.dataFim))) {
      this.alert.warning("A data início deve ser menor ou igual a data de fim");
      return false;
    } else {
      return true;
    }
  }

  save(form: NgForm, publishing?: boolean) {
    if (this.isValidForm(form)) {
      this.data.descricao = this.richEditor.content;
      this.isLoading = true;
      try {
        const formData = new FormData();
        formData.append("fotoExcluida", this.data.fotoExcluida + "");
        formData.append("file", this.data.file);
        formData.append("fotoDetalhe", this.data.fotoDetalhe);
        this.data.dataFim?.toJSON() &&
          formData.append("dataFim", this.data.dataFim.toJSON());
        formData.append("dataInicio", this.data.dataInicio.toJSON());
        this.data.dataPublicacao?.toJSON() &&
          formData.append("dataPublicacao", this.data.dataPublicacao.toJSON());
        formData.append("titulo", this.data.titulo);
        formData.append("situacao", this.data.situacao.toString());
        formData.append("empresaId", this.data.empresaId);
        formData.append("descricao", this.data.descricao);
        if (this.data.id) {
          this.putCampaign(formData, publishing);
        } else {
          this.postCampaign(formData);
        }
      } finally {
        this.isLoading = false;
      }
    } else {
      form.resetForm(form.value);
    }
  }

  async salvarPeca(form: NgForm) {
    try {
      this.isSavingPiece = true;
      const { id, descricao, tipo, file } = this.peca;

      if (form.valid && descricao && tipo && file) {
        const formData = new FormData();
        formData.append("tipo", tipo);
        formData.append("descricao", descricao);
        formData.append("file", file);

        const peca = await this.campaignService
          .addFile(formData, this.data.id)
          .toPromise();
        this.alert.success("Registro salvo com sucesso");
        this.outsideData.pecas.push(peca.value);
        this.fetchAndSetInitialData();
      } else {
        this.alert.error("É necessário preencher os campos obrigatórios");
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      this.isSavingPiece = false;
    }
  }

  putCampaign(formData: FormData, publishing: boolean) {
    this.isPublishing = publishing;
    this.subscriptions.add(
      this.campaignService.update(formData, this.data).subscribe(response => {
        this.alert.success(
          publishing && this.data.situacao === 1
            ? "Publicação efetuada com sucesso"
            : publishing && this.data.situacao === 0
            ? "Publicação removida com sucesso"
            : "Registro salvo com sucesso",
          "Sucesso",
          {
            duration: 2000
          }
        );

        if (publishing && this.data.situacao === 1) {
          this.data.dataPublicacao = new Date(
            dayjs().format("YYYY-MM-DD HH:mm:ss")
          );
        }

        this.isPublishing = false;
        if (!publishing) {
          this.close();
        }
      })
    );
  }

  postCampaign(formData: FormData) {
    this.subscriptions.add(
      this.campaignService.save(formData).subscribe(response => {
        const savedCampaign = response.value as Campaign;
        this.data.id = savedCampaign.id;
        this.alert.success("Registro salvo com sucesso.");
        this.isPublishing = false;
      })
    );
  }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  putPostPublish() {
    this.data.situacao = this.textoPublicar === "Publicar Campanha" ? 1 : 0;
    this.textoPublicar =
      this.textoPublicar === "Publicar Campanha"
        ? "Remover Publicação"
        : "Publicar Campanha";
    this.save(null, true);
  }

  updateDateValue(
    type: "firstDate" | "endDate" | "publishDate",
    newValue: Date
  ) {
    switch (type) {
      case "firstDate":
        this.data.dataInicio = newValue;
        break;
      case "endDate":
        this.data.dataFim = newValue;
        break;
      case "publishDate":
        this.data.dataPublicacao = newValue;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
