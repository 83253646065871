import { Component, OnInit } from "@angular/core";
import IPostPutEstacaoMinMax from "src/app/models/IPostPutEstacaoMinMax";
import { Input, AfterContentChecked, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { NbDialogRef, NbTabComponent, NbToastrService } from "@nebular/theme";
import { HelperService } from "src/app/services/helper.service";
import { EstacaoService } from "src/app/services/estacao.service";
import { WebserviceService } from "src/app/services/webservice.service";

@Component({
  selector: "app-min-max-estacao-modal",
  templateUrl: "./min-max-estacao-modal.component.html",
  styleUrls: ["./min-max-estacao-modal.component.scss"]
})
export class MinMaxEstacaoModalComponent implements OnInit {
  @Input() outsideData: any;

  isLoading: boolean;
  subscriptions = new Subscription();
  minMaxRegistered: any = [];
  list: any = [];

  constructor(
    private nbDialogRef: NbDialogRef<MinMaxEstacaoModalComponent>,
    private helperService: HelperService,
    private webService: WebserviceService,
    private estacaoService: EstacaoService,
    private nbToastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.fetchParametro();
  }

  changeCheckMinField(id: string, e: any) {
    let param = this.list.find(p => p.parametroId === id);
    param['parametroMinStatus'] = e.target.checked;
  }

  changeCheckMaxField(id: string, e: any) {
    let param = this.list.find(p => p.parametroId === id);
    param['parametroMaxStatus'] = e.target.checked
  }

  blurMinFieldValue(id: string, e: any) {
    let param = this.list.find(p => p.parametroId === id);
    param['parametroMin'] = e.target.value
  }

  blurMaxFieldValue(id: string, e: any) {
    let param = this.list.find(p => p.parametroId === id);
    param['parametroMax'] = e.target.value
  }

  fetchParametro() {
    this.estacaoService.getMinMaxParamsByClientId(this.outsideData.id).subscribe(({ value }) => {
      this.minMaxRegistered = value;
    });

    this.webService.getParametrosByAntenaIdWebserviceId(this.outsideData.webServiceId, this.outsideData.isn).subscribe(({ value }) => {
      value.parametrosNameResponse.forEach((param: any) => {
        let obj = {
          parametroId: param.id,
          ordem: param.ordem,
          parametro: param.parametro,
          webserviceId: param.webserviceId,
          clienteAntenaId: this.outsideData.id,
          parametroMin: "",
          parametroMax: "",
          parametroMinStatus: false,
          parametroMaxStatus: false,
        }
        
        if (this.minMaxRegistered.length > 0) {
          let register = this.minMaxRegistered.filter(r => r.parametroId === param.id)[0];

          if (register) {
            register['parametro'] = param.parametro;
            this.list.push(register)
          } else {
            this.list.push(obj)
          }
        } else {
          this.list.push(obj)
        }
      })

      this.isLoading = false;
    });
  }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  save() {
    let arrayParamsToSave = [];
    
    this.list.forEach((param: any) => { 
      if (param.parametroMinStatus || param.parametroMaxStatus || param.parametroMin || param.parametroMax) {
        arrayParamsToSave.push(param)
      }
    })
    
    try {
      this.isLoading = true;
      const observable = this.estacaoService.saveMinMax(arrayParamsToSave);

      this.subscriptions.add(observable.subscribe(res => 
        {
          this.nbToastrService.success("Registro salvo com sucesso", "Sucesso", { duration: 2000 });
          this.close();
        },
        err => {
          this.isLoading = false;
          //this.close();
        }
      ));
    } finally {
      this.isLoading = false;
    }
  }
}
