import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { LoginUser } from "../../models/LoginUser";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { CurrentUserService } from "../../services/current-user.service";
import { AlertService } from "src/app/services/alert.service";
import ResponsePOST from "src/app/models/ResponsePOST";
import { NbToastrService, NbDialogService } from "@nebular/theme";
import { Subscription } from "rxjs";
import { TermOfUseComponente } from "src/app/components/term-of-use/term-of-use.component";
import { PasswordAlertModalComponent } from "src/app/components/password-alert/password-alert-modal.component";
import { ModuleService } from "src/app/services/module.service";
import Module from "src/app/models/Module";
import { RespostaPrimeiroAcesso } from "src/app/models/RespostaPrimeiroAcesso";
import zxcvbn from "zxcvbn";
import { IPostLogin } from "src/app/models/Login";
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";

@Component({
  selector: "app-login",
  templateUrl: "./login-modal.component.html",
  styleUrls: ["./login-modal.component.scss"]
})
export class LoginModalComponent implements OnInit, OnDestroy {
  windowWidth: number;
  user = new LoginUser();
  isLoading = false;
  captcha: any;
  submitted: boolean = false;
  emailRecuperarSenha: string;

  recoverForm: FormGroup;

  bgVideo: HTMLVideoElement;
  showForgotPassword: boolean = false;
  showPerguntas: boolean = false;
  showFormNovaSenha: boolean = false;
  showSucesso: boolean = false;
  modules = [] as Module[];
  buttonAudioIcon: "volume-up-outline" | "volume-off-outline" =
    "volume-off-outline";
  showPassword = false;
  showNovaSenha = false;
  showConfirmarNovaSenha = false;
  subscriptions = new Subscription();
  respostasPrimeiroAcesso = [] as RespostaPrimeiroAcesso[];
  senhaTemporaria: string;
  termoId: string;
  qtdImages = 12;
  backgroundImages = new Array<HTMLImageElement>();
  currentImageID = 0;
  urlImageBG = "../../../assets/images/bg-login/1.jpg";

  constructor(
    private router: Router,
    private authService: AuthService,
    private currentUser: CurrentUserService,
    private alert: AlertService,
    private nbToastrService: NbToastrService,
    private nbDialogService: NbDialogService,
    private moduleService: ModuleService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.windowWidth = window.screen.width;
    const accessToken = sessionStorage.getItem("access-token");

    if (accessToken) {
      this.navigateToHome();
    } else {
      this.getCaptcha();
    }

    this.recoverForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]]
    });
  }

  get f() {
    return this.recoverForm.controls;
  }

  onSubmitForgot() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.recoverForm.invalid) {
      return;
    }

    const { email } = this.recoverForm.value;

    this.isLoading = true;
    this.subscriptions.add(
      this.authService.recuperacaoDeSenha({ email: email }).subscribe(res => {
        this.isLoading = false;
        //this.nbDialogService.open(PasswordAlertModalComponent, {
        //  autoFocus: false,
        //  context: { textoSenha: res.value.senhaTemporaria },
        //});
        this.nbToastrService.success(
          "Sua senha temporária foi enviada para o e-mail cadastrado.",
          "Sucesso",
          {
            duration: 5000
          }
        );
        this.showForgotPassword = false;
      })
    );
    this.isLoading = false;
  }
  navigateToHome() {
    const tipoUsuario = this.currentUser.getUserType();
    if (parseInt(tipoUsuario) === 0)
      // administrador
      this.router.navigate(["dashboard"]);
    else this.router.navigate(["antenas"]);
  }

  login() {
    const { email, password, captchaId, resultado } = this.user;
    this.isLoading = true;

    this.subscriptions.add(
      this.authService.logIn(email, password, captchaId, resultado).subscribe(
        async response => {
          await this.handleLoggedUser(response);
        },
        err => {
          this.isLoading = false;
          this.getCaptcha();
        }
      )
    );
  }

  async handleLoggedUser(response: ResponsePOST) {

    this.isLoading = false;
    const loggedUser = response.value as IPostLogin;
    const {
      token,
      authenticated,
      termoUso,
      primeiroLogin,
      message
    } = loggedUser;

    if (authenticated) {
      sessionStorage.setItem("access-token", token.accessToken);
      this.authService.token.next(token.accessToken);
      localStorage.setItem("refresh-token", token.refreshToken);

      const { tipoUsuario } = await this.currentUser.getUser();

      localStorage.setItem("isJustOneCompany", "true");
      localStorage.setItem("defaultCompanyId", this.authService.decode()?.sid);

      this.authService.loggedIn.next(true);
      // Obtendo módulos
      this.moduleService.fetch().subscribe(resp => {
        this.modules = resp.value as Module[];
        localStorage.setItem("modulos", JSON.stringify(this.modules));
      });

      localStorage.setItem("termo", termoUso.toString());
      localStorage.setItem("firstAccess", primeiroLogin.toString());

      switch (tipoUsuario) {
        case 0: //administrador
          this.router.navigate(["dashboard"]);
          break;
        case 1: //cliente
          this.router.navigate(["antenas"]);
          break;
      }
    } else {
      this.isLoading = false;
      this.alert.warning(message);
      this.getCaptcha();
    }
  }

  handleForgotPasswordScreen() {
    this.showForgotPassword = !this.showForgotPassword;
    this.clear();
    this.getCaptcha();
  }

  sendNewPassword() {
    this.isLoading = true;
    this.subscriptions.add(
      this.authService
        .changePassword({ userName: this.user.email })
        .subscribe(res => {
          this.isLoading = false;
          this.nbDialogService.open(PasswordAlertModalComponent, {
            autoFocus: false,
            context: { textoSenha: res.value.senhaTemporaria }
          });
          this.nbToastrService.success(
            "Sua senha temporária também foi enviada para o e-mail cadastrado.",
            "Sucesso",
            {
              duration: 3000
            }
          );
          this.showForgotPassword = false;
        })
    );
    this.isLoading = false;
  }

  validateSenha(event: any) {
    if (event.target.value !== "") {
      let score = zxcvbn(event.target.value).score;

      switch (score) {
        case 0:
          this.alert.info("A senha informada é muito fraca.");
          return;
        case 1:
          this.alert.info("A senha informada é fraca.");
          return;
        case 2:
          this.alert.info("A senha informada é média.");
          return;
        case 3:
          this.alert.info("A senha informada é forte.");
          return;
        case 4:
          this.alert.info("A senha informada é muito forte.");
          return;
      }
    }
  }

  voltarLogin() {
    this.showForgotPassword = false;
    this.showPerguntas = false;
    this.showFormNovaSenha = false;
    this.showSucesso = false;
    this.clear();
    this.getCaptcha();
  }

  clear() {
    this.user.matricula = "";
    this.user.cpf = "";
    this.user.resultado = null;
    this.user.novaSenha = "";
    this.user.confirmarNovaSenha = "";

    if (this.respostasPrimeiroAcesso.length > 0) {
      this.respostasPrimeiroAcesso = [] as RespostaPrimeiroAcesso[];
    }
  }

  async getCaptcha() {
    // this.user.resultado = null;
    // this.subscriptions.add(
    //   this.authService.getCaptcha("teste-ip").subscribe(({ value }) => {
    //     this.captcha = value;
    //     this.user.captchaId = value.id;
    //   })
    // );
  }

  getInputType() {
    if (this.showPassword) {
      return "text";
    }
    return "password";
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  toggleShowNovaSenha() {
    this.showNovaSenha = !this.showNovaSenha;
  }

  toggleShowConfirmarNovaSenha() {
    this.showConfirmarNovaSenha = !this.showConfirmarNovaSenha;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
  }
}
