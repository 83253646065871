import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FavoriteService } from "src/app/services/favorite.service";
import { RouteSearchable } from "src/app/models/Route";
import Response from "src/app/models/ResponseGET";
import { AlertService } from "src/app/services/alert.service";
import { FavoriteGET } from "src/app/models/Favorite";
import { Subscription } from "rxjs";

@Component({
  selector: "app-favorite",
  templateUrl: "./favorite.component.html",
  styleUrls: ["./favorite.component.scss"]
})
export class FavoriteComponent implements OnInit {
  isFavoritedPage = false;
  favoritou = 0;
  currentRouteConfig: RouteSearchable;
  subscriptions = new Subscription();

  constructor(
    private favoriteService: FavoriteService,
    private route: ActivatedRoute,
    private alert: AlertService
  ) {}

  ngOnInit() {
    this.getCurrentRoute();
    this.getIsFavoritedPage();
  }

  getCurrentRoute() {
    //this.currentRouteConfig = this.route.snapshot.parent.routeConfig;
  }

  getIsFavoritedPage() {
    //this.subscriptions.add(
    //  this.favoriteService.sharedFavoritedPages.subscribe((favoritedPages) => {
    //    this.isFavoritedPage = favoritedPages.some(
    //      (item) => item.pagina === this.currentRouteConfig.path,
    //    );
    //  }),
    //);
  }

  postFavorited() {
    //this.favoritou++;
    //
    //if (this.favoritou <= 1) {
    //  this.subscriptions.add(
    //    this.favoriteService
    //      .post({
    //        nome: this.currentRouteConfig.title,
    //        pagina: this.currentRouteConfig.path,
    //      })
    //      .subscribe((response: Response) => {
    //        this.favoriteService.addFavoritedPage(
    //          response.value as FavoriteGET,
    //        );
    //        this.isFavoritedPage = true;
    //        this.alert.success('Página favoritada com sucesso.');
    //      }),
    //  );
    //}
  }

  postUnfavorited() {
    //this.favoritou--;
    //const favorite = this.favoriteService.getByRoutePath(
    //  this.currentRouteConfig.path,
    //);
    //if (favorite) {
    //  this.subscriptions.add(
    //    this.favoriteService
    //      .delete(favorite.id)
    //      .subscribe((response: Response) => {
    //        this.favoriteService.removeFavoritedPage(favorite.id);
    //        this.isFavoritedPage = false;
    //        this.alert.success('A página foi removida de seus favoritos.');
    //      }),
    //  );
    //}
  }
}
