import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NbDialogRef, NbTabComponent, NbToastrService } from "@nebular/theme";
import { Subscription } from "rxjs";
import GetAntenaCameras from "src/app/models/AntenaCameras";
import { AntenaService } from "src/app/services/antena.service";
import { HelperService } from "src/app/services/helper.service";
import { AppConfig } from "src/config/app.config";
import { ModalViewPositionComponent } from "../modal-view-position/modal-view-position.component";

interface Horarios {
  HoraDomingo: string[];
  HoraSegunda: string[];
  HoraTerca: string[];
  HoraQuarta: string[];
  HoraQuinta: string[];
  HoraSexta: string[];
  HoraSabado: string[];
}

@Component({
  selector: "app-modal-view-cameras",
  templateUrl: "./modal-view-cameras.component.html",
  styleUrls: ["./modal-view-cameras.component.scss"]
})
export class ModalViewCamerasComponent implements OnInit {
  @Input() isn: string;
  clienteId: string;
  dataHora = new Date();
  horario = "";
  lista_horarios: string[];

  data: GetAntenaCameras = {
    isn: "",
    dataHora: "",
    camera1: "", //"https://via.placeholder.com/320x240",
    camera2: "", //"https://via.placeholder.com/320x240",
    camera3: "" //"https://via.placeholder.com/320x240"
  };

  //Configurações dos horários
  horarios: Horarios = {
    HoraDomingo: [],
    HoraSegunda: [],
    HoraTerca: [],
    HoraQuarta: [],
    HoraQuinta: [],
    HoraSexta: [],
    HoraSabado: []
  };

  selectedTab: string = "Imagens"; // 'Cadastro de cliente';
  isLoading = false;
  subscriptions = new Subscription();

  //${AppConfig.settings.urlApi}

  constructor(
    private nbDialogRef: NbDialogRef<ModalViewPositionComponent>,
    private helperService: HelperService,
    public sanitizer: DomSanitizer,
    private antenaService: AntenaService,
    private nbToastrService: NbToastrService
  ) {}

  ngOnInit() {
    const clientes = localStorage.getItem("user-clients");
    const dadosCliente = clientes ? JSON.parse(atob(clientes)) : null;
    if (dadosCliente) {
      this.clienteId = dadosCliente.id;
    }

    this.dataTimeTextChange(this.dataHora);
    this.fetchHorarios();
    //this.searchImages();

    this.data = {
      isn: this.isn,
      dataHora: "",
      camera1: "", //"https://via.placeholder.com/320x240",
      camera2: "", //"https://via.placeholder.com/320x240",
      camera3: "" //"https://via.placeholder.com/320x240"
    };
  }

  searchImages() {
    if (!this.horario) {
      this.nbToastrService.warning("Informe o horário!", "Atenção", {
        duration: 10000
      });
      return false;
    }

    this.isLoading = true;

    this.data = {
      isn: this.isn,
      dataHora: "",
      camera1: "",
      camera2: "",
      camera3: ""
    };

    if (this.clienteId !== null) {
      const clienteId = this.clienteId;

      let hora_int: number = parseInt(this.horario.replace(":00", ""));

      let data =
        this.dataHora.getFullYear() +
        "-" +
        (this.dataHora.getMonth() + 1) +
        "-" +
        this.dataHora.getDate() +
        " " +
        (hora_int + ":00:00");

      this.subscriptions.add(
        this.antenaService
          .getImagens(clienteId, data.toString(), this.isn)
          .subscribe(response => {
            const { value } = response;
            if (value) {
              var urlApi = AppConfig.settings.urlApi;

              this.data = {
                isn: value.isn,
                dataHora: value.dataHora,
                camera1: `${urlApi}/ClienteAntenaImagem/arquivo/${value.id}?cameras=Camera1`,
                camera2: `${urlApi}/ClienteAntenaImagem/arquivo/${value.id}?cameras=Camera2`,
                camera3: `${urlApi}/ClienteAntenaImagem/arquivo/${value.id}?cameras=Camera3`
              };
            }
          })
      );
    }
    this.isLoading = false;
  }

  dataTimeTextChange(data: Date) {
    var data_formatada =
      data.getFullYear() + "-" + (data.getMonth() + 1) + "/" + data.getDate();

    this.lista_horarios = [];
    this.horario = "";
    this.subscriptions.add(
      this.antenaService
        .getHorarios(this.clienteId, this.isn, data_formatada)
        .subscribe(response => {
          const { value, hasError, errors } = response;
          if (value) {
            this.lista_horarios = value.horarios;
          }

          if (hasError) {
            this.nbToastrService.warning(errors[0], "Atenção", {
              duration: 2000
            });
          }
        })
    );
  }

  solicitarImagens() {
    this.isLoading = true;

    if (this.clienteId !== null) {
      this.subscriptions.add(
        this.antenaService
          .solicitacaoAvulsa({ clienteId: this.clienteId, isn: this.isn })
          .subscribe(response => {
            const { value } = response;
            if (value) {
              this.nbToastrService.success(
                "Solicitação processada com sucesso!\nA captura das imagens irá iniciar em " +
                  value.dataAberturaPorta,
                "Sucesso",
                {
                  duration: 10000
                }
              );
            }
          })
      );
    }

    this.isLoading = false;
  }

  fetchHorarios() {
    this.isLoading = true;

    if (this.clienteId !== null) {
      const clienteId = this.clienteId;

      this.subscriptions.add(
        this.antenaService
          .getClienteAntenaHorario(clienteId, this.isn)
          .subscribe(response => {
            const { value } = response;
            if (value) {
              this.horarios = {
                HoraDomingo: value.horaDomingo ? value.horaDomingo : "",
                HoraSegunda: value.horaSegunda ? value.horaSegunda : "",
                HoraTerca: value.horaTerca ? value.horaTerca : "",
                HoraQuarta: value.horaQuarta ? value.horaQuarta : "",
                HoraQuinta: value.horaQuinta ? value.horaQuinta : "",
                HoraSexta: value.horaSexta ? value.horaSexta : "",
                HoraSabado: value.horaSabado ? value.horaSabado : ""
              };
            }

            this.isLoading = false;
          })
      );
    } else {
      this.isLoading = false;
    }
  }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  tabChanged(currentTab: NbTabComponent) {
    this.selectedTab = currentTab.tabTitle;
  }

  putOrPostHorario() {
    try {
      this.isLoading = true;

      if (this.clienteId) {
        const data = {
          clienteId: this.clienteId,
          isn: this.isn,
          horaDomingo:
            this.horarios.HoraDomingo.length > 0
              ? this.horarios.HoraDomingo
              : null,
          horaSegunda:
            this.horarios.HoraSegunda.length > 0
              ? this.horarios.HoraSegunda
              : null,
          horaTerca:
            this.horarios.HoraTerca.length > 0 ? this.horarios.HoraTerca : null,
          horaQuarta:
            this.horarios.HoraQuarta.length > 0
              ? this.horarios.HoraQuarta
              : null,
          horaQuinta:
            this.horarios.HoraQuinta.length > 0
              ? this.horarios.HoraQuinta
              : null,
          horaSexta:
            this.horarios.HoraSexta.length > 0 ? this.horarios.HoraSexta : null,
          horaSabado:
            this.horarios.HoraSabado.length > 0
              ? this.horarios.HoraSabado
              : null
        };

        const observable = this.antenaService.saveClienteAntenaHorario(data);

        this.subscriptions.add(
          observable.subscribe(
            res => {
              this.nbToastrService.success(
                "Configurações guardadas com sucesso",
                "Sucesso",
                {
                  duration: 2000
                }
              );

              //this.close();
            },
            err => {
              this.isLoading = false;
            }
          )
        );
      }
    } finally {
      this.isLoading = false;
    }
  }

  save() {
    this.putOrPostHorario();
  }
}
