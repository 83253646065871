import { NgModule } from "@angular/core";
import { DatatableComponent } from "./datatable.component";
import { CommonModule } from "@angular/common";
import { Ng2SmartTableModule } from "ng2-smart-table";
import {
  NbCardModule,
  NbInputModule,
  NbCheckboxModule,
  NbSpinnerModule,
  NbSelectModule,
  NbIconModule,
  NbFormFieldModule,
  NbTooltipModule
} from "@nebular/theme";
import { FormsModule } from "@angular/forms";
import { ActionsDatatableModule } from "../actions-datatable/actions-datatable.module";

@NgModule({
  declarations: [DatatableComponent],
  entryComponents: [],
  imports: [
    NbFormFieldModule,
    CommonModule,
    FormsModule,
    Ng2SmartTableModule,
    NbCardModule,
    NbInputModule,
    NbCheckboxModule,
    NbSpinnerModule,
    NbSelectModule,
    NbIconModule,
    NbTooltipModule,
    ActionsDatatableModule
  ],
  exports: [DatatableComponent]
})
export class DatatableModule {}
