import { NgModule } from "@angular/core";
import { SidebarComponent } from "./sidebar.component";
import { NbSidebarModule, NbMenuModule, NbCardModule } from "@nebular/theme";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [SidebarComponent],
  imports: [CommonModule, NbSidebarModule, NbCardModule, NbMenuModule],
  exports: [SidebarComponent]
})
export class SidebarModule {}
