import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { LoginUser } from "../models/LoginUser";
import { JwtHelperService } from "@auth0/angular-jwt";
import * as jwt_decode from "jwt-decode";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { AppConfig } from "src/config/app.config";
import Response from "../models/ResponseGET";
import { IPostLogin } from "../models/Login";
import ResponsePOST from "../models/ResponsePOST";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  token = new BehaviorSubject<string>(null);
  loggedIn = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    public jwtHelper: JwtHelperService,
    private router: Router
  ) {
    this.token.next(this.getToken());
    this.loggedIn.next(this.getToken() ? true : false);
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get getTokenState() {
    return this.token.asObservable();
  }

  getToken(): string {
    return sessionStorage.getItem("access-token");
  }

  getIsJustOneCompany(): boolean {
    return localStorage.getItem("isJustOneCompany") === "true";
  }

  getDefaultCompanyId(): string {
    return localStorage.getItem("defaultCompanyId") || "";
  }

  logIn(
    email: string,
    password: string,
    captchaId?: string,
    resultado?: number
  ) {
    return this.http.post<ResponsePOST>(`${AppConfig.settings.urlApi}/Login`, {
      userName: email,
      password,
      captchaId,
      resultado
    });
  }

  signUp(email: string, password: string): Observable<LoginUser> {
    return this.http.post<LoginUser>(`${AppConfig.settings.urlApi}/register`, {
      email,
      password
    });
  }

  logout(refreshPage = false) {
    localStorage.clear();
    sessionStorage.clear();

    //window.location.reload();
    setTimeout(() => {
      //window.location.reload();
      this.router.navigate(["/home"]);
      this.loggedIn.next(false);
    }, 1000);
    setTimeout(() => {
      if (refreshPage) {
        window.location.reload();
      }
    }, 2000);
  }

  decode() {
    let token = { sid: "", id: "" };
    if (this.getToken()) {
      token = jwt_decode(this.getToken());
      return token;
    }
    return token;
  }

  changePassword(params: any) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/Login/senha/resetar`,
      params
    );
  }

  getIp() {
    return this.http.get<any>("http://api.ipify.org/?format=json");
  }

  getCaptcha(ip: string) {
    return this.http.post<any>(`${AppConfig.settings.urlApi}/Login/captcha`, {
      ip
    });
  }

  registerOperator() {
    // return this.http.post<any>(`${AppConfig.settings.urlApi}`)
  }

  refreshToken() {
    const { id } = this.decode();
    const refreshToken = localStorage.getItem("refresh-token");

    return this.http.post<Response<IPostLogin>>(
      `${AppConfig.settings.urlApi}/Login`,
      {
        userName: id,
        password: refreshToken,
        grantTypes: "refresh_token",
        captchaId: "b2a9138d-462e-414d-9e77-0ddf3269a9aa",
        resultado: 10
      }
    );
  }

  getTermoUso() {
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/Termo/termo-uso`
    );
  }

  getTermoUsoCpf(cpf: string) {
    return this.http.get<Response>(
      `${AppConfig.settings.urlApi}/Termo/termo-uso/` + cpf
    );
  }

  acceptTermoUso(id: string) {
    return this.http.post(
      `${AppConfig.settings.urlApi}/Termo/${id}/aceitar`,
      null
    );
  }

  sendPrimeiroAcesso(params: any) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/Login/primeiro-acesso`,
      params
    );
  }

  sendValidarPrimeiroAcesso(params: any) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/Login/primeiro-acesso/validar`,
      params
    );
  }

  sendAlterarSenha(params: any) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/Login/senha/alterar`,
      params
    );
  }

  recuperacaoDeSenha(params: any) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/Login/recuperacao-senha`,
      params
    );
  }
}
