export interface PermissionToAdd {
  id: string;
  nome: string;
  descricao: string;
  check?: boolean;
}

export interface PermissionAdded {
  id: string;
  permissao: PermissionToAdd;
  check?: boolean;
}

export enum Permissions {
  ConsultarEstadoCivil = "ConsultarEstadoCivil",
  ConsultarEnumPermissao = "ConsultarEnumPermissao",
  CadastrarUsuario = "CadastrarUsuario",
  AtualizarUsuario = "AtualizarUsuario",
  DeletarUsuario = "DeletarUsuario",
  ConsultarUsuario = "ConsultarUsuario",
  ResetarSenhaUsuario = "ResetarSenhaUsuario",
  DesbloquearUsuario = "DesbloquearUsuario",
  CadastrarGrupo = "CadastrarGrupo",
  AtualizarGrupo = "AtualizarGrupo",
  DeletarGrupo = "DeletarGrupo",
  ConsultarGrupo = "ConsultarGrupo",
  CadastrarPermissaoGrupo = "CadastrarPermissaoGrupo",
  DeletarPermissaoGrupo = "DeletarPermissaoGrupo",
  ConsultarPermissoesGrupo = "ConsultarPermissoesGrupo",
  CadastrarPermissaoUsuario = "CadastrarPermissaoUsuario",
  DeletarPermissaoUsuario = "DeletarPermissaoUsuario",
  ConsultarPermissoesUsuario = "ConsultarPermissoesUsuario",
  CadastrarEmpresa = "CadastrarEmpresa",
  AtualizarEmpresa = "AtualizarEmpresa",
  DeletarEmpresa = "DeletarEmpresa",
  ConsultarEmpresa = "ConsultarEmpresa",
  CadastrarTermoUso = "CadastrarTermoUso",
  AtualizarTermoUso = "AtualizarTermoUso",
  DeletarTermoUso = "DeletarTermoUso",
  ConsultarTermoUso = "ConsultarTermoUso",
  ConsultarNoticia = "ConsultarNoticia",
  CadastrarNoticia = "CadastrarNoticia",
  AtualizarNoticia = "AtualizarNoticia",
  DeletarNoticia = "DeletarNoticia",
  CadastrarNotificacao = "CadastrarNotificacao",
  DeletarNotificacao = "DeletarNotificacao",
  CadastrarCampanha = "CadastrarCampanha",
  ConsultarCampanha = "ConsultarCampanha",
  AtualizarCampanha = "AtualizarCampanha",
  DeletarCampanha = "DeletarCampanha",
  ConsultarFuncionario = "ConsultarFuncionario",
  ImportarFuncionario = "ImportarFuncionario",
  FuncionarioAbaDadosPessoais = "Funcionario-Aba-DadosPessoais",
  FuncionarioAbaDadosFuncionais = "Funcionario-Aba-DadosFuncionais",
  FuncionarioAbaAusencias = "Funcionario-Aba-Ausencias",
  FuncionarioAbaDocumentos = "Funcionario-Aba-Documentos",
  FuncionarioAbaRemuneracoes = "Funcionario-Aba-Remuneracoes",
  FuncionarioAbaSegMedTrabalho = "Funcionario-Aba-SegMedTrabalho",
  ExibirPortal = "ExibirPortal",
  ConsultarJustificativaEntrega = "ConsultarJustificativaEntrega",
  CadastrarJustificativaEntrega = "CadastrarJustificativaEntrega",
  AtualizarJustificativaEntrega = "AtualizarJustificativaEntrega",
  AtivarDesativarJustificativaEntrega = "AtivarDesativarJustificativaEntrega",
  DeletarJustificativaEntrega = "DeletarJustificativaEntrega",
  ConsultarMotivoDevolucao = "ConsultarMotivoDevolucao",
  CadastrarMotivoDevolucao = "CadastrarMotivoDevolucao",
  AtualizarMotivoDevolucao = "AtualizarMotivoDevolucao",
  AtivarDesativarMotivoDevolucao = "AtivarDesativarMotivoDevolucao",
  DeletarMotivoDevolucao = "DeletarMotivoDevolucao",
  ConsultarMaterial = "ConsultarMaterial",
  ConsultarFuncionarioCentroCusto = "ConsultarFuncionarioCentroCusto",
  AtualizarFuncionarioCentroCusto = "AtualizarFuncionarioCentroCusto",
  CadastrarFotoMaterial = "CadastrarFotoMaterial",
  ConsultarFotoMaterial = "ConsultarFotoMaterial",
  AtualizarFotoMaterial = "AtualizarFotoMaterial",
  DeletarFotoMaterial = "DeletarFotoMaterial",
  AtualizarItemChecklist = "AtualizarItemChecklist",
  DeletarItemChecklist = "DeletarItemChecklist",
  CadastrarItemChecklist = "CadastrarItemChecklist",
  ConsultarItemChecklist = "ConsultarItemChecklist",
  ConsultarVinculoFuncionario = "ConsultarVinculoFuncionario",
  CadastrarVinculoFuncionario = "CadastrarVinculoFuncionario",
  AtualizarVinculoFuncionario = "AtualizarVinculoFuncionario",
  AtualizarChecklist = "AtualizarChecklist",
  DeletarChecklist = "DeletarChecklist",
  CadastrarChecklist = "CadastrarChecklist",
  ConsultarChecklist = "ConsultarChecklist",

  ConsultarEstacao = "ConsultarEstacao",
  CadastrarEstacao = "CadastrarEstacao",
  AtualizarEstacao = "AtualizarEstacao",
  ConfigurarEstacao = "ConfigurarEstacao",
  DeletarEstacao = "DeletarEstacao",

  CadastrarCliente = "CadastrarCliente",
  ConsultarCliente = "ConsultarCliente",
  AtualizarCliente = "AtualizarCliente",
  DeletarCliente = "DeletarCliente",

  CadastrarBoia = "CadastrarBoia",
  ConsultarBoia = "ConsultarBoia",
  AtualizarBoia = "AtualizarBoia",
  DeletarBoia = "DeletarBoia",

  VerPosicaoAntena = "VerPosicaoAntena",
  VerFotoAntena = "VerFotoAntena",
  
  ConfigurarWebServiceIncluir = "ConfigurarWebServiceIncluir",
  ConfigurarWebServiceEditar = "ConfigurarWebServiceEditar",
  ConfigurarWebServicePesquisar = "ConfigurarWebServicePesquisar",

  HistoricoDeDadosExportar = "HistoricoDeDadosExportar",
  HistoricoDeDadosPesquisar = "HistoricoDeDadosPesquisar",
  
  ConfigurarParametroIncluir = "ConfigurarParametroIncluir",
  ConfigurarParametroPesquisar = "ConfigurarParametroPesquisar",
  ConfigurarParametroEditar = "ConfigurarParametroEditar",
}
