import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "src/config/app.config";

interface SavePermissionBody {
  permissaoId: string;
  grupoId: string;
}

@Injectable({
  providedIn: "root"
})
export class GroupService {
  private BASE_URL = `${AppConfig.settings.urlApi}`;
  constructor(private http: HttpClient) {}

  fetch(companyId: string) {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/Grupo?EmpresaId=${companyId}`
    );
  }

  save(params) {
    return this.http.post<any>(`${AppConfig.settings.urlApi}/Grupo`, params);
  }

  update(id, params) {
    return this.http.put<any>(
      `${AppConfig.settings.urlApi}/Grupo/${id}`,
      params
    );
  }

  deleteGroup(id: string) {
    return this.http.delete<any>(`${AppConfig.settings.urlApi}/Grupo/RemoverBase/${id}`);
  }

  activeDisableGroup(id: string) {
    return this.http.delete<any>(
      `${AppConfig.settings.urlApi}/Grupo/ativarDesativar/${id}`
    );
  }

  fetchGroupPermissions(id) {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/PermissaoGrupo?grupoId=${id}`
    );
  }

  fetchPermissions(grupoId: string) {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/PermissaoGrupo?GrupoId=${grupoId}`
    );
  }

  savePermission(params: SavePermissionBody) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/PermissaoGrupo`,
      params
    );
  }

  deletePermission(id: string) {
    return this.http.delete<any>(
      `${AppConfig.settings.urlApi}/PermissaoGrupo/${id}`
    );
  }
}
