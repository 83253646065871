import {
  Input,
  AfterContentChecked,
  OnDestroy,
  Component,
  OnInit
} from "@angular/core";

import GetBoia from "src/app/models/Boia";
import { ClienteService } from "src/app/services/cliente.service";
import { CurrentUserService } from "src/app/services/current-user.service";
import { HelperService } from "src/app/services/helper.service";
import { NbDialogRef, NbDialogService, NbToastrService } from "@nebular/theme";
import IPostPutBoia from "src/app/models/PostPutBoia";
import { Subscription } from "rxjs";

@Component({
  selector: "app-new-edit-boia-modal",
  templateUrl: "./new-edit-boia-modal.component.html",
  styleUrls: ["./new-edit-boia-modal.component.scss"]
})
export class NewEditBoiaModalComponent
  implements OnInit, AfterContentChecked, OnDestroy {
  @Input() outsideData: GetBoia;

  isLoading: boolean;
  subscriptions = new Subscription();
  showCurrentPassword = false;

  data: IPostPutBoia = {
    id: "",
    nome: "",
    loginEquipamento: "",
    senhaEquipamento: ""
  };

  constructor(
    public uService: CurrentUserService,
    private nbDialogRef: NbDialogRef<NewEditBoiaModalComponent>,
    private helperService: HelperService
  ) //private nbDialogService: NbDialogService,
  //private nbToastrService: NbToastrService,
  {}

  ngOnInit() {
    this.isLoading = this.outsideData ? true : false;
  }

  ngAfterContentChecked() {
    this.setValuesOnFields();
  }

  toggleShowPassword() {
    this.showCurrentPassword = !this.showCurrentPassword;
  }

  setValuesOnFields() {
    if (this.outsideData && this.isLoading) {
      this.data = {
        id: this.outsideData.id,
        nome: this.outsideData.nome,
        loginEquipamento: this.outsideData.loginEquipamento,
        senhaEquipamento: this.outsideData.senhaEquipamento
      };
    } else {
      this.data = {
        id: "",
        nome: "",
        loginEquipamento: "",
        senhaEquipamento: ""
      };
    }
    this.isLoading = false;
  }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  save() {
    alert("ta salvando");
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
