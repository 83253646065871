import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import Response from "../models/ResponseGET";
import { AppConfig } from "src/config/app.config";

@Injectable({
  providedIn: "root"
})
export class AntenaService {
  private BASE_URL = `${AppConfig.settings.urlApi}`;
  constructor(private http: HttpClient) {}

  fetch(clienteId: string) {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/Cliente/${clienteId}/antena`
    );
  }

  getPosicao(clienteId: string, isn: string, posicaoAtual: boolean) {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/Cliente/${clienteId}/antena/posicao?isn=${isn}&posicaoAtual=${posicaoAtual}`
    );
  }

  getClienteAntenaHorario(clienteId: string, isn: string) {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/ClienteAntenaHorario?clienteId=${clienteId}&iSN=${isn}`
    );
  }

  saveClienteAntenaHorario(params: any) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/ClienteAntenaHorario`,
      params
    );
  }

  getImagens(clienteId: string, dataHora: string, isn: string) {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/ClienteAntenaImagem?clienteId=${clienteId}&iSN=${isn}&dataHora=${dataHora}`
    );
  }

  solicitacaoAvulsa(params: any) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/ClienteAntenaImagem/solicitacao-avulsa`,
      params
    );
  }

  getHorarios(clienteId: string, isn: string, data: string) {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/ClienteAntenaImagem/obter-horarios?clienteId=${clienteId}&iSN=${isn}&data=${data}`
    );
  }
}
