import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import FilterJustificativaEntrega from "../models/FilterJustificativaEntrega";
import { AppConfig } from "src/config/app.config";

@Injectable({
  providedIn: "root"
})
export class JustificativaEntregaService {
  id: any;

  private BASE_URL = `${environment.BASE_URL}`;
  constructor(private http: HttpClient) {}

  fetch(page: number, rowsPerPage: number) {
    return this.http.get<any>(
      `${AppConfig.settings.urlApi}/JustificativaEntrega?take=${rowsPerPage}&page=${page}&sortingProp=descricao`
    );
  }

  save(params) {
    return this.http.post<any>(
      `${AppConfig.settings.urlApi}/JustificativaEntrega`,
      params
    );
  }

  update(id, params) {
    return this.http.put<any>(
      `${AppConfig.settings.urlApi}/JustificativaEntrega/${id}`,
      params
    );
  }

  search(
    filterJustificativaEntrega: FilterJustificativaEntrega,
    page: number,
    rowsPerPage: number
  ) {
    return this.http.get(`${AppConfig.settings.urlApi}/JustificativaEntrega`, {
      params: {
        descricao: `${filterJustificativaEntrega.descricao}`,
        ativo: `${filterJustificativaEntrega.ativo}`,
        take: rowsPerPage?.toString(),
        page: page?.toString()
      }
    });
  }

  ativarDesativar(id, params) {
    return this.http.put<any>(
      `${AppConfig.settings.urlApi}/JustificativaEntrega/ativarDesativar/${id}`,
      params
    );
  }

  delete(id, params) {
    return this.http.put<any>(
      `${AppConfig.settings.urlApi}/JustificativaEntrega/deletar/${id}`,
      params
    );
  }
}
