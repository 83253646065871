import { Component, OnDestroy, OnInit } from "@angular/core";
import { result } from "lodash";
import { Subscription } from "rxjs";
import { ColumnObject } from "src/app/models/DataTable";
import { TableBase } from "src/app/models/PagesComponents";
import { Permissions } from "src/app/models/Permissions";
import { CurrentUserService } from "src/app/services/current-user.service";
import { EstacaoService } from "src/app/services/estacao.service";
import { WebserviceService } from "src/app/services/webservice.service";
import { format } from "date-fns";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import * as FileSaver from "file-saver";
@Component({
  selector: "app-historico-estacao",
  templateUrl: "./historico-estacao.component.html",
  styleUrls: ["./historico-estacao.component.scss"]
})
export class HistoricoEstacaoComponent implements OnInit, TableBase {
  retornoKeys = [];
  separador: string;
  hasErrorHoraInicial: boolean = false;
  hasErrorHoraFinal: boolean = false;
  hasErrorDataInicial: boolean = false;
  hasErrorDataFinal: boolean = false;
  noParameterMsg: boolean = false;
  selectedPage: number = 1;
  rowsPerPage: number = 10;
  totalRecords: number;
  subscriptions = new Subscription();
  data = [] as any[];
  isLoaded = false;
  dataExcel = [];

  columns: ColumnObject = {
    data: {
      title: "Data",
      sort: true,
      sortDirection: "asc"
    },
    webService: {
      title: "Web Service",
      sort: false,
      sortDirection: "asc"
    },
    estacao: {
      title: "Estação",
      sort: false,
      sortDirection: "asc"
    },
    horario: {
      title: "Horário",
      sort: false,
      sortDirection: "asc"
    },
    parametro: {
      title: "Parâmetros",
      sort: false,
      sortDirection: "asc"
    },
    dados: {
      title: "Dados",
      sort: false,
      sortDirection: "asc"
    }
  };

  filterEstacao = {
    dataInicial: "",
    dataFinal: "",
    horaFinal: "",
    horaInicial: "",
    estacao: "",
    webservice: "",
    page: this.selectedPage?.toString(),
    take: this.rowsPerPage?.toString()
  };
  webServiceList: any;
  estacaoList: any;
  constructor(
    public uService: CurrentUserService,
    public webService: WebserviceService,
    public estacaoService: EstacaoService
  ) {}
  companyId: string;
  isAlive?: boolean;
  permissions = this.uService.getEnumPermissions();

  handleRawData?(...args: any[]) {
    throw new Error("Method not implemented.");
  }

  createRefreshDataWatcher(...args: any[]): void {
    this.subscriptions.add(this.refreshData(""));
  }

  ngOnInit() {
    this.fetchWebservice();
    this.createRefreshDataWatcher();
  }

  fetch() {
    this.OnSearch();
  }

  changePage(selectedPage: number) {
    this.selectedPage = selectedPage || this.selectedPage;
    this.fetch();
  }
  changeRowsPerPage(selectedRowsPerPage: number) {
    this.rowsPerPage = selectedRowsPerPage || this.rowsPerPage;
    this.fetch();
  }
  refreshData(evt) {
    this.fetch();
  }

  fetchWebservice() {
    this.subscriptions.add(
      this.webService.fetch().subscribe((response: any) => {
        this.webServiceList = response.value;
      })
    );
  }
  onWebServiceChange(evt) {
    const param = +this.filterEstacao.webservice;
    this.subscriptions.add(
      this.estacaoService.getAntenaByWebService(param).subscribe(result => {
        this.estacaoList = result.value.antenas;
      })
    );
  }

  OnSearch() {
    this.noParameterMsg = false;
    const {
      dataInicial,
      dataFinal,
      horaInicial,
      horaFinal,
      webservice,
      estacao
    } = this.filterEstacao;
    if (!dataInicial && !webservice && !estacao) {
      return false;
    }
    const inicio = format(new Date(dataInicial), "dd/MM/yyyy");

    let fim = "";
    if (dataFinal) {
      fim = format(new Date(dataFinal), "dd/MM/yyyy");
    }

    this.webService.getParametrosByAntenaIdWebserviceId(parseInt(webservice), estacao).subscribe(response => {
      this.separador = response.value.separador;
      if (response.value.parametrosNameResponse.length === 0) {
        this.noParameterMsg = true
        return
      } else {
        this.retornoKeys = response.value.parametrosNameResponse;
        this.noParameterMsg = false
      }
    })

    this.estacaoService.getHistoricoAntena(inicio, fim, horaInicial, horaFinal, webservice, estacao).subscribe(data => {
        this.dataExcel = data.value;
        let lista = [];
        for (const item of data.value) {
          const newString = item.stringRetorno[0].split(this.separador);

          this.retornoKeys.forEach((param: any, index: number) => {
            let newRetorn = {
              data: item.data,
              horario: newString[4] + ":" + newString[5],
              webService: item.webService,
              estacao: item.estacao,
              parametro: param.parametroNome,
              dados: newString[index]
            };
            lista.push(newRetorn);
          })
        }

        this.data = lista;
        this.isLoaded = true;
      });
  }

  onExportTxt() {
    let blob = new Blob([JSON.stringify(this.data)], {
      type: "application/json"
    });
    FileSaver.saveAs(
      blob,
      new Date().getTime() + "_exportHistoricoDeDados.txt"
    );
  }

  onExportExcel() {
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet("Exportação - Histórico de Dados");
    let header = [
      "Data",
      "Web Service",
      "Estação",
      "Horário"
    ];

    this.retornoKeys.forEach(item => {
      header.push(item.parametroNome);
    });

    worksheet.addRow(header);

    this.dataExcel.forEach(item => {
      const newString = item.stringRetorno[0].split(this.separador);
      let informacoes = [
        item.data,
        item.webService,
        item.estacao,
        newString[4] + ":" + newString[5]
      ]

      newString.forEach(d => informacoes.push(d))
      worksheet.addRow(informacoes);
    });

    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      FileSaver.saveAs(
        blob,
        new Date().getTime() + "_exportHistoricoDeDados.xlsx"
      );
    });
  }

  changeInitialHour() {
    if (this.filterEstacao.horaFinal !== "" && this.filterEstacao.horaInicial > this.filterEstacao.horaFinal) {
      this.filterEstacao.horaInicial = "";
      this.hasErrorHoraInicial = true
    } else {
      this.hasErrorHoraInicial = false
    }
  }

  changeFinalHour() {
    if (this.filterEstacao.horaInicial !== "" && this.filterEstacao.horaFinal < this.filterEstacao.horaInicial) {
      this.filterEstacao.horaFinal = "";
      this.hasErrorHoraFinal = true
    } else {
      this.hasErrorHoraFinal = false
    }
  }

  changeInitialData(event: any) {
    if (this.filterEstacao.dataFinal !== "" && event > this.filterEstacao.dataFinal) {
      this.filterEstacao.dataInicial = "";
      this.hasErrorDataInicial = true
    } else {
      this.hasErrorDataInicial = false
      this.hasErrorDataFinal = false
    }
  }

  changeFinalData(event: any) {
    if (this.filterEstacao.dataInicial !== "" && event < this.filterEstacao.dataInicial) {
      this.filterEstacao.dataFinal = "";
      this.hasErrorDataFinal = true
    } else {
      this.hasErrorDataInicial = false
      this.hasErrorDataFinal = false
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
