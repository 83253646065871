import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ConfigurarParamComponent } from "./configurar-param/configurar-param.component";
import { ConfigurarCategoriasComponent } from "./configurar-categorias/configurar-categorias.component";

import { EstacaoComponent } from "./consult-estacao.component";
import { HistoricoEstacaoComponent } from "./historico-estacao/historico-estacao.component";
import { WebserviceComponent } from "./webservice/webservice.component";
import { LinkComponent } from "./link/link.component";

const routes: Routes = [
  { path: "", component: EstacaoComponent },
  { path: "parametro", component: ConfigurarParamComponent },
  { path: "categorias", component: ConfigurarCategoriasComponent },
  { path: "historico", component: HistoricoEstacaoComponent },
  { path: "webservice", component: WebserviceComponent },
  { path: "link", component: LinkComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EstacaoRoutingModule {}
5;
