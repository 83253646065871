import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import IPostPutTerm from "../models/PostPutTerm";
import { AppConfig } from "src/config/app.config";

@Injectable({
  providedIn: "root"
})
export class TermService {
  private BASE_URL = `${AppConfig.settings.urlApi}`;
  constructor(private http: HttpClient) {}

  fetch(filter: string) {
    return this.http.get<any>(`${AppConfig.settings.urlApi}/TermoUso/listar`);
  }

  save(params: IPostPutTerm) {
    return this.http.post<any>(`${AppConfig.settings.urlApi}/TermoUso`, params);
  }

  updateTerm(params: IPostPutTerm) {
    return this.http.put<any>(
      `${AppConfig.settings.urlApi}/TermoUso/${params.id}`,
      params
    );
  }

  fetchTermById(termId: string) {
    return this.http.get<any>(`${AppConfig.settings.urlApi}/Termo/${termId}`);
  }

  activeInactiveTerm(id: string) {
    return this.http.delete<any>(`${AppConfig.settings.urlApi}/TermoUso/${id}`);
  }

  removeBaseTerm(id: string) {
    return this.http.delete<any>(`${AppConfig.settings.urlApi}/TermoUso/RemoverBase/${id}`);
  }
}
