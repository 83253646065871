import { Component, OnInit } from "@angular/core";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { NewEditCategoryModalComponent } from "./new-edit-category-modal/new-edit-category-modal.component"
import { HelperService } from "src/app/services/helper.service";
import { CurrentUserService } from "src/app/services/current-user.service";
import Response from "src/app/models/ResponseGET";
import Category from "src/app/models/Category";
import { DecisionAlertModalComponent } from "src/app/components/decision-alert/decision-alert-modal.component";
import { Subscription } from "rxjs";
import { MaskedValues, DataTableActions, ColumnObject } from "src/app/models/DataTable";
import { Router } from "@angular/router";
import { CategoryService } from "src/app/services/category.service";

@Component({
  selector: "app-configurar-categorias",
  templateUrl: "./configurar-categorias.component.html",
  styleUrls: ["./configurar-categorias.component.scss"]
})

export class ConfigurarCategoriasComponent implements OnInit {
  data: Category[] | any[] = [];
  subscriptions = new Subscription();
  permissions = this.uService.getEnumPermissions();
  isLoaded = false;
  columns: ColumnObject = {
    categoria: {
      title: "Categoria",
      sort: true,
      sortDirection: "asc"
    },
    dataCriacao: {
      title: "Data de criação",
      valueType: MaskedValues.date
    },
  };

  constructor(
    private categoryService: CategoryService,
    private nbDialogService: NbDialogService,
    private helperService: HelperService,
    public uService: CurrentUserService,
    private nbToastrService: NbToastrService,
    private router: Router,
  ) {}

  ngOnInit() { 
    this.fetch();
  }

  fetch() {
    this.subscriptions.add(
      this.categoryService.getCategories().subscribe((response: Response) => {
        const categoriesFound = response.value as Category[];
        this.data = categoriesFound.map(item =>
          this.includeActionsColumnOnData(item)
        );
        this.isLoaded = true;
      })
    );
  }

  includeActionsColumnOnData(item: Category) {
    const buttons = this.uService.getActionsTableAllowed(
      this.permissions.AtualizarGrupo,
      null,
      this.permissions.DeletarGrupo,
    );

    const dataWithActions = {
      ...item,
      actions: {
        buttons,
        editEvent: () => this.edit(item),
        deleteEvent: () => this.delete(item),
      } as DataTableActions
    };

    return dataWithActions;
  }

  new() {
    this.nbDialogService.open(NewEditCategoryModalComponent, {
      context: { }
    })
    .onClose.subscribe(() => this.fetch());
  }

  edit(rowData: Category) {
    this.nbDialogService.open(NewEditCategoryModalComponent, {
      autoFocus: false,
      context: { outsideData: rowData }
    })
    .onClose.subscribe(() => this.fetch());
  }

  delete(rowData: Category) {
    const { id } = rowData;
    this.subscriptions.add(
      this.nbDialogService.open(DecisionAlertModalComponent, { autoFocus: false }).onClose.subscribe((decision: "cancel" | "remove") => {
        if (decision === "remove") {
          this.subscriptions.add(
            this.categoryService.deleteCategory(id).subscribe(() => {
              this.nbToastrService.success(
                "Categoria removida com sucesso.",
                "Sucesso"
              );
              this.fetch();
            })
          );
        }
      })
    );

    this.helperService.closeModal(true);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
