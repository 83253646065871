import { FormsModule } from "@angular/forms";
import { Component, OnInit, Input, NgZone, OnDestroy } from "@angular/core";
import { GroupService } from "src/app/services/group.service";
import { CompanyService } from "src/app/services/company.service";
import {
  NbToastrService,
  NbDialogRef,
  NbTabComponent,
  NbDialogService
} from "@nebular/theme";
import { HelperService } from "src/app/services/helper.service";
import ResponseGET from "src/app/models/ResponseGET";
import Group from "src/app/models/Group";
import ICompany from "src/app/models/Company";
import { AuthService } from "src/app/services/auth.service";
import Funcionario from "src/app/models/Funcionario";
import PostPutFuncionario from "src/app/models/PostPutFuncionario";
import { FuncionarioService } from "src/app/services/funcionario.service";
import { Subscription } from "rxjs";
import { UserService } from "src/app/services/user.service";
import { CurrentUserService } from "src/app/services/current-user.service";
import { CrachaService } from "src/app/services/cracha.service";
import {
  ColumnObject,
  MaskedValues,
  DataTableActions
} from "src/app/models/DataTable";
import Cracha from "src/app/models/Cracha";
import Ausencia from "src/app/models/Ausencia";
import EnderecoFuncionario from "src/app/models/EnderecoFuncionario";
import Cnh from "src/app/models/Cnh";
import Rg from "src/app/models/Rg";
import Ctps from "src/app/models/Ctps";
import PostPutUser from "src/app/models/PostPutUser";
import GetUser from "src/app/models/User";
import Response from "src/app/models/ResponseGET";
import {
  DecisionAlertModalComponent,
  TipoMensagem
} from "src/app/components/decision-alert/decision-alert-modal.component";
import * as dayjs from "dayjs";
import imageCompression from "browser-image-compression";
import { AlertService } from "src/app/services/alert.service";
import { ITreeOptions, ITreeState } from "@circlon/angular-tree-component";

@Component({
  selector: "app-edit-funcionario-modal",
  templateUrl: "./edit-funcionario-modal.component.html",
  styleUrls: ["./edit-funcionario-modal.component.scss"]
})
export class EditFuncionarioModalComponent implements OnInit, OnDestroy {
  @Input() outsideData: Funcionario;

  bloqueiaBuscaSap = false;
  subscriptions = new Subscription();
  isLoading: boolean;
  newOrEditTabTitle = "";
  selectedTab: string = "";
  isEditingAndLoaded: boolean = false;
  crachaVix: boolean = false;
  companyId: string;
  groups = [] as Group[];
  companies = [] as ICompany[];
  userGroups: string[] = [];
  permissions = this.uService.getEnumPermissions();
  initialFirstDateValue: Date = new Date();
  initialBirthDateValue: Date = new Date();
  dtInicioRfid: Date;
  Cnh: Cnh;
  Rg: Rg;
  Ctps: Ctps;
  EnderecoFuncionario: EnderecoFuncionario;
  PostPutUser: PostPutUser = {
    id: "",
    nome: "",
    cpf: "",
    login: "",
    email: "",
    telefone: "",
    empresaId: "",
    tipoUsuario: 0,
    telefoneResidencial: "",
    urlImagem: "",
    dataCriacao: "",
    dataUltimaAtualizacao: "",
    clienteId: ""
  };
  data: PostPutFuncionario = {
    file: null,
    id: "",
    nome: "",
    email: "",
    emailUsuario: "",
    telefoneCelular: "",
    telefoneResidencial: "",
    descrCentroCusto: "",
    cpf: "",
    baseOperacao: "",
    cnpjFilial: "",
    codAreaRh: "",
    codCargo: "",
    codCentroCusto: "",
    codDiretoria: "",
    codEmpresa: "",
    codEstadoCivil: "",
    codSexo: "",
    codSindicato: "",
    codSubareaRh: "",
    codSubgrupoEmpr: "",
    descrAreaRh: "",
    descrCargo: "",
    descrEmpresa: "",
    descrDiretoria: "",
    descrEstadoCivil: "",
    descrSindicato: "",
    descrSubareaRh: "",
    descrSubgrupoEmpr: "",
    dtAdmissao: "",
    dtNascimento: "",
    matriculaAntiga: "",
    matriculaSap: "",
    nacionalidade: "",
    pis: "",
    codSituacao: "",
    situacao: "",
    nomeMae: "",
    deletado: false,
    descrMotivoDemissao: "",
    dtDemissao: "",
    dtUltimoAso: "",
    cnh: this.Cnh,
    dtVencimentoCnh: this.Cnh?.dtVencimento,
    rg: this.Rg,
    dataEmissaoRg: this.Rg?.dataEmissao,
    ctps: this.Ctps,
    enderecoFuncionario: this.EnderecoFuncionario,
    dtFimAfastamento: "",
    dtFimFeriasEfet: "",
    dtFimFeriasProg: "",
    dtIniAfastamento: "",
    dtIniFeriasEfet: "",
    dtIniFeriasProg: "",
    salario: "",
    codGestor: "",
    descrGestor: "",
    codPosicao: "",
    descrPosicao: "",
    postPutUser: this.PostPutUser,
    descAfastamento: "",
    dataUltimaAtualizacaoSap: "",
    dtIniRfid: null,
    codRfid: "",
    observacaoRfid: ""
  };
  novo: Cracha = {
    tipo: 0,
    dataInicio: new Date(),
    deletado: false,
    observacao: "",
    rfid: "",
    id: "",
    funcionarioId: ""
  };
  tipoCracha = [
    {
      Id: 0,
      Nome: "Cliente"
    }
    /*{
      Id: 1,
      Nome: 'VIX',
    },*/
  ];
  dataCrachaAux = [] as Cracha[];
  dataCracha = [] as Cracha[];
  dataAusencia = [] as Ausencia[];
  altura: number;
  columnsCracha: ColumnObject = {
    tipo: {
      title: "Tipo"
    },
    rfid: {
      title: "RFID"
    },
    dataInicio: {
      title: "Data de início",
      valueType: MaskedValues.date
    },
    observacao: {
      title: "Observação"
    }
  };
  columnsAusencia: ColumnObject = {
    tipo: {
      title: "Motivo"
    },
    rfid: {
      title: "Período inicial",
      valueType: MaskedValues.date
    },
    dataInicio: {
      title: "Período final",
      valueType: MaskedValues.date
    },
    observacao: {
      title: "Observação"
    }
  };
  uploadedFiles: Array<File> = [];

  isJustOneCompany: boolean = this.authService.getIsJustOneCompany();
  imageUrl: string = "../../../../assets/images/pngfuel.com.png";

  constructor(
    private groupService: GroupService,
    private companyService: CompanyService,
    private funcionarioService: FuncionarioService,
    private nbToastrService: NbToastrService,
    private helperService: HelperService,
    private nbDialogRef: NbDialogRef<EditFuncionarioModalComponent>,
    private authService: AuthService,
    public uService: CurrentUserService,
    private crachaService: CrachaService,
    private userService: UserService,
    private nbDialogService: NbDialogService,
    private alert: AlertService
  ) {}

  ngOnInit() {
    this.fetchAndSetInitialData();
    this.isLoading = this.outsideData ? true : false;
    this.novo.funcionarioId = this.outsideData.id;
    this.collapseAll();
  }
  state: ITreeState;
  nodes = [
    {
      id: 1,
      name: "Área de trabalho",
      children: [
        {
          id: 2,
          name: "000001 Grupo Águia Branca",
          children: [
            {
              id: 3,
              name: "00055 Vix Logística",
              children: [
                {
                  id: 4,
                  name: "000100 ES",
                  children: [
                    {
                      id: 5,
                      name: "00101 Gerência RH",
                      children: [
                        {
                          id: 6,
                          name: "000101 Grupo 1",
                          children: []
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ];
  options = {};
  collapseAll() {
    this.state = {
      ...this.state,
      expandedNodeIds: {}
    };
  }

  ngAfterContentChecked() {
    this.setValuesOnFields();
  }

  tabChanged(currentTab: NbTabComponent) {
    this.selectedTab = currentTab.tabTitle;
  }

  fetchAndSetInitialData() {
    this.fetchCompanies();
    if (this.isJustOneCompany) {
      this.companyId = this.authService.getDefaultCompanyId();

      this.dtInicioRfid =
        this.outsideData.dtIniRfid === undefined
          ? null
          : new Date(
              dayjs(this.outsideData.dtIniRfid).format("YYYY-MM-DD HH:mm:ss")
            );

      if (
        this.outsideData.observacaoRfid !== undefined &&
        this.outsideData.observacaoRfid === "SAP"
      ) {
        this.crachaVix = true;
      }

      this.dataCracha = this.outsideData.cracha.map(item =>
        this.includeActionsColumnOnData(item)
      );

      this.dataCrachaAux = this.outsideData.cracha.map(item =>
        this.includeActionsColumnOnData(item)
      );

      this.altura = this.dataCracha.length * 10;

      this.isLoading = false;
      // this.fetchGroups(this.companyId);
    }
  }

  fetchCompanies() {
    this.subscriptions.add(
      this.companyService.fetch().subscribe((response: ResponseGET) => {
        this.companies = response.value as ICompany[];
      })
    );
  }

  fetchGroups(companieId: string) {
    this.subscriptions.add(
      this.groupService.fetch(companieId).subscribe((response: ResponseGET) => {
        this.groups = response.value as Group[];
      })
    );
  }

  setValuesOnFields() {
    if (this.outsideData && this.isLoading) {
      this.data = {
        file: null,
        id: this.outsideData.id,
        nome: this.outsideData.nome,
        email: this.outsideData.email,
        emailUsuario: this.outsideData.emailUsuario,
        telefoneCelular: this.outsideData.telefoneCelular,
        telefoneResidencial: this.outsideData.telefoneResidencial,
        matriculaSap: this.outsideData.matriculaSap,
        matriculaAntiga: this.outsideData.matriculaAntiga,
        dtAdmissao: dayjs(this.outsideData.dtAdmissao).format("DD/MM/YYYY"),
        codEmpresa: this.outsideData.codEmpresa,
        descrEmpresa: this.outsideData.descrEmpresa,
        cnpjFilial: this.outsideData.cnpjFilial,
        codAreaRh: this.outsideData.codAreaRh,
        descrAreaRh: this.outsideData.descrAreaRh,
        codSubareaRh: this.outsideData.codSubareaRh,
        descrSubareaRh: this.outsideData.descrSubareaRh,
        codSubgrupoEmpr: this.outsideData.codSubgrupoEmpr,
        descrSubgrupoEmpr: this.outsideData.descrSubgrupoEmpr,
        codCargo: this.outsideData.codCargo,
        descrCargo: this.outsideData.descrCargo,
        codCentroCusto: this.outsideData.codCentroCusto,
        descrCentroCusto: this.outsideData.descrCentroCusto,
        codDiretoria: this.outsideData.codDiretoria,
        descrDiretoria: this.outsideData.descrDiretoria,
        codEstadoCivil: this.outsideData.codEstadoCivil,
        descrEstadoCivil: this.outsideData.descrEstadoCivil,
        codSexo: this.outsideData.codSexo,
        dtNascimento: dayjs(this.outsideData.dtNascimento).format("DD/MM/YYYY"),
        nacionalidade: this.outsideData.nacionalidade,
        codSindicato: this.outsideData.codSindicato,
        descrSindicato: this.outsideData.descrSindicato,
        cpf: this.outsideData.cpf,
        pis: this.outsideData.pis,
        codSituacao: this.outsideData.codSituacao,
        situacao: this.outsideData.situacao,
        baseOperacao: this.outsideData.baseOperacao,
        nomeMae: this.outsideData.nomeMae,
        deletado: false,
        descrMotivoDemissao: this.outsideData.descrMotivoDemissao,
        dtDemissao:
          this.outsideData.dtDemissao == undefined
            ? ""
            : dayjs(this.outsideData.dtDemissao).format("DD/MM/YYYY"),
        dtUltimoAso:
          this.outsideData.dtUltimoAso == undefined
            ? ""
            : dayjs(this.outsideData.dtUltimoAso).format("DD/MM/YYYY"),
        cnh: this.outsideData.cnh,
        dtVencimentoCnh:
          this.outsideData.cnh == undefined
            ? ""
            : dayjs(this.outsideData.cnh.dtVencimento).format("DD/MM/YYYY"),
        rg: this.outsideData.rg,
        dataEmissaoRg:
          this.outsideData.rg == undefined
            ? ""
            : dayjs(this.outsideData.rg.dataEmissao).format("DD/MM/YYYY"),
        ctps: this.outsideData.ctps,
        enderecoFuncionario: this.outsideData.enderecoFuncionario,
        dtFimAfastamento:
          this.outsideData.dtFimAfastamento == undefined
            ? ""
            : dayjs(this.outsideData.dtFimAfastamento).format("DD/MM/YYYY"),
        dtFimFeriasEfet:
          this.outsideData.dtFimFeriasEfet == undefined
            ? ""
            : dayjs(this.outsideData.dtFimFeriasEfet).format(
                "DD/MM/YYYY HH:mm"
              ),
        dtFimFeriasProg:
          this.outsideData.dtFimFeriasProg == undefined
            ? ""
            : dayjs(this.outsideData.dtFimFeriasProg).format(
                "DD/MM/YYYY HH:mm"
              ),
        dtIniAfastamento:
          this.outsideData.dtIniAfastamento == undefined
            ? ""
            : dayjs(this.outsideData.dtIniAfastamento).format(
                "DD/MM/YYYY HH:mm"
              ),
        dtIniFeriasEfet:
          this.outsideData.dtIniFeriasEfet == undefined
            ? ""
            : dayjs(this.outsideData.dtIniFeriasEfet).format(
                "DD/MM/YYYY HH:mm"
              ),
        dtIniFeriasProg:
          this.outsideData.dtIniFeriasProg == undefined
            ? ""
            : dayjs(this.outsideData.dtIniFeriasProg).format(
                "DD/MM/YYYY HH:mm"
              ),
        salario:
          this.outsideData.salario === undefined
            ? ""
            : this.descript(this.outsideData.salario),
        codGestor: this.outsideData.codGestor,
        descrGestor: this.outsideData.descrGestor,
        codPosicao: this.outsideData.codPosicao,
        descrPosicao: this.outsideData.descrPosicao,
        postPutUser:
          this.outsideData.postPutUser === undefined
            ? this.PostPutUser
            : this.outsideData.postPutUser,
        descAfastamento: this.outsideData.descAfastamento,
        dataUltimaAtualizacaoSap:
          this.outsideData.dataUltimaAtualizacaoSap == undefined
            ? ""
            : dayjs(this.outsideData.dataUltimaAtualizacaoSap).format(
                "DD/MM/YYYY HH:mm"
              ),
        dtIniRfid:
          this.outsideData.dtIniRfid === undefined
            ? null
            : new Date(
                dayjs(this.outsideData.dtIniRfid).format("YYYY-MM-DD HH:mm:ss")
              ),
        codRfid: this.outsideData.codRfid,
        observacaoRfid: this.outsideData.observacaoRfid
      };

      if (this.outsideData.usuario !== undefined) {
        this.funcionarioService
          .fetchById(this.outsideData.usuario.id)
          .subscribe((response: ResponseGET) => {
            const usersFound = response.value as Funcionario;

            if (usersFound.urlImagem) {
              this.data.postPutUser.urlImagem = usersFound.urlImagem;
              this.imageUrl = usersFound.urlImagem;
            } else {
              this.data.postPutUser.urlImagem =
                "../../../../assets/images/pngfuel.com.png";
              this.imageUrl = "../../../../assets/images/pngfuel.com.png";
            }
          });
      }

      this.data.postPutUser.email = this.outsideData.usuario?.email;
      this.data.postPutUser.telefone = this.outsideData.usuario?.telefone;
      this.data.postPutUser.telefoneResidencial = this.outsideData.usuario?.telefoneResidencial;

      const dataUltima = dayjs(this.outsideData.dataUltimaAtualizacaoSap);
      if (dayjs().isBefore(dataUltima)) {
        this.bloqueiaBuscaSap = true;
      }

      this.isLoading = false;
    }
  }

  includeActionsColumnOnData?(item: Cracha) {
    const buttons = this.uService.getActionsTableAllowed(
      null,
      null,
      this.permissions.FuncionarioAbaDadosFuncionais
    );

    const dataWithActions = {
      ...item,
      actions: {
        buttons,
        deleteEvent: () => this.delete(item)
      } as DataTableActions
    };

    return dataWithActions;
  }

  update() {
    this.subscriptions.add(
      this.funcionarioService
        .searchUpdate(this.data.id)
        .subscribe((response: Response) => {
          this.isLoading = true;
          this.outsideData = response.value as Funcionario;
          this.setValuesOnFields();
          this.isLoading = false;
          this.nbToastrService.success("Atualização concluída", "Sucesso", {
            duration: 2000
          });
        })
    );
  }

  delete(item: Cracha): void {
    this.subscriptions.add(
      this.nbDialogService
        .open(DecisionAlertModalComponent, {
          autoFocus: false,
          context: { tipo: TipoMensagem.Deletar }
        })
        .onClose.subscribe((decision: "cancel" | "remove") => {
          if (decision === "remove") {
            this.subscriptions.add(
              this.crachaService
                .delete(item.id)
                .subscribe((response: Response) => {
                  this.dataCrachaAux = this.dataCrachaAux.filter(x => {
                    return x.id !== item.id;
                  });
                  this.dataCracha = this.dataCrachaAux;

                  this.nbToastrService.success("Crachá removido", "Sucesso", {
                    duration: 2000
                  });
                  // this.close();
                })
            );
          }
        })
    );
  }

  updateDataIniRfid(newValue: Date) {
    this.data.dtIniRfid = newValue;
  }

  updateDataIniRfidCli(newValue: Date) {
    this.novo.dataInicio = newValue;
  }

  save() {
    switch (this.selectedTab) {
      case "Dados funcionais": {
        if (this.novo.rfid !== "" || this.novo.observacao !== "") {
          this.subscriptions.add(
            this.crachaService
              .save(this.novo)
              .subscribe((response: Response) => {
                this.nbToastrService.success("Crachá cadastrado", "Sucesso", {
                  duration: 2000
                });
                this.close();
              })
          );
        }

        if (
          this.outsideData.observacaoRfid !== "SAP" &&
          (this.data.codRfid !== "" ||
            this.data.dtIniRfid !== null ||
            this.data.observacaoRfid !== "")
        ) {
          this.subscriptions.add(
            this.funcionarioService
              .updateCrachaSap(
                {
                  codRfid: this.data.codRfid,
                  dtIniRfid: this.data.dtIniRfid.toJSON(),
                  observacaoRfid: this.data.observacaoRfid
                },
                this.data.id
              )
              .subscribe((res: Response) => {
                this.nbToastrService.success("Crachá alterado", "Sucesso", {
                  duration: 2000
                });
                this.close();
              })
          );
        }

        break;
      }
      case "Dados pessoais": {
        this.putOrPostUser();
        break;
      }
    }
  }

  validarCampos() {
    if (
      this.data.postPutUser.telefone &&
      this.data.postPutUser.telefone.length < 11 &&
      this.data.postPutUser.telefone.length > 0
    ) {
      this.alert.error("O formato do telefone celular está incorreto.");
      return;
    }

    if (
      this.data.postPutUser.telefoneResidencial &&
      this.data.postPutUser.telefoneResidencial.length < 10 &&
      this.data.postPutUser.telefoneResidencial.length > 0
    ) {
      this.alert.error("O formato do telefone residencial está incorreto.");
      return;
    }

    let regex = new RegExp(
      /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/i
    );

    if (
      this.data.postPutUser.email &&
      !regex.test(this.data.postPutUser.email)
    ) {
      this.alert.error("O formato do e-mail está incorreto.");
      return;
    }

    return true;
  }

  putOrPostUser() {
    this.isLoading = true;
    try {
      if (this.validarCampos()) {
        this.data.postPutUser.empresaId = this.authService.getDefaultCompanyId();
        this.data.postPutUser.nome = this.data.nome;
        this.data.postPutUser.login = this.data.matriculaSap;
        this.data.postPutUser.id = this.data.id;
        this.data.postPutUser.email = this.data.postPutUser.email;
        this.data.postPutUser.telefone = this.data.postPutUser.telefone;
        this.data.postPutUser.telefoneResidencial = this.data.postPutUser.telefoneResidencial;

        const formData = new FormData();
        formData.append("file", this.data.file);
        formData.append("empresaId", this.data.postPutUser.empresaId);
        formData.append("nome", this.data.postPutUser.nome);
        formData.append("login", this.data.postPutUser.login);
        formData.append("id", this.data.postPutUser.id);
        if (this.data.postPutUser.telefone)
          formData.append("telefone", this.data.postPutUser.telefone);
        if (this.data.postPutUser.telefoneResidencial)
          formData.append(
            "telefoneResidencial",
            this.data.postPutUser.telefoneResidencial
          );
        if (this.data.postPutUser.email)
          formData.append("email", this.data.postPutUser.email);
        this.subscriptions.add(
          this.userService
            .updateUser(this.data.postPutUser, formData)
            .subscribe((response: Response) => {
              const savedUser = response.value as GetUser;
              this.data.postPutUser = {
                ...this.data.postPutUser,
                id: savedUser.id,
                email: savedUser.email,
                login: savedUser.login,
                nome: savedUser.nome,
                telefone: savedUser.telefone,
                tipoUsuario: savedUser.tipoUsuario
              };
              this.nbToastrService.success(
                "Registro salvo com sucesso",
                "Sucesso",
                {
                  duration: 2000
                }
              );
            })
        );
      }
    } finally {
      this.isLoading = false;
    }
  }

  // putOrPostUserGroup() {
  //   if (this.outsideData) {

  //       this.nbToastrService.warning(
  //         'Portanto não há o que salvar.',
  //         'Nenhuma alteração foi realizada',
  //       );

  //   } else {
  //     if (this.userGroups.length) {
  //     } else {
  //       this.nbToastrService.warning(
  //         'Portanto não há o que salvar.',
  //         'Nenhuma alteração foi realizada',
  //       );
  //     }
  //   }
  // }

  close() {
    this.helperService.closeModal(true);
    this.nbDialogRef.close();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  updateDateValue(newValue: Date) {
    this.novo.dataInicio = newValue;
  }

  uploadPhoto(): void {
    document
      .getElementById("foto")
      .querySelector("input")
      .click();
  }

  fileChosen(event) {
    this.preview();
  }

  async preview() {
    const files = this.uploadedFiles;
    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.nbToastrService.warning("Apenas imagens são suportadas", "Falha", {
        duration: 4000
      });
      this.uploadedFiles = [];
      this.imageUrl = "";
      return;
    }
    const reader = new FileReader();
    const file = files[0];
    const compressedFile = await this.compressImage(file);
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imageUrl = reader.result.toString();
      this.data.postPutUser.urlImagem = this.imageUrl;
    };
    this.data.file = compressedFile;
  }

  async compressImage(file: File) {
    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      });
      return compressedFile as File;
    } catch (ex) {
      this.alert.error("Houve um problema ao realizar a compressão da imagem");
      return null;
    }
  }

  descript(salarioCript: string) {
    let digtBegin = salarioCript.substring(0, 2);
    let digtEnd = salarioCript.substring(
      salarioCript.length - 2,
      salarioCript.length
    );
    let txtDecript = atob(salarioCript.substring(2, salarioCript.length - 2));

    let valor = txtDecript.substring(+digtBegin, +digtBegin + +digtEnd);

    let formatador = Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
    valor = `${valor.substring(0, valor.length - 2)}.${valor.substring(
      valor.length - 2,
      valor.length
    )}`;

    let salario = formatador.format(+valor);

    return salario;
  }
}
